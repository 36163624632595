import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { goBack, push } from "react-router-redux";
import * as i18n from "util/i18n";
import { FORM_ID } from "constants/servicePayments";

import Col from "react-bootstrap/lib/Col";
import MainContainer from "pages/_components/MainContainer";
import Heading from "pages/_components/Heading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import classNames from "classnames";
import { arrayOf, bool, func, shape, string } from "prop-types";

import { selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { selectors as accountsSelectors } from "reducers/accounts";

import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import { getAccountDescription } from "util/accounts";
import I18n from "pages/_components/I18n";
import { parseFloatToAmountFormat } from "util/number";
import moment from "moment";
import MultiplePaymentsConfirmationToken from "./MultiplePaymentsConfirmationToken";

const MultiplePaymentsConfirmation = ({ isDesktop, dispatch, account, payments, accounts, redirect }) => {
    const [redirectState] = useState(redirect)
    const currencies = [{ id: 0, label: i18n.get(`currency.label.0`) }];
    const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === account);

    useEffect(() => {
        if (redirectState) {
            dispatch(push("/servicePayments"))
        }
    }, [redirectState, dispatch])

    const handleBack = () => {
        dispatch(goBack());
    };

    const centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{i18n.get("services.newPayment")}</h1>
        </div>
    );

    const renderHeader = () => (
        <Container className="mb-3">
            <Col sm={12} className="d-flex justify-content-end">
                <div className="d-flex flex-column justify-content-center text-right text-uppercase">
                    <SelectedAccountsAmount
                        list={payments.filter(({ isChecked }) => isChecked)}
                        summaryView
                        leftOperations={false}
                    />
                </div>
            </Col>
            <Col sm={12} className="container-white d-flex align-items-center" style={{ height: "50px" }}>
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                    label="transfers.debitAccount.label"
                    data={completeDebitAccount?.number !== undefined && getAccountDescription(completeDebitAccount)}
                />
            </Col>
        </Container>
    );

    const renderInfo = ({ entity, concept, ...payment }) => {
        const columns = [
            { md: 4, lg: 3, label: `${FORM_ID}.confirmationTicket.item`, value: entity.category.description },
            { md: 4, lg: 3, label: `${FORM_ID}.confirmationTicket.company`, value: entity.description },
            { md: 4, lg: 3, label: `${FORM_ID}.confirmationTicket.paymentCode`, value: payment.reference },
            {
                md: 4,
                lg: 3,
                label: `${FORM_ID}.confirmationTicket.expiration`,
                value: concept?.date
                    ? moment(concept?.date).format("DD/MM/YYYY")
                    : i18n.get("services.newPayment.noExpirationDate"),
            },
            { md: 4, lg: 3, label: `${FORM_ID}.confirmationTicket.concept`, value: concept.description },
            { md: 4, lg: 3, label: `${FORM_ID}.confirmationTicket.serviceReference`, value: concept.description },
            {
                md: 4,
                lg: 3,
                label: `${FORM_ID}.confirmationTicket.paymentReference`,
                value: payment.paymentReference,
            },
            {
                md: 4,
                lg: 3,
                label: `${FORM_ID}.confirmationTicket.amountToPay`,
                value: `${currencies[0].label.toString()} ${parseFloatToAmountFormat(payment.amount.quantity)}`,
            },
        ];
        return columns.map(({ md, lg, label, value }) => (
            <Col lg={lg} md={md} sm={12} className="col-12 px-3 mb-2">
                <Heading.DataGroup
                    containerClassName={`transfer-data mb-0 ${isDesktop ? "" : "space-between"}`}
                    label={label}
                    data={value}
                />
            </Col>
        ));
    };

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <div className={classNames({ isDesktop: "admin-detail-head px-0 mb-4" })}>
                <Head
                    onBack={handleBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile agenda-payment-title-mobile" : ""}
                    navStyle="default pl-0"
                    centerElement={isDesktop ? undefined : centerContentMobile}
                />
                {isDesktop && (
                    <div className="view-title mb-2">
                        <h1>{i18n.get(`${FORM_ID}.confirmationTicket.title`)}</h1>
                    </div>
                )}
                <div
                    className={`admin-content-center mb-4 w-70 ${
                        !isDesktop ? "admin-content-center-mobile pt-3" : "text-left"
                    }`}>
                    <I18n id={`${FORM_ID}.confirmationTicket.label`} component="h2" />
                </div>
            </div>
            {renderHeader()}
            {payments
                .filter(({ isChecked }) => isChecked)
                .map((payment) => (
                    <Container className="flex-grow container-white py-2 mb-2" rowClassName="justify-content-start">
                        {renderInfo(payment)}
                    </Container>
                ))}
            <MultiplePaymentsConfirmationToken isDesktop={isDesktop} payments={payments} />
            <MainContainer />
        </>
    );
};

MultiplePaymentsConfirmation.propTypes = {
    isDesktop: bool.isRequired,
    account: string.isRequired,
    accounts: arrayOf(shape({})).isRequired,
    payments: arrayOf(shape({})).isRequired,
    dispatch: func.isRequired,
};

const mapStateToProps = (state) => ({
    payments: servicePaymentsSelectors.getMultiplePaymentsInfo(state),
    account: servicePaymentsSelectors.getMultiplePaymentsAccount(state),
    redirect: servicePaymentsSelectors.getBackFromTicket(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default compose(connect(mapStateToProps))(MultiplePaymentsConfirmation);
