import * as API from "middleware/api";

export const validateExternalTransaction = (
    accounts,
    debitAccount,
    cbu,
    alias,
    currency,
    amount,
    reference,
    sendEmail,
    email,
    emailText,
    concept,
    scheduler,
    transferKind,
    tadTrx,
    isSavingDraft = false,
) =>
    API.executeWithAccessToken("/transfers.thirdParties.preview", {
        accounts,
        debitAccount: debitAccount.idProduct,
        cbu,
        alias,
        amount: {
            quantity: amount,
            currency: currency.toString(),
        },
        reference,
        sendEmail,
        notificationMails: email ? [email] : [],
        notificationBody: emailText || "",
        concept,
        valueDate: new Date(),
        scheduler,
        idForm: "transferThirdParties",
        transferKind,
        tadTrx,
        isSavingDraft,
    });

export const validateInternalTransaction = (
    debitAccount,
    creditAccount,
    currency,
    amount,
    reference,
    sendEmail,
    email,
    emailText,
    scheduler,
    tadTrx,
    isSavingDraft = false,
) =>
    API.executeWithAccessToken("/transfers.internal.preview", {
        debitAccount,
        creditAccount,
        sendEmail,
        amount: {
            quantity: amount,
            currency: currency.toString(),
        },
        creditReference: reference,
        notificationMails: email ? [email] : [],
        notificationBody: emailText || "",
        valueDate: new Date(),
        scheduler,
        idForm: "transferInternal",
        tadTrx,
        isSavingDraft,
    });

export const sendInternalTransaction = (summary, otp) => {
    const {
        debitAccount,
        creditAccount,
        amount,
        reference,
        email,
        emailText,
        currency,
        scheduler,
        idTransaction,
        tadTrx,
    } = summary;

    return API.executeWithAccessToken("/transfers.internal.send", {
        ...summary,
        debitAccount: debitAccount.idProduct,
        creditAccount: creditAccount.idProduct,
        amount: {
            quantity: amount,
            currency: currency.toString(),
        },
        creditReference: reference,
        notificationMails: email ? [email] : [],
        notificationBody: emailText || "",
        valueDate: new Date(),
        idForm: "transferInternal",
        idTransaction,
        scheduler,
        tadTrx,
        _otp: otp,
    });
};

export const sendInternalTadTransaction = (summary, otp) => {
    const {
        debitAccount,
        creditAccount,
        amount,
        reference,
        email,
        emailText,
        currency,
        scheduler,
        idTransaction,
        tadTrx,
    } = summary;

    return API.executeWithAccessToken("/transfers.internal.tad.send", {
        ...summary,
        debitAccount: debitAccount.idProduct,
        creditAccount: creditAccount.idProduct,
        amount: {
            quantity: amount,
            currency: currency.toString(),
        },
        creditReference: reference,
        notificationMails: email ? [email] : [],
        notificationBody: emailText || "",
        valueDate: new Date(),
        idForm: "transferInternal",
        idTransaction,
        scheduler,
        tadTrx,
        _otp: otp,
    });
};

export const sendExternalTransaction = (summary, otp) => {
    const {
        debitAccount,
        alias,
        cbu,
        amount,
        reference,
        email,
        emailText,
        currency,
        concept,
        transferKind,
        scheduler,
        idTransaction,
        tadTrx,
    } = summary;
    return API.executeWithAccessToken("/transfers.thirdParties.send", {
        ...summary,
        debitAccount: debitAccount.idProduct,
        amount: {
            quantity: amount,
            currency: currency.toString(),
        },
        alias,
        cbu,
        creditReference: reference,
        notificationMails: email ? [email] : [],
        notificationBody: emailText || "",
        valueDate: new Date(),
        idForm: "transferThirdParties",
        concept,
        transferKind,
        scheduler,
        idTransaction,
        tadTrx,
        _otp: otp,
    });
};

export const sendExternalTadTransaction = (summary, otp) => {
    const {
        debitAccount,
        alias,
        cbu,
        amount,
        reference,
        email,
        emailText,
        currency,
        concept,
        transferKind,
        scheduler,
        idTransaction,
    } = summary;
    return API.executeWithAccessToken("/transfers.thirdParties.tad.send", {
        ...summary,
        debitAccount: debitAccount.idProduct,
        amount: {
            quantity: amount,
            currency: currency.toString(),
        },
        alias,
        cbu,
        creditReference: reference,
        notificationMails: email ? [email] : [],
        notificationBody: emailText || "",
        valueDate: new Date(),
        idForm: "transferThirdParties",
        concept,
        transferKind,
        scheduler,
        idTransaction,
        _otp: otp,
    });
};
export const loadPreActivity = () => API.executeWithAccessToken("/transfers.internal.pre");

export const cancelScheduledTransaction = (
    idTransaction,
    otp,
    creditAccount,
    cbu,
    debitAccount,
    amount,
    currency,
    clientUser,
) =>
    API.executeWithAccessToken("/scheduled.transactions.cancel", {
        idTransactionToCancel: idTransaction,
        _otp: otp,
        creditAccount,
        cbu,
        debitAccount,
        clientUser,
        amount: { currency, quantity: amount },
    });

export const loadAccountLimitsActivities = (idAccount, idActivity) =>
    API.executeWithAccessToken("/transfers.account.change", { idAccount, idActivity });

export const sendSchedulerEmail = (summary) => {
    const { internalTransfer } = summary;

    let summaryToSend;

    if (internalTransfer) {
        const {
            debitAccount,
            creditAccount,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            currency,
            scheduler,
            idTransaction,
            _originName,
            _originDocument,
            _beneficitName,
            _beneficitDocument,
            _creditAccountLabel,
        } = summary;

        summaryToSend = {
            debitAccount: debitAccount.idProduct,
            creditAccount: creditAccount.idProduct,
            amount: {
                quantity: amount,
                currency: currency.toString(),
            },
            creditReference: reference,
            sendEmail,
            notificationMails: email ? [email] : [],
            notificationBody: emailText || "",
            valueDate: new Date(),
            idForm: "transferInternal",
            idTransaction,
            scheduler,
            internalTransfer,
            _originName,
            _originDocument,
            _beneficitName,
            _beneficitDocument,
            _creditAccountLabel,
        };
    } else {
        const {
            debitAccount,
            alias,
            cbu,
            amount,
            reference,
            email,
            emailText,
            currency,
            concept,
            transferKind,
            scheduler,
            idTransaction,
        } = summary;

        summaryToSend = {
            ...summary,
            debitAccount: debitAccount.idProduct,
            amount: {
                quantity: amount,
                currency: currency.toString(),
            },
            alias,
            cbu,
            creditReference: reference,
            notificationMails: email ? [email] : [],
            notificationBody: emailText || "",
            valueDate: new Date(),
            idForm: "transferThirdParties",
            concept,
            transferKind,
            scheduler,
            idTransaction,
            internalTransfer,
        };
    }

    return API.executeWithAccessToken("/transfer.scheduler.send.email", { ...summaryToSend });
};

export const getCbuCvuFromAliasActivity = (alias) => API.executeWithAccessToken("/get.cbu.cvu.alias", alias);
