import React, { Component } from "react";
import { func, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import Heading from "pages/_components/Heading";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Yup from "yup";
import Notification from "pages/_components/Notification";
import { withFormik } from "formik";

import Container from "pages/_components/Container";

import * as i18n from "util/i18n";
import { getAccountDescription } from "util/accounts";
import { push } from "react-router-redux";
import classNames from "classnames";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import MultipleAFIPSummaryData from "../_components/MultipleAFIPSummaryData";
import MultipleAFIPSummaryToken from "./MultipleAFIPSummaryToken";

const FORM_ID = "pay.paymentAFIP.summary";

class MultipleAFIPSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        redirect: bool.isRequired,
        isDesktop: bool.isRequired,
        debitAccountMultipleAFIP: shape({}).isRequired,
        selectedPaymentsAFIP: shape({}).isRequired,
    };

    componentDidMount() {
        const { redirect, dispatch } = this.props;
        if (redirect) {
            dispatch(push("/servicePayments"));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(paymentsAFIPActions.setIsBackFromOtherPage(true));
        dispatch(push(`/servicePayments`));
    };

    centerContentMobile = () => {
        const { isDesktop } = this.props;

        if (!isDesktop) {
            return (
                <div
                    className={classNames({
                        "title-account-header-multiline-background-blue": !isDesktop,
                        "view-title d-flex": isDesktop,
                    })}>
                    <I18n component="h1" id={`${FORM_ID}.confirmationTicket.title`} />
                </div>
            );
        }
    };

    renderHeader = () => {
        const { selectedPaymentsAFIP, isDesktop, debitAccountMultipleAFIP } = this.props;
        const multipleAFIPSelected = selectedPaymentsAFIP.length > 1;

        return (
            <Container className="mb-3">
                <Col sm={12} className="d-flex justify-content-end">
                    <div
                        className={classNames("d-flex flex-column justify-content-center text-right text-uppercase", {
                            "w-100": !isDesktop,
                        })}>
                        <SelectedAccountsAmount
                            list={selectedPaymentsAFIP.filter(({ isChecked }) => isChecked)}
                            withoutTables
                            spaceBetween
                            leftOperations={false}
                        />
                    </div>
                </Col>
                {multipleAFIPSelected && (
                    <Col sm={12} className="container-white d-flex align-items-center" style={{ height: "50px" }}>
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            label="transfers.debitAccount.label"
                            data={
                                debitAccountMultipleAFIP?.number !== undefined &&
                                getAccountDescription(debitAccountMultipleAFIP)
                            }
                        />
                    </Col>
                )}
            </Container>
        );
    };

    render() {
        const { isDesktop, selectedPaymentsAFIP, debitAccountMultipleAFIP } = this.props;
        const oneAFIPSelected = selectedPaymentsAFIP.length === 1;

        return (
            <>
                <Notification scopeToShow="multipleAFIPSummary" />
                <div className={classNames({ isDesktop: "admin-detail-head px-0 mb-4" })}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile agenda-payment-title-mobile" : ""}
                        navStyle="default pl-0"
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="view-title mb-2">
                            <h1>{i18n.get(`${FORM_ID}.confirmationTicket.title`)}</h1>
                        </div>
                    )}
                    <div
                        className={`admin-content-center mb-4 w-70 ${
                            !isDesktop ? "admin-content-center-mobile pt-3" : "text-left"
                        }`}>
                        <I18n id={`${FORM_ID}.confirmationTicket.label`} component="h2" />
                    </div>
                </div>
                {this.renderHeader()}
                {selectedPaymentsAFIP.map(({ account: debitAccount, ...selectedPaymentAFIP }) => (
                    <Container className="flex-grow container-white py-2 mb-2">
                        <Col sm={12} className="px-0">
                            <MultipleAFIPSummaryData
                                isDesktop={isDesktop}
                                isAPay
                                summary={{ debitAccount, paymentAFIP: selectedPaymentAFIP }}
                                oneAFIPSelected={oneAFIPSelected}
                                account={debitAccountMultipleAFIP}
                            />
                        </Col>
                    </Container>
                ))}
                <MultipleAFIPSummaryToken isDesktop={isDesktop} />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedPaymentsAFIP: paymentsAFIPSelectors.getSelectedPaymentsAFIP(state),
    debitAccountMultipleAFIP: paymentsAFIPSelectors.getDebitAccountMultipleAFIP(state),
    userId: sessionSelectors.getUser(state).userId,
    redirect: paymentsAFIPSelectors.getBackFromTicket(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get(`${FORM_ID}.otp.error.required`)),
            }),
    }),
)(MultipleAFIPSummary);
