/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { push } from "react-router-redux";
import { string, func, shape, bool, int } from "prop-types";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import FormattedDate from "pages/_components/FormattedDate";

import { selectors as transferSelectors, actions as transferActions } from "reducers/transfer";
import {
    selectors as frequentDestinationSelectors,
    actions as frequentDestinationActions,
} from "reducers/frequentDestination";
import { selectors as sessionSelectors } from "reducers/session";

import moment from "moment";
import { getDisplay } from "util/device";
import * as i18nUtils from "util/i18n";

import Heading from "pages/_components/Heading";
import { Col } from "react-bootstrap";

import Hint from "pages/_components/hints/Hint";
import * as config from "util/config";

class TransferTransactionData extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            creditAccount: string.isRequired,
            amount: string.isRequired,
            currency: string.isRequired,
            reference: string,
        }).isRequired,
        idTransaction: string.isRequired,
        internalTransfer: bool.isRequired,
        frequentDestination: shape({
            idFrequentDestinations: int,
            accountNumber: string,
            productType: string,
            isOwn: string,
            cbu: string,
            recipientDocumentType: string,
            recipientDocumentNumber: string,
            recipientName: string,
            localBank: string,
            SubsidiaryBank: string,
            recipientEmail: string,
            name: string,
        }).isRequired,
        idEnvironment: string.isRequired,
        scheduledCancel: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
        isCVU: bool,
    };

    static defaultProps = {
        isCVU: false,
    };

    state = {
        isDesktop: getDisplay() === "desktop",
    };

    componentDidMount = () => {
        const {
            dispatch,
            idEnvironment,
            summary: { creditAccountDetail },
            activeEnvironment,
        } = this.props;

        const frequentDestinationPermission = activeEnvironment?.permissions.frequentDestination;

        if (creditAccountDetail && frequentDestinationPermission) {
            dispatch(frequentDestinationActions.getFrequentDestinationRequest(idEnvironment, creditAccountDetail.cbu));
        }
    };

    getAccountDescription = ({ number, productType, currency }) => {
        if (!number) {
            return "";
        }
        return `${number} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
    };

    getAccountObject = (account, type) => {
        const { dispatch } = this.props;
        dispatch(transferActions.getAccountInfo(account, type, true));
    };

    createFrequentDestination = (alias, cbu) => {
        const { dispatch } = this.props;
        let cbuAlias = "";
        if (cbu) {
            cbuAlias = cbu;
        }
        if (alias) {
            cbuAlias = alias;
        }
        dispatch(push(`/transfer/frequentDestination/${cbuAlias}/create/`));

        dispatch(frequentDestinationActions.createFrequentDestinationTransferPreRequest(cbuAlias));
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    getCuitDescription = (clientUser) => {
        if (clientUser?.cuil !== undefined) {
            return ` ${i18nUtils.get(`transferSummary.cuil.input.label`)} ${clientUser?.cuil}`;
        }
        return ` ${i18nUtils.get(`transferSummary.cuit.input.label`)} ${clientUser?.cuit}`;
    };

    render() {
        const {
            summary: {
                debitAccount,
                creditAccount,
                amount,
                reference,
                currency,
                concept,
                transferKind,
                email,
                emailText,
                sendEmail,
                creditAccountDetail,
                clientUser,
                alias,
                cbu,
                idTransactionStatus,
                scheduler,
                idTransactionToCancel,
                creationDate,
                tadTrx,
                responseCVUCode,
                responseCVUDescription,
                pspEntityName,
            },
            summary,
            internalTransfer,
            frequentDestination,
            scheduledCancel,
            activeEnvironment,
            isCVU,
        } = this.props;
        const { isDesktop } = this.state;
        let isFrequentDestination = false;
        if (debitAccount?.number === undefined) {
            this.getAccountObject(debitAccount, "debit");
        }
        if (creditAccount?.number === undefined) {
            this.getAccountObject(creditAccount, "credit");
        }

        if (frequentDestination?.cbu) {
            isFrequentDestination = frequentDestination?.cbu === cbu;
        }

        const transferKindOptions = [
            {
                value: `same`,
                label: i18nUtils.get("transfers.titular.same.label"),
            },
            {
                value: `other`,
                label: i18nUtils.get("transfers.titular.other.label"),
            },
        ];
        const isInternalPending = idTransactionStatus === "PENDING" && internalTransfer;
        const frequentDestinationPermission = activeEnvironment?.permissions.frequentDestination;
        const amountValue = amount?.quantity || amount;
        const currencyValue = currency?.toString() || (amount?.currency ? amount.currency.toString() : "");
        const transferCvuEnabled = config.getBoolean("transfer.cvu.enabled");
        const transfersFunctionalities = config.getBoolean(
            "frontend.show.failedVoucher.transfersFunctionalities",
            false,
        );

        return (
            <>
                <Col xs={12} className="px-0">
                    {idTransactionStatus === "FINISHED" && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="transferSummary.date.label"
                                data={
                                    <p className="data-desc m-0">
                                        <FormattedDate date={creationDate} dateFormat="dd/MM/yyyy" showTime />
                                    </p>
                                }
                            />
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="transferConfirmation.voucher.label"
                                data={<p className="data-desc m-0">{summary?.voucher}</p>}
                            />
                        </>
                    )}
                    {idTransactionStatus !== "FINISHED" && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transferConfirmation.idTransaction.label"
                            data={
                                <p className="data-desc m-0">{summary?.idTransaction?.substring(0, 8).toUpperCase()}</p>
                            }
                        />
                    )}

                    {scheduledCancel && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="scheduled.transactions.cancel.idTransactionToCancel.label"
                            data={
                                <p className="data-desc m-0">{idTransactionToCancel?.substring(0, 8).toUpperCase()}</p>
                            }
                        />
                    )}

                    <>
                        {idTransactionStatus !== "PENDING" && (
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="transferSummary.payer.label"
                                data={<p className="data-desc m-0">{activeEnvironment?.name}</p>}
                            />
                        )}
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transfers.debitAccount.label"
                            data={
                                <p className="data-desc m-0">
                                    {debitAccount?.number !== undefined && this.getAccountDescription(debitAccount)}
                                </p>
                            }
                        />
                        {internalTransfer && (
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="transfers.creditAccount.label"
                                data={
                                    <p className="data-desc m-0">
                                        {creditAccount?.number
                                            ? this.getAccountDescription(creditAccount)
                                            : cbu !== undefined && cbu.slice(-14, -1)}
                                    </p>
                                }
                            />
                        )}
                        {!internalTransfer && (
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="transfers.creditAccount.label"
                                data={<p className="data-desc m-0">{cbu !== undefined && cbu.slice(-14, -1)}</p>}
                            />
                        )}
                    </>
                    {!internalTransfer && !scheduledCancel && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transfers.transferKind.label"
                            data={
                                <p className="data-desc m-0">
                                    {transferKindOptions?.find(({ value }) => value === transferKind)?.label}
                                </p>
                            }
                        />
                    )}
                    {!internalTransfer && !scheduledCancel && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={
                                transferCvuEnabled
                                    ? `transferSummary.${!isCVU ? "cbu" : "cvu"}.label`
                                    : "transferSummary.cbu.label"
                            }
                            data={<p className="data-desc m-0">{creditAccountDetail?.cbu}</p>}
                        />
                    )}
                    {!isInternalPending && (clientUser?.cuit || clientUser?.cuil) && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transferSummary.cuit.label"
                            data={<p className="data-desc m-0">{this.getCuitDescription(clientUser)}</p>}
                        />
                    )}
                    {!internalTransfer && creditAccountDetail?.cuit && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transferSummary.alias.label"
                            data={<p className="data-desc m-0">{clientUser?.cuit || clientUser?.cuil}</p>}
                        />
                    )}
                    {!internalTransfer && !scheduledCancel && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transferSummary.owner.label"
                            data={<p className="data-desc m-0">{clientUser?.firstName}</p>}
                        />
                    )}
                    {!isInternalPending && creditAccountDetail?.bankName && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`transferSummary.${!isCVU ? "bank" : "entity"}.label`}
                            data={
                                <p className="data-desc m-0">
                                    {transferCvuEnabled && isCVU ? pspEntityName : creditAccountDetail?.bankName}
                                </p>
                            }
                        />
                    )}

                    {frequentDestinationPermission &&
                        !isFrequentDestination &&
                        (cbu || alias) &&
                        isDesktop &&
                        !scheduledCancel && (
                            <div style={{ textAlign: "center", width: "100%" }}>
                                <Button
                                    bsStyle="link"
                                    style={{ width: "auto", paddingLeft: 0 }}
                                    onClick={() => {
                                        this.createFrequentDestination(alias, cbu);
                                    }}>
                                    <Image
                                        src="images/transferFrequentDestination.svg"
                                        className="svg-icon"
                                        style={{ fontSize: "1.4rem" }}
                                    />
                                    <I18n
                                        id="frequentDestination.transfer.create"
                                        componentProps={{ style: { width: "max-content" } }}
                                    />
                                </Button>
                            </div>
                        )}
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="transfers.amount.label"
                        data={<FormattedAmount currency={currencyValue} quantity={amountValue} notBold />}
                    />
                    {!internalTransfer && concept && !scheduledCancel && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transfers.concept.label"
                            data={
                                <p className="data-desc m-0">
                                    {`${concept} - ${i18nUtils.get(`transfers.concept.${concept}`)}`}
                                </p>
                            }
                        />
                    )}

                    {(idTransactionStatus === "PENDING" || idTransactionStatus === "SCHEDULED") &&
                        scheduler &&
                        !tadTrx && (
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="forms.confirmation.scheduler"
                                data={<Message value={scheduler} />}
                            />
                        )}
                    {!scheduledCancel && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transfers.reference.label"
                            data={<p className="data-desc m-0">{reference || ""}</p>}
                        />
                    )}
                    {responseCVUDescription && idTransactionStatus === "FAILED" && !transfersFunctionalities && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transfers.cvu.response.label"
                            data={<p className="data-desc m-0">{responseCVUDescription || ""}</p>}
                        />
                    )}
                    {responseCVUCode && idTransactionStatus === "FAILED" && !transfersFunctionalities && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transfers.cvu.code.label"
                            data={<p className="data-desc m-0">{responseCVUCode || ""}</p>}
                        />
                    )}
                    {!internalTransfer &&
                        debitAccount &&
                        debitAccount.idProduct &&
                        debitAccount.productType !== "CA" &&
                        transferKind === "other" &&
                        idTransactionStatus !== "FAILED" &&
                        !tadTrx && (
                            <Hint
                                defaultValue={config.get(`transferencias.impuestos${debitAccount.productType}.Texto`)}
                                styleText={{
                                    maxWidth: "35rem",
                                }}
                                classNameGroup="transfer__text-hint"
                                styleDiv={{ marginLeft: "0.5rem" }}
                                styleImage={{ alignSelf: "center", display: "flex" }}
                                classNameHintP="font-weight-normal my-0"
                                alwaysShowMessage
                            />
                        )}
                    {sendEmail && email && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transfers.email.label"
                            data={<p className="data-desc m-0">{email}</p>}
                        />
                    )}
                    {sendEmail && email && emailText && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="transfers.emailText.label"
                            data={<p className="data-desc m-0">{emailText}</p>}
                        />
                    )}

                    {tadTrx && idTransactionStatus !== "FAILED" && (
                        <I18n
                            id={
                                internalTransfer
                                    ? "transfer.TAD.summary.internalTransfer"
                                    : "transfer.TAD.summary.thirdPartiesTransfer"
                            }
                            componentProps={{ className: "d-flex mt-4 font-weight-600 color-primary text-center" }}
                        />
                    )}

                    {!isFrequentDestination && (cbu || alias) && !isDesktop && (
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <Button
                                bsStyle="link"
                                style={{ width: "auto", paddingLeft: 0 }}
                                onClick={() => {
                                    this.createFrequentDestination(alias, cbu);
                                }}>
                                <Image
                                    src="images/transferFrequentDestination.svg"
                                    className="svg-icon"
                                    style={{ fontSize: "1.4rem" }}
                                />
                                <I18n
                                    id="frequentDestination.transfer.create"
                                    componentProps={{ style: { width: "max-content" } }}
                                />
                            </Button>
                        </div>
                    )}
                </Col>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: transferSelectors.getSummary(state),
    idTransaction: transferSelectors.getIdTransaction(state),
    internalTransfer: transferSelectors.getInternalTransfer(state),
    scheduledCancel: transferSelectors.getScheduledCancel(state),
    frequentDestination: frequentDestinationSelectors.getFrequentDestination(state),
    idEnvironment: frequentDestinationSelectors.getEnviromentId(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isCVU: transferSelectors.getIsCVU(state),
});

export default connect(mapStateToProps)(TransferTransactionData);
