import React, { Component } from "react";
import { string, func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

import { selectors as sessionSelectors } from "reducers/session";

import { Col } from "react-bootstrap";
import { getAccountDescription } from "util/accounts";

class PaymentAFIPSummaryData extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            paymentAFIP: shape({}).isRequired,
        }).isRequired,
        fullName: string.isRequired,
        isDesktop: bool.isRequired,
        signature: bool,
        oneAFIPSelected: bool.isRequired,
        account: shape({}),
    };

    static defaultProps = {
        signature: false,
        account: null,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    preparedBy = () => {
        const { summary, fullName } = this.props;
        return (
            <p>
                {summary?.creatorFullName || fullName} -{" "}
                <FormattedDate date={summary?.creationDate || new Date()} dateFormat="dd/MM/yyyy" showTime />
            </p>
        );
    };

    signBy = () => {
        const { summary } = this.props;
        return (
            <p>
                {summary?.signatures.map((object) => (
                    <>
                        {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                        <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                        <br />
                    </>
                ))}
            </p>
        );
    };

    render() {
        const {
            summary,
            summary: {
                paymentAFIP: {
                    numberVEP,
                    fiscalPeriod,
                    dueDate,
                    amount,
                    conceptDesc,
                    subConceptDesc,
                    description,
                    formNumber,
                    advancePayment,
                    taxpayerCUIT,
                    contributorsCUIT,
                },
            },
            isDesktop,
            signature,
            oneAFIPSelected,
            account,
        } = this.props;

        if (!summary) {
            return null;
        }
        
        return (
            <>
                <Col lg={2} md={2} sm={12} className="px-3 mb-2">
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.numVEP"
                        data={Number(numberVEP)}
                    />
                    {oneAFIPSelected && (
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                            label="pay.paymentAFIP.debAccount"
                            data={getAccountDescription(account)}
                        />
                    )}
                </Col>
                <Col lg={3} md={3} sm={12} className="px-3 mb-2">
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.fiscalPeriod"
                        data={fiscalPeriod}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.concept"
                        data={conceptDesc}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.formNumber"
                        data={formNumber}
                    />
                </Col>
                <Col lg={3} md={3} sm={12} className="px-3 mb-2">
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.dueDate"
                        data={<FormattedDate date={dueDate} />}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.subConcept"
                        data={subConceptDesc}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.advancePayment"
                        data={advancePayment}
                    />
                </Col>
                <Col lg={4} md={4} sm={12} className="px-3 mb-2">
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.amount"
                        data={<FormattedAmount currency={amount.currency} quantity={amount.quantity} notBold />}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.description"
                        data={description}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${!isDesktop ? "space-between" : ""}`}
                        label="pay.paymentAFIP.taxpayerCUIT"
                        data={contributorsCUIT || taxpayerCUIT}
                    />
                </Col>

                {signature && (
                    <>
                        <I18n
                            component="h3"
                            id="accounts.new.account.signatures.label"
                            componentProps={{ className: "font-size-25-px" }}
                        />
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex space-between ${
                                isDesktop ? "" : "summary-data"
                            }`}
                            label="accounts.new.account.signatures.preparedBy.label"
                            data={this.preparedBy()}
                        />
                        {summary?.signatures?.length > 0 && (
                            <>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex space-between ${
                                        isDesktop ? "" : "summary-data"
                                    }`}
                                    label="accounts.new.account.signatures.signBy.label"
                                    data={this.signBy()}
                                />
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default compose(connect(mapStateToProps))(PaymentAFIPSummaryData);
