import React, { useState } from "react";
import { func, shape } from "prop-types";
import Yup from "yup";
import { connect } from "react-redux";

import * as configUtils from "util/config";
import {
    Box,
    Button,
    Chip,
    Col,
    Form,
    Grid,
    OrderBy,
    Radio,
    RadioGroup,
    Separator,
    useMediaQuery,
} from "@ui-kit/components/index.es.js";

import { selectors as transferSelectors, actions as transferActions } from "reducers/transfer";

import * as i18n from "util/i18n";

const FORM_ID = "transfers.historic";
function TransferHistoricFilters(props) {
    TransferHistoricFilters.propTypes = {
        dispatch: func.isRequired,
        setOrder: func.isRequired,
        filterByStatus: func.isRequired,
        onSubmit: func.isRequired,
        onSelectCurrency: func.isRequired,
        filters: shape({}).isRequired,
    };

    TransferHistoricFilters.defaultProps = {};

    const limitMonths = configUtils.getInteger("frontend.Transfers.maxMonths.ToSearch", 3);
    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - limitMonths);
    const defaultDateFrom = new Date();
    defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 1);
    const isMobile = useMediaQuery("min", "md");
    const [dateFrom, setDateFrom] = useState(defaultDateFrom);
    const [dateTo, setDateTo] = useState(new Date());
    const [formikProps, setFormikProps] = useState({});

    const [showFilters, setShowFilters] = useState(false);
    const [showCleanFilters, setShowCleanFilters] = useState(false);
    const { setOrder, filterByStatus, onSubmit, onSelectCurrency, selectedCurrency, filters, onResetFilters } = props;

    const cleanFilters = () => {
        setShowCleanFilters(false);
        if (formikProps.handleReset) {
            formikProps.handleReset();
        }
        onResetFilters();
    };

    const initialValues = {
        transferType: {
            label: i18n.get(`${FORM_ID}.all`),
            value: "all",
        },
        beneficiary: "",
        reference: "",
        minAmount: 0,
        maxAmount: 0,
        dateFrom,
        dateTo,
    };

    const handleOnSubmit = (selectedFilters) => {
        const isFormChanged = Object.keys(selectedFilters).some((key) => selectedFilters[key] !== initialValues[key]);
        setShowCleanFilters(isFormChanged);
        onSubmit(selectedFilters);
    };

    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", ""));
        }
        return value;
    };

    return (
        <>
            <Box mt={32} mb={showFilters ? 24 : 0} {...(!isMobile && { justify: "between", align: "center" })}>
                <Box flex>
                    <Chip label={i18n.get("transfers.historic.tab")} onClick={() => { }} pressed />
                </Box>
                <Box mt={isMobile ? 24 : 0}>
                    <Button
                        text={i18n.get(
                            FORM_ID +
                            (showFilters ? ".hideFilters" : showCleanFilters ? ".activeFilters" : ".showFilters"),
                        )}
                        iconVariant="outline"
                        variant="outline"
                        size="medium"
                        icon={showFilters ? "EyeOff" : "Eye"}
                        onClick={() => setShowFilters(!showFilters)}
                    />
                    {isMobile && showCleanFilters && (
                        <Button
                            text={i18n.get("global.clean.filters")}
                            variant="text"
                            size="small"
                            icon="Error"
                            className="ml-2"
                            onClick={() => cleanFilters()}
                        />
                    )}
                </Box>
            </Box>
            <Box>
                <Form
                    disabledSubmitByDirty
                    hiddeForm={!showFilters}
                    rowSpacingForm={24}
                    buttonSubmit={{
                        colProps: {
                            size: {
                                lg: 2,
                                xs: 12,
                            },
                        },
                        text: i18n.get(`${FORM_ID}.filter`),
                        variant: "solid",
                        icon: "Commands",
                    }}
                    initialValues={initialValues}
                    inputsList={[
                        {
                            colProps: {
                                size: {
                                    lg: 4,
                                    xs: 12,
                                },
                            },
                            component: "selectField",
                            id: "transferType",
                            selectLabel: i18n.get(`${FORM_ID}.transferType`),
                            iconMobile: "TransferAlt",
                            options: [
                                {
                                    label: i18n.get(`${FORM_ID}.all`),
                                    value: "all",
                                },
                                {
                                    label: i18n.get(`${FORM_ID}.internal`),
                                    value: "transfers.internal.send",
                                },
                                {
                                    label: i18n.get(`${FORM_ID}.internal.tad`),
                                    value: "transfers.internal.tad.send",
                                },
                                {
                                    label: i18n.get(`${FORM_ID}.thirdParties`),
                                    value: "transfers.thirdParties.send",
                                },
                                {
                                    label: i18n.get(`${FORM_ID}.thirdParties.tad`),
                                    value: "transfers.thirdParties.tad.send",
                                },
                            ],
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 4,
                                    md: 6,
                                    xs: 12,
                                },
                            },
                            component: "textField",
                            id: "beneficiary",
                            label: i18n.get(`${FORM_ID}.beneficiary`),
                            placeholder: i18n.get(`${FORM_ID}.beneficiary.placeholder`),
                            maxLength: 100,
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 4,
                                    md: 6,
                                    xs: 12,
                                },
                            },
                            component: "textField",
                            id: "reference",
                            label: i18n.get(`${FORM_ID}.reference`),
                            maxLength: 50,
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 3,
                                    md: 6,
                                    xs: 6,
                                },
                            },
                            component: "amountField",
                            allowNegative: false,
                            currency: selectedCurrency,
                            currencyList: [
                                {
                                    label: i18n.get(`currency.label.0`),
                                    value: 0,
                                },
                                {
                                    label: i18n.get(`currency.label.2`),
                                    value: 2,
                                },
                            ],
                            id: "minAmount",
                            label: i18n.get(`${FORM_ID}.amountFrom`),
                            onSelectCurrency: (e) => {
                                onSelectCurrency(e.value);
                            },
                            maxLength: 24, // maximo de 18 numeros + puntos y comas
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 3,
                                    md: 6,
                                    xs: 6,
                                },
                            },
                            component: "amountField",
                            allowNegative: false,
                            currency: selectedCurrency,
                            currencyList: [
                                {
                                    label: i18n.get(`currency.label.0`),
                                    value: 0,
                                },
                                {
                                    label: i18n.get(`currency.label.2`),
                                    value: 2,
                                },
                            ],
                            id: "maxAmount",
                            label: i18n.get(`${FORM_ID}.amountTo`),
                            onSelectCurrency: (e) => {
                                onSelectCurrency(e.value);
                            },
                            maxLength: 24, // maximo de 18 numeros + puntos y comas
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 2,
                                    md: 6,
                                    xs: 12,
                                },
                            },
                            component: "dateField",
                            id: "dateFrom",
                            label: i18n.get(`${FORM_ID}.executionDateFrom`),
                            minDate,
                            startDate: dateFrom,
                            endDate: dateTo,
                            maxDate: dateTo,
                            value: dateFrom,
                            selectsStart: true,
                            onDateChange: (e) => {
                                setDateFrom(e);
                            },
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 2,
                                    md: 6,
                                    xs: 12,
                                },
                            },
                            component: "dateField",
                            id: "dateTo",
                            label: i18n.get(`${FORM_ID}.executionDateTo`),
                            startDate: dateFrom,
                            minDate: dateFrom,
                            endDate: dateTo,
                            value: dateTo,
                            selectsEnd: true,
                            onDateChange: (e) => {
                                setDateTo(e);
                            },
                        },
                    ]}
                    onSubmit={(selectedFilters) => handleOnSubmit(selectedFilters)}
                    validationSchema={Yup.lazy((values) =>
                        Yup.object().shape({
                            beneficiary: Yup.string()
                                .min(3, i18n.get(`${FORM_ID}.validation.length`))
                                .matches(/^[^[\]+?:.\-_&*@#{}$()/";]+$/, i18n.get(`${FORM_ID}.validation.format`)),
                            reference: Yup.string()
                                .min(3, i18n.get(`${FORM_ID}.validation.length`))
                                .matches(/^[^[\]+?:.\-_&*@#{}$()/";]+$/, i18n.get(`${FORM_ID}.validation.format`)),
                            minAmount: Yup.string().test(
                                "test",
                                i18n.get(`${FORM_ID}.validation.minAmount`),
                                (minAmount) => parseAmount(minAmount) <= parseAmount(values.maxAmount),
                            ),
                            maxAmount: Yup.string().test(
                                "test",
                                i18n.get(`${FORM_ID}.validation.maxAmount`),
                                (maxAmount) => parseAmount(maxAmount) >= parseAmount(values.minAmount),
                            ),
                        }),
                    )}
                    setFormikProps={setFormikProps}
                />
            </Box>
            <Box flex justify={showFilters ? "between" : "end"}>
                {showFilters && showCleanFilters && (
                    <Box mt={24}>
                        <OrderBy
                            defaultValueRadioButtons="descendent"
                            defaultValueSelector="executionDate"
                            onChange={(e) => setOrder(e)}
                            propList={[
                                {
                                    label: i18n.get(`${FORM_ID}.orderBy.executionDate`),
                                    value: "executionDate",
                                },
                                {
                                    label: i18n.get(`${FORM_ID}.orderBy.amount`),
                                    value: "amount",
                                },
                            ]}
                        />
                    </Box>
                )}
                {!isMobile && showCleanFilters && (
                    <Button
                        text={i18n.get("global.clean.filters")}
                        variant="text"
                        size="medium"
                        icon="Error"
                        className="ml-2"
                        onClick={() => cleanFilters()}
                    />
                )}
            </Box>
            {isMobile || (showFilters && <Separator color="neutral-light" mt={24} />)}
            <Box my={24} flex>
                <RadioGroup
                    direction="row"
                    name="default1"
                    onChange={(e) => filterByStatus(e.target.value)}
                    value={filters?.status || "TODOS"}>
                    <Radio id="all" label={i18n.get(`${FORM_ID}.all`)} value="TODOS" />
                    <Radio id="finished" label={i18n.get(`${FORM_ID}.finished`)} value="FINALIZADA" />
                    <Radio id="failed" label={i18n.get(`${FORM_ID}.failed`)} value="FALLIDA" />
                </RadioGroup>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => ({
    filters: transferSelectors.getFilters(state),
});
export default connect(mapStateToProps)(TransferHistoricFilters);
