import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";

import { Modal } from "react-bootstrap";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedRate from "pages/_components/FormattedRate";
import EquivalentTotalBalance from "pages/metrocorp/_components/EquivalentTotalBalance";
import Notification from "pages/_components/Notification";

import metrocorpTitleModal from "styles/images/metrocorp-white.svg";

import { selectors as metrocorpSelectors, actions as metrocorpActions } from "reducers/metrocorp";

import * as i18nUtils from "util/i18n";

const FORM_ID = "metrocorp.detail";

class MetrocorpMovementDetail extends Component {
    static propTypes = {
        detail: shape({}).isRequired,
        handleCloseBottomSheetClick: func.isRequired,
        isDisplayed: bool.isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetchingDetailDownload: bool.isRequired,
    };

    handleExport = () => {
        const { dispatch, detail, handleCloseBottomSheetClick } = this.props;
        dispatch(metrocorpActions.downloadMetrocorpDetail(detail, "pdf", handleCloseBottomSheetClick));
    };

    renderClientData = () => {
        const { isDesktop } = this.props;
        return (
            <Container className="flex-grow scrollable">
                <Col md={12} className="with-table-header-margin px-0">
                    <Table className="mb-4">
                        {isDesktop && (
                            <Table.Header className="container-white">
                                <Table.HeaderData>
                                    <I18n id={`${FORM_ID}.column.client`} />
                                </Table.HeaderData>
                                <Table.HeaderData>
                                    <I18n id={`${FORM_ID}.column.movementDate`} />
                                </Table.HeaderData>
                                <Table.HeaderData>
                                    <I18n id={`${FORM_ID}.column.dueDate`} />
                                </Table.HeaderData>
                                <Table.HeaderData>
                                    <I18n id={`${FORM_ID}.column.title`} />
                                </Table.HeaderData>
                            </Table.Header>
                        )}
                        <Table.Body>{this.renderClientDataItem()}</Table.Body>
                    </Table>
                </Col>
            </Container>
        );
    };

    renderClientDataItem = () => {
        const { detail } = this.props;
        const { nroDoc, codClie, tipoDoc, fechaConcertacion, fechaLiquidacion, numBoleto, descripcionEspecie } = detail;

        return (
            <Table.Row className="container-white">
                <Table.Data>
                    <span className="table-text">{`${codClie} ${tipoDoc} ${nroDoc}`}</span>
                </Table.Data>
                <Table.Data>
                    <FormattedDate date={fechaConcertacion} dateFormat="dd/MM/yyyy" className="table-text" />
                </Table.Data>
                <Table.Data>
                    <FormattedDate date={fechaLiquidacion} dateFormat="dd/MM/yyyy" className="table-text" />
                </Table.Data>
                <Table.Data>
                    <span className="table-text">
                        {numBoleto} {descripcionEspecie}
                    </span>
                </Table.Data>
            </Table.Row>
        );
    };

    renderContraPartesBoleto = () => {
        const { detail } = this.props;
        const { contraPartesBoleto } = detail;
        const { contraParteBoleto } = contraPartesBoleto;
        // Datos fijos para la maquetación
        // const contraParteBoleto = [{precio: 123, cantidad: 3, importe: 1234,
        // agente: 14, nroOperacion: 12345678976543, nroRegistro: 7654321 }]
        return (
            <Container className="flex-grow scrollable mb-3">
                <Col md={12} className="with-table-header-margin px-0">
                    <Table>
                        <Table.Header className="container-white">
                            <Table.HeaderData>
                                <I18n id={`${FORM_ID}.column.price`} />
                            </Table.HeaderData>
                            <Table.HeaderData>
                                <I18n id={`${FORM_ID}.column.quantity`} />
                            </Table.HeaderData>
                            <Table.HeaderData align="right" className="pr-2">
                                <I18n id={`${FORM_ID}.column.amount`} />
                            </Table.HeaderData>
                            <Table.HeaderData>
                                <I18n id={`${FORM_ID}.column.agent`} />
                            </Table.HeaderData>
                            <Table.HeaderData>
                                <I18n id={`${FORM_ID}.column.operationalNumber`} />
                            </Table.HeaderData>
                            <Table.HeaderData className="py-1" width="5rem">
                                <I18n id={`${FORM_ID}.column.registerNumber`} />
                            </Table.HeaderData>
                        </Table.Header>
                        <Table.Body>
                            {contraParteBoleto &&
                                contraParteBoleto.length > 0 &&
                                contraParteBoleto.map((item) => this.renderContraPartesBoletoItem(item))}
                        </Table.Body>
                    </Table>
                </Col>
            </Container>
        );
    };

    renderContraPartesBoletoItem = (item) => {
        const { precio, cantidad, importe, agente, nroOperacion, nroRegistro } = item;

        return (
            <Table.Row className="container-white">
                <Table.Data>
                    <FormattedAmount currency="0" className="table-text my-0" quantity={precio} notBold />
                </Table.Data>
                <Table.Data>
                    <span className="table-text">{cantidad}</span>
                </Table.Data>
                <Table.Data align="right">
                    <FormattedAmount currency="0" className="table-text my-0 pr-2" quantity={importe} notBold />
                </Table.Data>
                <Table.Data>
                    <span className="table-text">{agente}</span>
                </Table.Data>
                <Table.Data>
                    <span className="table-text">{nroOperacion}</span>
                </Table.Data>

                <Table.Data>
                    <span className="table-text">{nroRegistro}</span>
                </Table.Data>
            </Table.Row>
        );
    };

    renderItemConceptoRetencion = () => {
        const { detail } = this.props;
        const { itemsConceptos } = detail;
        const { itemConceptoRetencion } = itemsConceptos;

        return (
            <Container className="flex-grow scrollable">
                <Col md={12} className="with-table-header-margin px-0">
                    <Table className="mb-0">
                        <Table.Header className="container-white">
                            <Table.HeaderData align="left" className="pl-2" width="7rem">
                                <I18n id={`${FORM_ID}.column.concept`} />
                            </Table.HeaderData>
                            <Table.HeaderData>
                                <I18n id={`${FORM_ID}.column.comission`} />
                            </Table.HeaderData>
                            <Table.HeaderData align="right">
                                <I18n id={`${FORM_ID}.column.amounts`} />
                            </Table.HeaderData>
                            <Table.HeaderData width="6rem">
                                <I18n id={`${FORM_ID}.column.iva`} />
                            </Table.HeaderData>
                        </Table.Header>
                        <Table.Body>
                            {itemConceptoRetencion &&
                                itemConceptoRetencion.length > 0 &&
                                itemConceptoRetencion.map((item) => this.renderItemConceptoRetencionItem(item))}
                        </Table.Body>
                    </Table>
                </Col>
            </Container>
        );
    };

    renderItemConceptoRetencionItem = (item) => {
        const { descripcionConcepto, porcentaje, monto, iva } = item;

        return (
            <Table.Row className="container-white">
                <Table.Data align="left" className="pl-2">
                    <span className="table-text">{descripcionConcepto}</span>
                </Table.Data>
                <Table.Data>
                    <FormattedRate
                        rate={porcentaje}
                        ml
                        className="table-text my-0"
                        minimumDecimalsCustom={4}
                        maximumDecimalsCustom={4}
                        symbolToTheLeft={false}
                    />
                </Table.Data>
                <Table.Data align="right">
                    <FormattedAmount currency="0" className="table-text my-0" quantity={monto} notBold />
                </Table.Data>
                <Table.Data>
                    {iva ? (
                        <FormattedRate
                            rate={21}
                            ml
                            className="table-text my-0"
                            minimumDecimalsCustom={2}
                            maximumDecimalsCustom={2}
                            symbolToTheLeft={false}
                        />
                    ) : (
                        <span className="table-text ml-4 mr-2">-</span>
                    )}
                </Table.Data>
            </Table.Row>
        );
    };

    renderContent = () => {
        const { detail } = this.props;
        if (!detail) {
            return null;
        }
        const { montoTotal, montoTotalTexto, descripcionOperacionCastellano } = detail;
        const montoTexto =
            montoTotalTexto.slice(-1) === "0"
                ? montoTotalTexto
                : i18nUtils.get("metrocorp.detail.totalBalanceText", null, {
                      totalBalanceText: montoTotalTexto,
                  });

        return (
            <div className="above-the-fold">
                <Container className="container--layout flex-grow p-0">
                    <Col sm={12}>
                        <p className="form-text-group font-regular">
                            {i18nUtils.get(`${FORM_ID}.subTitle`, null, { descripcionOperacionCastellano })}
                        </p>
                    </Col>
                    <Col sm={12}>{this.renderClientData()}</Col>
                    <Col sm={7} className="pr-2 min-height-8 pb-5">
                        {this.renderContraPartesBoleto()}
                        <div className="metrcorp__total_balance">
                            <EquivalentTotalBalance balance={montoTotal} title="metrocorp.detail.totalBalance" bold />
                            {/* Queda fijo el parseo de los centavos */}
                            <p className="mt-0">{montoTexto}</p>
                        </div>
                    </Col>
                    <Col sm={5} className="pl-2">
                        {this.renderItemConceptoRetencion()}
                    </Col>
                </Container>
            </div>
        );
    };

    render() {
        const { handleCloseBottomSheetClick, isDisplayed, fetchingDetailDownload } = this.props;

        return (
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                dialogClassName="modal-dialog-metrocorp"
                containerClassName="metrocorp"
                onHide={handleCloseBottomSheetClick}
                show={isDisplayed}>
                <div className="modal-metrocorp">
                    <Modal.Header closeButton>
                        <Modal.Title id="modalTitleID" className="px-3">
                            <div className="d-flex my-2">
                                <img src={metrocorpTitleModal} alt="MetroCorp" className="logo" />
                                <div className="d-flex align-items-center">
                                    <I18n
                                        id="metrocorp.detail.title.secondPart"
                                        componentProps={{ className: "metrocorp-title" }}
                                    />
                                </div>
                            </div>
                        </Modal.Title>
                        <div className="justify-content-end d-flex w-100 metrocorp__modal-btn-download">
                            <Button
                                image="images/download_bold_white.svg"
                                block={false}
                                bsStyle="link"
                                onClick={this.handleExport}
                                loading={fetchingDetailDownload}
                                fitWidth
                            />
                        </div>
                    </Modal.Header>
                    <Notification scopeToShow={FORM_ID} />
                    <Modal.Body>
                        <MainContainer>{this.renderContent()}</MainContainer>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: metrocorpSelectors.getMovementDetail(state),
    fetchingDetailDownload: metrocorpSelectors.getFetchingDetailDownload(state),
});

export default connect(mapStateToProps)(MetrocorpMovementDetail);
