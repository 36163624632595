import React, { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, string, shape, bool } from "prop-types";
import { echeqShape, echeqConceptLabel } from "util/checks";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import { compose } from "redux";
import { selectors as sessionSelectors } from "reducers/session";

const FORM_ID = "echeqs";

class EndorseSummaryData extends Component {
    static propTypes = {
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        endorseData: shape({
            beneficiary: shape({}),
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { echeqAction, endorseData, isDesktop } = this.props;
        return (
            <>
                {echeqAction?.echeqList?.map((echeq) => (
                    <Container
                        className="flex-grow align-items-center container-white with-margin with-padding-1-rem"
                        gridClassName="form-content">
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.cheque_numero`}
                                data={echeq.cheque_numero}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.add.beneficiary`}
                                data={endorseData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.beneficiario_documento`}
                                data={endorseData?.beneficiary?.document?.toUpperCase()}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.monto`}
                                data={
                                    <FormattedAmount
                                        currency={i18n.get("currency.label.".concat(echeq.emisor_moneda))}
                                        quantity={echeq.monto}
                                        notBold
                                        className="data-desc"
                                    />
                                }
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.cheque_id`}
                                data={echeq.cheque_id}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.type.label`}
                                data={i18n.get(`${FORM_ID}.${endorseData?.endorseData?.type.toLowerCase()}.label`)}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label="echeq.emissionDate"
                                data={<FormattedDate date={echeq.fecha_emision} />}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.fecha_pago`}
                                data={<FormattedDate showTime={false} date={echeq.fecha_pago} />}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.cheque_tipo`}
                                data={echeq.cheque_tipo}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.cheque_caracter`}
                                data={echeq.cheque_caracter}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.cheque_modo`}
                                data={i18n.get(`echeqs.${echeq.cheque_modo}.modo`)}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.cheque_concepto`}
                                data={echeqConceptLabel(echeq.cheque_concepto, "")}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.cheque_motivo_pago`}
                                data={echeq.cheque_motivo_pago}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.reference.label`}
                                data={endorseData?.endorseData?.reference}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-desc"
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.referencevalue.label`}
                                data={endorseData?.endorseData?.referencevalue}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <></>
                        </Col>
                    </Container>
                ))}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(connect(mapStateToProps))(EndorseSummaryData);
