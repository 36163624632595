import React, { useEffect } from "react";
import { arrayOf, bool, func, shape } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, Form, withFormik } from "formik";
import { Col } from "react-bootstrap";
import { goBack } from "react-router-redux";
import Yup from "yup";

import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Hint from "pages/_components/hints/Hint";
import Credential from "pages/_components/fields/credentials/Credential";
import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";

import {
    actions as multipleTransferActions,
    selectors as multipleTransferSelectors,
} from "reducers/multiple-transfers";
import { selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";
import {
    actions as transactionLinesActions,
    selectors as selectorsTransactionLines,
} from "reducers/form/transactionLines";
import { selectors as sessionSelectors } from "reducers/session";

import { getAccountDescription } from "util/accounts";
import isTokenActive from "util/token";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import Row from "react-bootstrap/lib/Row";
import MultipleTransferModal from "./MultipleTransferModal";

const FORM_ID = "multiple.transfers";
function MultipleTransferSummary(props) {
    const {
        dispatch,
        fetching,
        isDesktop,
        summary: { account, description, executionDate, fileName, quantity, amount },
        processedFileData,
        loggedUser,
        itemsDetail,
        isSubmitting,
    } = props;

    const handleBack = () => dispatch(goBack());

    useEffect(() => {
        const fileId = processedFileData?.idFile;
        if (fileId) {
            dispatch(multipleTransferActions.setRememberFormData(true));

            dispatch(multipleTransferActions.getFileContentsRequest(fileId, false, loggedUser.userId));
            dispatch(transactionLinesActions.initializeEditedLines());
            dispatch(transactionLinesActions.setPageNumber(0));
        } else {
            handleBack();
        }
        // eslint-disable-next-line
    }, [processedFileData]);

    return (
        <PageLoading loading={fetching}>
            <Notification scopeToShow="form" />
            <Form>
                <div className="mb-3">
                    <Head onBack={handleBack} accessibilityTextId="transfers.main.label" navStyle="default pl-0" />
                    <I18n id={`${FORM_ID}.summary.title`} component="h1" />
                </div>
                <Container className="confirmation__container">
                    <Col md={9} lg={6} className={isDesktop ? "confirmation__form" : "container-white py-2 px-5"}>
                        <I18n
                            id={`${FORM_ID}.summary.confirm`}
                            component={!isDesktop ? "h2" : "h1"}
                            componentProps={{ className: "text-center" }}
                        />
                        <div className="my-3">
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label={`${FORM_ID}.summary.account.label`}
                                labelClassName="no-wrap"
                                data={
                                    <p className="data-desc m-0">
                                        {account?.number !== undefined && getAccountDescription(account)}
                                    </p>
                                }
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label={`${FORM_ID}.summary.reference.label`}
                                labelClassName="no-wrap"
                                data={description}
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label={`${FORM_ID}.summary.date.label`}
                                labelClassName="no-wrap"
                                data={<FormattedDate date={executionDate} dateFormat="dd/MM/yyyy" />}
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label={`${FORM_ID}.summary.fileName.label`}
                                labelClassName="no-wrap"
                                data={fileName}
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label={`${FORM_ID}.summary.quantity.label`}
                                labelClassName="no-wrap"
                                data={quantity}
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label={`${FORM_ID}.summary.amount.label`}
                                labelClassName="no-wrap"
                                data={
                                    <FormattedAmount
                                        {...amount}
                                        notBold
                                        className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                                    />
                                }
                                dataClassName="data-desc text-right"
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button
                                type="button"
                                bsStyle="outline"
                                block={false}
                                label={`${FORM_ID}.showList.button`}
                                className="px-5"
                                onClick={() => dispatch(multipleTransferActions.setShowDetail(true))}
                            />
                        </div>
                        <Hint
                            classNameMessage=""
                            classNameGroup="justify-content-center d-flex my-3"
                            classNameImage="my-0 ml-2 justify-content-center align-self-center"
                            classNameHintP="aling-items-center my-2"
                            alwaysShowMessage
                            nodeMessage={
                                <I18n
                                    id={`${FORM_ID}.summary.hint`}
                                    component="p"
                                    componentProps={{ className: "hint-text hint-text-header" }}
                                    NOTICE_MESSAGE={configUtils.get("frontend.MultipleTransfer.notice.message", null, {
                                        MAX_ALLOWED_TIME: configUtils.getInteger(
                                            "frontend.MultipleTransfer.max.allowed.time",
                                        ),
                                    })}
                                />
                            }
                        />
                        {!isTokenActive(false) && (
                            <>
                                <I18n
                                    component="p"
                                    id="transfer.summary.confirm.label"
                                    componentProps={{
                                        className: "font-light text-center f-size-5",
                                    }}
                                />
                                <Field
                                    placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                                    name="otp"
                                    type="otp"
                                    idForm={FORM_ID}
                                    component={Credential}
                                />
                            </>
                        )}
                        <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                            <Col md={12} lg={12} className="confirmation__content-button">
                                <Row>
                                    <div className="admin-content-center">
                                        <Button
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            loading={isSubmitting}
                                        />
                                    </div>
                                </Row>
                            </Col>
                        </Container>
                    </Col>
                </Container>
            </Form>
            <MultipleTransferModal
                isDesktop={isDesktop}
                setShow={(show) => dispatch(multipleTransferActions.setShowDetail(show))}
                list={itemsDetail}
            />
        </PageLoading>
    );
}

MultipleTransferSummary.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    processedFileData: shape({}).isRequired,
    summary: shape({}).isRequired,
    itemsDetail: arrayOf().isRequired,
    loggedUser: shape({}).isRequired,
    isSubmitting: bool,
};

MultipleTransferSummary.defaultProps = {
    isSubmitting: false,
};

const mapStateToProps = (state) => ({
    fetching: multipleTransferSelectors.getFetching(state),
    summary: multipleTransferSelectors.getMultipleTransfer(state),
    itemsDetail: selectorsTransactionLines.getTransactionLines(state),
    processedFileData: multilineFileSelectors.getProcessedFileData(state),
    loggedUser: sessionSelectors.getUser(state),
    showDetailModal: multipleTransferSelectors.showDetailModal(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            otp: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.emit.field.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { props } = formikBag;
            const { dispatch, summary } = props;

            dispatch(multipleTransferActions.createMultipleTransfer(otp, summary, formikBag));
        },
    }),
)(MultipleTransferSummary);
