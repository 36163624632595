import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { func, bool, arrayOf, shape, string } from "prop-types";
import { Col } from "react-bootstrap";

import * as i18n from "util/i18n";
import { FORM_ID } from "constants/servicePayments";

import Button from "pages/_components/Button";
import Productselector from "pages/forms/_components/_fields/Productselector";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import Container from "pages/_components/Container";

import { actions as servicePaymentsActions } from "reducers/servicePayments";
import { connect } from "react-redux";

const MultiplePaymentsHeader = (props) => {
    const {
        isDesktop,
        accounts,
        submitForm,
        disableButton,
        payments,
        currencies,
        setErrors,
        setTouched,
        dispatch,
    } = props;
    const [selectedAccount, setSelectedAccount] = useState(null);
    const accountsArs = accounts?.filter((account) => account.currency === currencies[0].id.toString());

    useEffect(() => {
        const selAccount = accountsArs.find((account) => account.favorite);
        if (selAccount) {
            setSelectedAccount(selAccount);
            dispatch(servicePaymentsActions.setMultiplePaymentsAccount(selAccount));
        }
    }, []);

    const onChangeAccount = (account) => {
        const selAccount = accountsArs.find((object) => object.idProduct === account);
        if (selAccount) {
            setSelectedAccount(selAccount);
            dispatch(servicePaymentsActions.setMultiplePaymentsAccount(selAccount));
        }
    };

    return (
        <Container rowClassName={!isDesktop ? "f-dir-col-reverse" : ""}>
            <Col lg={3} md={6} sm={12} className="col py-2">
                <Field
                    name="account"
                    component={Productselector}
                    idField="account"
                    idForm={FORM_ID}
                    data={{
                        emptyOptionLabel: "",
                        options: accountsArs,
                    }}
                    disabled={accountsArs.length === 0}
                    mode="edit"
                    isRequired
                    defaultValue={accountsArs.find((account) => account.favorite)?.idProduct}
                    isDesktop={isDesktop}
                    labelText="transfers.debitaccount.label"
                    customPlaceholder={i18n.get("payments.afip.list.generatedVEP.placeholder")}
                    onCustomChange={onChangeAccount}
                />
            </Col>
            <Col lg={4} lgOffset={5} md={6} sm={12} className="d-flex justify-content-end">
                <div
                    className={`d-flex flex-column justify-content-center text-${
                        isDesktop ? "right" : "left max-width-50"
                    } text-uppercase pr-3`}>
                    <SelectedAccountsAmount
                        list={payments.filter(({ isChecked }) => isChecked)}
                        withoutTables
                        leftOperations={false}
                        selectedAccount={selectedAccount}
                        setErrors={setErrors}
                        setTouched={setTouched}
                    />
                </div>
                <div className="align-items-center d-flex">
                    <Button
                        block={false}
                        label={`${FORM_ID}.paySelected`}
                        bsStyle="primary"
                        onClick={submitForm}
                        disabled={disableButton}
                    />
                </div>
            </Col>
        </Container>
    );
};

MultiplePaymentsHeader.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    accounts: arrayOf(
        shape({
            idProduct: string.isRequired,
        }),
    ).isRequired,
    submitForm: func.isRequired,
    disableButton: bool.isRequired,
    payments: arrayOf(shape({})).isRequired,
    currencies: arrayOf(
        shape({
            id: string.isRequired,
        }),
    ).isRequired,
    setErrors: func.isRequired,
    setTouched: func.isRequired,
};

export default connect()(MultiplePaymentsHeader);
