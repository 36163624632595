/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { bool, func, shape, number, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import moment from "moment";
import {
    Box,
    Button,
    Card,
    Container,
    FormattedDate,
    List,
    Text,
    useMediaQuery,
    Tag,
    ThemeProvider,
} from "@ui-kit/components/index.es";
import Head from "pages/_components/Head";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { actions as transactionsActions } from "reducers/transactions";
import * as i18n from "util/i18n";
import TransferHistoricFilters from "./_components/TransferHistoricFilters";

const FORM_ID = "transfers.historic";

function TransferHistoric(props) {
    TransferHistoric.propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        fetchingMore: bool.isRequired,
        transactions: arrayOf().isRequired,
        concepts: arrayOf().isRequired,
        totalPages: number.isRequired,
        pageNumber: number.isRequired,
        filters: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        location: shape({}).isRequired,
    };
    TransferHistoric.defaultProps = {};

    const { dispatch, fetching, isDesktop, fetchingMore, transactions, totalPages, pageNumber, filters } = props;

    const [orderBy, setOrderBy] = useState("submit_date_time DESC");
    const [selectedCurrency, setSelectedCurrency] = useState(0);

    const isMobile = useMediaQuery("min", "md");

    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 1);
    const dateTo = new Date();
    const defaultFilters = {
        orderBy: "submit_date_time DESC",
        dateType: "submit_date_time",
        dateFrom,
        dateTo,
        pageNumber: 1,
        filter: "last",
        onlyTransfers: true,
    };

    useEffect(() => {
        const { location } = props;

        if (filters === null) {
            dispatch(transferActions.loadListRequest(defaultFilters, false, false));
        } else if (!isDesktop) {
            dispatch(transferActions.resetListTransfers());
            dispatch(transferActions.loadListRequest({ ...filters, pageNumber: 1 }, false, false));
        }

        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
    }, []);

    const resetFilters = () => {
        dispatch(transferActions.resetFilters());
        dispatch(transferActions.loadListRequest(defaultFilters, false, false));
    };

    const onSubmit = (selectedFilters) => {
        const { transferType, minAmount, maxAmount } = selectedFilters;
        dispatch(transferActions.setFilters(selectedFilters));
        const minAmountFormated = minAmount ? parseFloat(minAmount.replace(/\./g, "").replace(",", ".")) : undefined;
        const maxAmountFormated = maxAmount ? parseFloat(maxAmount.replace(/\./g, "").replace(",", ".")) : undefined;

        const filtersToSend = {
            ...filters,
            orderBy,
            ...selectedFilters,
            minAmount:
                minAmountFormated !== undefined
                    ? {
                          currency: selectedCurrency,
                          amount: minAmountFormated,
                      }
                    : undefined,
            maxAmount:
                maxAmountFormated !== undefined
                    ? {
                          currency: selectedCurrency,
                          amount: maxAmountFormated,
                      }
                    : undefined,
            idActivity: transferType.value !== "all" ? transferType.value : undefined,
            pageNumber: 1,
            filter: "last",
            onlyTransfers: true,
            dateType: "submit_date_time",
        };
        dispatch(transferActions.loadListRequest(filtersToSend, false, false));
    };

    const handleFilterButtonsClick = (fieldName = "orderBy", value = "submit_date_time DESC") => {
        const { filters } = props;

        let filtersToSend;
        if (filters === null) {
            filtersToSend = {
                orderBy,
                dateFrom,
                dateTo,
                pageNumber: 1,
                filter: "last",
                onlyTransfers: true,
            };
        } else {
            filtersToSend = {
                ...filters,
                [fieldName]: value,
            };
            if (value === null) {
                delete filtersToSend.fieldName;
            }
        }
        dispatch(transferActions.loadListRequest(filtersToSend, false, false));
    };

    const setOrder = (value) => {
        const orderDirection = value?.direction === "ascendent" ? " ASC" : " DESC";
        const orderBy = value?.orderBy === "executionDate" ? "submit_date_time" : "total_amount_data";
        setOrderBy(orderBy + orderDirection);
        handleFilterButtonsClick("orderBy", orderBy + orderDirection);
    };

    const onSelectCurrency = (currency) => {
        setSelectedCurrency(currency);
        dispatch(transferActions.setFilters({ ...filters, minAmountCurrency: currency, maxAmountCurrency: currency }));
    };

    const filterByStatus = (status) => {
        handleFilterButtonsClick("status", status === "TODOS" ? null : status);
    };

    const fetchMoreTransactions = (newPageNumber) => {
        let { filters } = props;
        filters = filters
            ? { ...filters, pageNumber: newPageNumber }
            : { ...defaultFilters, pageNumber: newPageNumber };
        dispatch(transferActions.loadMoreTransfersRequest(filters, false, false));
    };

    const paginationProps = {
        totalPages,
        pageNumber,
        action: fetchMoreTransactions,
    };

    const onClickOnCard = (idTransaction) => {
        dispatch(routerActions.push(`/transaction/${idTransaction}`));
    };

    const formatDate = (date) => {
        const dateToParse = moment(date).format();
        return new Date(dateToParse);
    };

    const renderTransferCard = (transfer) => {
        const { transactionAmounts, transaction } = transfer;
        const { data, idTransaction } = transaction;
        const { clientUser, concept, reference, transferKind } = data;
        const { firstName } = clientUser;
        const status = transaction.idTransactionStatus === "FINISHED" ? "success" : "error";
        const currency = Object.keys(transactionAmounts)[0];
        const currencyText = i18n.get(`currency.label.${currency}`);
        const quantity = Object.values(transactionAmounts)[0];
        const date = formatDate(transaction.submitDateTime);
        const conceptDescription = `${concept} - ${i18n.get(`transfers.concept.${concept}`)}`;

        let cuitCuil;
        if (clientUser?.cuil !== undefined) {
            cuitCuil = `${i18n.get(`transfers.historic.cuil`)}: ${clientUser?.cuil}`;
        } else {
            cuitCuil = `${i18n.get(`transfers.historic.cuit`)}: ${clientUser?.cuit}`;
        }

        const kind =
            transferKind === "other"
                ? i18n.get("transfers.titular.other.label")
                : i18n.get("transfers.titular.same.label");

        return (
            <Card
                key={idTransaction}
                title={
                    isMobile ? (
                        <Box flex align="start" column>
                            <Text variant="captionBold">{firstName}</Text>
                            <Tag variant="info" className="p-025">
                                <Text variant="caption">{kind}</Text>
                            </Tag>
                        </Box>
                    ) : (
                        <Box flex align="center">
                            <Text variant="bodyBold">{i18n.get("transfers.historic.date")}</Text>
                            <FormattedDate variant="bodyBold" date={date} dateFormat="short" locale="es-ES" ml={4} />
                        </Box>
                    )
                }
                variant={status}
                content={
                    isMobile ? (
                        <Box flex column className="w-100">
                            <Box flex justify="between">
                                <Text variant="captionBold">FECHA</Text>
                                <FormattedDate variant="caption" date={date} dateFormat="short" locale="es-ES" ml={4} />
                            </Box>
                            {concept ? (
                                <Box flex justify="between">
                                    <Text variant="captionBold">CONCEPTO</Text>
                                    <Text variant="caption">{conceptDescription}</Text>
                                </Box>
                            ) : null}
                            <Box flex justify="between">
                                <Text variant="captionBold">REFERENCIA</Text>
                                {reference ? (
                                    <Text variant="caption" className="align-right">
                                        {reference}
                                    </Text>
                                ) : null}
                            </Box>
                        </Box>
                    ) : (
                        <Text variant="body2">{`${firstName} | ${cuitCuil}`}</Text>
                    )
                }
                infoOne={!isMobile && kind}
                bottom={<Text variant="captionBold">{`${concept ? `Concepto: ${conceptDescription} | ` : ""}Referencia: ${reference}`}</Text>}
                id="card-Default"
                currency={currencyText}
                amount={quantity}
                onClick={() => onClickOnCard(idTransaction)}
            />
        );
    };

    const handleBack = () => dispatch(push("/desktop"));

    const makeTransfersPermissions = () => {
        const { activeEnvironment } = props;
        return !!(
            activeEnvironment?.permissions?.transferInternal || activeEnvironment?.permissions?.transferThirdParties
        );
    };

    return (
        <ThemeProvider>
            {!isDesktop && (
                <>
                    <Box className="admin-detail-head z-index-1 px-0">
                        <Head
                            onBack={handleBack}
                            headerClassName="blue-main-header-mobile"
                            centerElement={() => (
                                <Text as="h1" className="m-0">
                                    {i18n.get("transfers.main.label")}
                                </Text>
                            )}
                            accessibilityTextId="transfers.main.label"
                        />
                    </Box>
                    <Container fixed>
                        <Box mt={12}>
                            <Button
                                justify="right"
                                text={i18n.get(`${FORM_ID}.btn.createTransfer`)}
                                variant="solid"
                                icon="CashflowOut"
                                className="w-100"
                                disabled={!makeTransfersPermissions()}
                                onClick={() => {
                                    dispatch(push("/transfer"));
                                }}
                            />
                        </Box>
                    </Container>
                </>
            )}
            <Container fixed className="h-100">
                {isDesktop && (
                    <Box className="admin-detail-head px-0">
                        <Head onBack={handleBack} accessibilityTextId="transfers.main.label" />
                        <Box flex align="center" justify="between">
                            <Text variant="h2">{i18n.get("transfers.main.label")}</Text>
                            <Button
                                justify="right"
                                text={i18n.get(`${FORM_ID}.btn.createTransfer`)}
                                variant="solid"
                                icon="CashflowOut"
                                disabled={!makeTransfersPermissions()}
                                onClick={() => {
                                    dispatch(push("/transfer"));
                                }}
                            />
                        </Box>
                    </Box>
                )}
                <TransferHistoricFilters
                    dispatch={dispatch}
                    setOrder={setOrder}
                    filterByStatus={filterByStatus}
                    onSubmit={onSubmit}
                    onSelectCurrency={onSelectCurrency}
                    onResetFilters={resetFilters}
                    selectedCurrency={selectedCurrency}
                />
                <Box flex className="min-height-40vh" justify="center">
                    <List
                        emptyStateText={i18n.get("transactions.list.none")}
                        array={transactions}
                        mapCallback={renderTransferCard}
                        paginationProps={paginationProps}
                        loading={fetching || fetchingMore}
                        isInfiniteScroll={!isDesktop}
                        endMessage="No hay más transacciones."
                    />
                </Box>
            </Container>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => ({
    transactions: transferSelectors.getTransfers(state),
    fetching: transferSelectors.getFetching(state),
    filters: transferSelectors.getFilters(state),
    pageNumber: transferSelectors.getPageNumber(state),
    hasMoreData: transferSelectors.getHasMoreData(state),
    fetchingMore: transferSelectors.isFetchingMore(state),
    totalPages: transferSelectors.getTotalPages(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default withRouter(connect(mapStateToProps)(TransferHistoric));
