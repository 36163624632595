import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { bool, func, string } from "prop-types";
import Yup from "yup";
import Button from "pages/_components/Button";
import { Form, Field, withFormik } from "formik";
import { compose } from "redux";
import classNames from "classnames";

import * as i18nUtils from "util/i18n";

import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import {
    actions as frequentDestinationActions,
    selectors as frequentDestinationSelector,
} from "reducers/frequentDestination";
import { routerActions } from "react-router-redux/actions";
import Credential from "pages/_components/fields/credentials/Credential";
import Heading from "pages/_components/Heading";
import isTokenActive from "util/token";

const FORM_ID = "createFrequentDestination";

class CreateFrequentDestinationConfirmations extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        frequentDestination: {},
        dispatch: func.isRequired,
        alias: string,
    };

    static defaultProps = {
        frequentDestination: {},
        alias: null,
    };

    componentDidMount() {
        const { frequentDestination } = this.props;

        if (!frequentDestination) {
            routerActions.goBack();
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue d-flex justify-content-center">
            <h1>{i18nUtils.get("frequentDestination.create.newFrequentDestination")}</h1>
        </div>
    );

    render() {
        const { fetching, frequentDestination, alias, isDesktop } = this.props;
        const containerClassName = `transfer-data data-wrapper-flex ${
            !isDesktop ? "space-between mb-0 container-details" : "mb-2"
        }`;
        const labelClassName = "font-weight-600 font-light";

        return (
            <>
                <Notification scopeToShow="frequentDestinationCreateConfirm" />
                <div className="admin-detail-head px-0 rigth-position-desktop">
                    <Head
                        onBack={this.handleBack}
                        closeLinkTo="/FrequentDestination"
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="view-title mb-4">
                            <h1>{i18nUtils.get("frequentDestination.create.newFrequentDestination")}</h1>
                        </div>
                    )}
                </div>
                <MainContainer showLoader={fetching}>
                    <Form className={!isDesktop ? "above-the-fold" : "confirmation__container"}>
                        <Container
                            className={classNames({
                                "pt-3 align-items-center flex-grow cmf-container-white": !isDesktop,
                            })}
                            gridClassName={!isDesktop ? "form-content" : "confirmation__form confirmation__box"}
                            rowClassName={!isDesktop && "align-items-center f-dir-col"}>
                            <div className={classNames("admin-content-center", { "mb-3": !isDesktop })}>
                                <I18n
                                    id="frequentDestination.create.confirmate.subtitle"
                                    component={!isDesktop ? "h2" : "h1"}
                                    componentProps={!isDesktop && { className: "font-size-20-px mx-3" }}
                                />
                            </div>
                            <Col sm={12} md={9} lg={12} className={`col col-12 ${!isDesktop && "px-0"}`}>
                                <Col sm={12} className="col col-12 px-0">
                                    <div className="d-flex justify-content-center">
                                        <Col className={`col col-12 ${isDesktop && "px-0"}`} sm={12} md={8} lg={12}>
                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.numberAccount"
                                                labelClassName={labelClassName}
                                                data={<span>{`${frequentDestination.accountNumber}`}</span>}
                                            />
                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.accountType"
                                                labelClassName={labelClassName}
                                                data={<span>{`${frequentDestination.productType}`}</span>}
                                            />

                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.own"
                                                labelClassName={labelClassName}
                                                data={
                                                    <span>{`${frequentDestination.isOwn === "1" ? "SI" : "NO"}`}</span>
                                                }
                                            />

                                            {alias && (
                                                <Heading.DataGroup
                                                    containerClassName={containerClassName}
                                                    label="frequentDestination.create.confirmate.alias"
                                                    labelClassName={labelClassName}
                                                    data={<span>{`${alias}`}</span>}
                                                />
                                            )}
                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.cbu"
                                                labelClassName={labelClassName}
                                                data={<span>{`${frequentDestination.cbu}`}</span>}
                                            />

                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.document"
                                                labelClassName={labelClassName}
                                                data={
                                                    <span>
                                                        {`${frequentDestination.recipientDocumentType}
                                                    ${frequentDestination.recipientDocumentNumber}
                                                    `}
                                                    </span>
                                                }
                                            />
                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.recipientName"
                                                labelClassName={labelClassName}
                                                data={<span>{`${frequentDestination.recipientName}`}</span>}
                                            />
                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.bank"
                                                labelClassName={labelClassName}
                                                data={
                                                    <span>
                                                        {`${frequentDestination.localBank}/
                                                        ${frequentDestination.subsidiaryBank}`}
                                                    </span>
                                                }
                                            />
                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.email"
                                                labelClassName={labelClassName}
                                                data={<span>{frequentDestination.recipientEmail}</span>}
                                            />
                                            <Heading.DataGroup
                                                containerClassName={containerClassName}
                                                label="frequentDestination.create.confirmate.referent"
                                                labelClassName={labelClassName}
                                                data={<span>{`${frequentDestination.name}`}</span>}
                                            />
                                        </Col>
                                    </div>
                                </Col>
                                <Col sm={12} className="col col-12 d-flex justify-content-center">
                                    <Col className="col col-12" sm={12} md={8} lg={12}>
                                        <div className="admin-content-center mt-3">
                                            {!isTokenActive(!isDesktop) ? (
                                                <I18n
                                                    id="transfer.summary.confirm.label"
                                                    componentProps={{ className: "account-dropdown-header-font" }}
                                                />
                                            ) : (
                                                <div className="admin-content-center pb-3">
                                                    <I18n
                                                        id="confirmation.withoutotp.text"
                                                        component="p"
                                                        componentProps={{ className: "confirmation__text-without-otp" }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                            <Col sm={12} md={6} lg={12} className="col col-12">
                                <Field
                                    placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                                    name="otp"
                                    type="otp"
                                    idForm={FORM_ID}
                                    component={Credential}
                                />
                            </Col>
                            {isDesktop && (
                                <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                                    <Col lg={12} md={12} sm={12} className="px-0">
                                        <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                                            <div className="admin-content-center">
                                                <Button
                                                    type="submit"
                                                    bsStyle="primary"
                                                    label="global.confirm"
                                                    loading={fetching}
                                                    image="images/arrowRight.svg"
                                                    imageStyle="mr-2"
                                                    className="text-capitalize"
                                                />
                                            </div>
                                        </Col>
                                    </Col>
                                </Container>
                            )}
                        </Container>

                        {!isDesktop && (
                            <Container
                                className={!isDesktop && "flex-grow align-items-center"}
                                gridClassName={
                                    !isDesktop ? "form-content" : "confirmation__form confirmation__box-transparent"
                                }>
                                <Col sm={12} md={6} lg={12} className={`col col-12 ${isDesktop && "px-0"}`}>
                                    <Button
                                        image="images/arrowRight.svg"
                                        imageStyle="mr-2"
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.confirm"
                                        loading={fetching}
                                    />
                                </Col>
                            </Container>
                        )}
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: frequentDestinationSelector.getCredentialGroups(state),
    frequentDestination: frequentDestinationSelector.getFrequentDestination(state),
    alias: frequentDestinationSelector.getAlias(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { frequentDestination, idTransaction, dispatch } = formikBag.props;

            if (!idTransaction) {
                dispatch(
                    frequentDestinationActions.frequentDestinationCreateRequest(frequentDestination, otp, formikBag),
                );
            }
        },
    }),
)(CreateFrequentDestinationConfirmations);
