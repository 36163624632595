import React, { Component } from "react";
import { shape, func } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as checkActions, selectors as checkSelector } from "reducers/checks";
import { accountDescription } from "util/checks";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import FormattedDate from "pages/_components/FormattedDate";
import moment from "moment";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";

const FORM_ID = "new.check";

class ChecksTransactionData extends Component {
    static propTypes = {
        checksRequestData: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    componentDidMount() {
        const { checksRequestData, dispatch } = this.props;
        if (!checksRequestData) {
            dispatch(push("/checks"));
        }
    }

    getAccountDescription = () => {
        const { checksRequestData } = this.props;
        if (checksRequestData?.account?.number !== undefined) {
            return accountDescription(
                checksRequestData?.account.productAlias,
                checksRequestData?.account.number,
                checksRequestData?.account.currency,
                checksRequestData?.account.productType,
            );
        }
        return "";
    };

    getAccountObject = (account) => {
        const { dispatch } = this.props;
        dispatch(checkActions.getAccountInfo(account));
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    render() {
        const { checksRequestData } = this.props;

        if (checksRequestData?.account?.number === undefined) {
            this.getAccountObject(checksRequestData?.account);
        }
        const isFinished = checksRequestData?.idTransactionStatus === "FINISHED";

        return (
            checksRequestData && (
                <Col xs={12} className="px-0">
                    {isFinished && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="accounts.new.account.dateTime.label"
                            data={
                                <p className="data-desc m-0">
                                    <FormattedDate
                                        date={checksRequestData?.creationDate}
                                        dateFormat="dd/MM/yyyy"
                                        showTime
                                    />
                                </p>
                            }
                        />
                    )}
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={isFinished ? "accounts.new.account.receipt.label" : "accounts.new.account.id.label"}
                        data={
                            <p className="m-0 data-desc">
                                {checksRequestData?.idTransaction.substring(0, 8).toUpperCase()}
                            </p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.account.label`}
                        data={
                            <p className="data-desc m-0">
                                {checksRequestData?.account && this.getAccountDescription()}
                            </p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.checksTypes.label`}
                        data={<p className="data-desc m-0">{checksRequestData?.checksTypes?.label}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.checkBookChecksAmount.label`}
                        data={<p className="data-desc m-0">{checksRequestData?.checkBookChecksAmount}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.checkBooksAmount.label`}
                        data={<p className="data-desc m-0">{checksRequestData?.checkBooksAmount}</p>}
                    />
                    {checksRequestData?.checksTypes?.showsDeliveryPoint && (
                        <div style={{ marginTop: "2rem" }}>
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.authorized.data`}
                                data={<p className="data-desc m-0" />}
                            />

                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.documentType.label`}
                                data={
                                    <p className="data-desc m-0">
                                        {checksRequestData?.documentType.concat(" ", checksRequestData?.document)}
                                    </p>
                                }
                            />
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.name.label`}
                                data={<p className="data-desc m-0">{checksRequestData?.name}</p>}
                            />
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.surname.label`}
                                data={<p className="data-desc m-0">{checksRequestData?.surname}</p>}
                            />
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.delivery.point`}
                                data={
                                    <p className="data-desc m-0">{i18n.get(`${FORM_ID}.delivery.point.description`)}</p>
                                }
                            />
                        </div>
                    )}
                </Col>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    checksRequestData: checkSelector.getChecksRequestData(state),
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default connect(mapStateToProps)(ChecksTransactionData);
