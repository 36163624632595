import React from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import classNames from "classnames";
import Yup from "yup";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";

import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";
import { selectors as sessionSelectors } from "reducers/session";

import Col from "react-bootstrap/lib/Col";
import isTokenActive from "util/token";

const MultipleAFIPSummaryToken = ({ isSubmitting, isDesktop }) => (
    <Form className={`${!isDesktop && "token-mobile-form bg-white-mobile px-2"}`}>
        <Col sm={12} className="p-0">
            <Container className="flex-grow align-items-center container-white" gridClassName="form-content">
                <Col className={classNames("px-lg-0", { "token-mobile": !isDesktop })} lg={4} md={6} sm={12}>
                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                        {!isTokenActive(!isDesktop) ? (
                            <div className={`admin-content-center ${!isDesktop && "admin-content-center-mobile"}`}>
                                <I18n id="transfer.summary.confirm.label" />
                            </div>
                        ) : (
                            <div className="admin-content-center pb-3">
                                <I18n
                                    id="confirmation.withoutotp.text"
                                    component="p"
                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                />
                            </div>
                        )}
                        <div className="admin-content-center">
                            <Field
                                name="otp"
                                type="otp"
                                idForm="new.check"
                                component={Credential}
                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                            />
                        </div>
                    </Container>
                </Col>
            </Container>
        </Col>
        <Col className="col col-12 px-0" sm={12}>
            <Container className="flex-grow align-items-center container-white" gridClassName="form-content">
                <Col className={`${!isDesktop && "token-button-mobile"}`} lg={4} md={6} sm={12} xl={4}>
                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                        <Button
                            type="submit"
                            bsStyle="primary"
                            label="global.confirm"
                            loading={isSubmitting}
                            image="images/arrowRight.svg"
                            imageStyle="mr-2"
                            className="text-capitalize"
                        />
                    </Container>
                </Col>
            </Container>
        </Col>
    </Form>
);

MultipleAFIPSummaryToken.propTypes = {
    isSubmitting: bool.isRequired,
    isDesktop: bool,
};

MultipleAFIPSummaryToken.defaultProps = {
    isDesktop: false,
};

const mapStateToProps = (state) => ({
    selectedPaymentsAFIP: paymentsAFIPSelectors.getSelectedPaymentsAFIP(state),
    debitAccountMultipleAFIP: paymentsAFIPSelectors.getDebitAccountMultipleAFIP(state),
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, selectedPaymentsAFIP, debitAccountMultipleAFIP, userId } = formikBag.props;

            const oneAFIPSelected = selectedPaymentsAFIP.length === 1;

            const selectedPayments = selectedPaymentsAFIP.map((pay) => ({
                ...pay,
                amount: {
                    currency: pay?.amount.currency,
                    quantity: pay?.amount.quantity,
                },
            }));

            if (oneAFIPSelected) {
                const summary = {
                    paymentAFIP: selectedPayments[0],
                    debitAccount: debitAccountMultipleAFIP,
                };

                dispatch(paymentsAFIPActions.payPaymentAFIP({ ...summary, formikBag }, otp));
            } else {
                dispatch(
                    paymentsAFIPActions.payMultipleAFIP(
                        { selectedPaymentsAFIP, debitAccountMultipleAFIP, otp, userId },
                        formikBag,
                    ),
                );
            }
        },
    }),
)(MultipleAFIPSummaryToken);
