import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, withFormik, Form } from "formik";
import { Col, Grid, Row } from "react-bootstrap";
import { string, func, bool } from "prop-types";
import classNames from "classnames";
import Yup from "yup";

import { selectors as settingsSelectors } from "reducers/settings";
import { selectors as tokenSelectors, actions as tokenActions } from "reducers/token";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import { downloadUrl } from "util/download";
import { getDisplay } from "util/device";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";

import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Logo from "pages/login/_components/Logo";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

const FORM_ID = "accounts.cbuAlias";

class TokenDrawer extends Component {
    static propTypes = {
        isSubmitting: bool,
        currentEmail: string.isRequired,
        mobilePhone: string.isRequired,
        tokenStatus: string.isRequired,
        fetchingToken: bool,
        closeDrawer: func,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
        fetchingToken: false,
        closeDrawer: undefined,
    };

    state = {
        otherScreen: false,
        isDesktop: getDisplay() === "desktop",
    };

    componentDidMount() {
        const { dispatch, tokenStatus } = this.props;
        const isQuery = tokenStatus === "ACCEPTED" || tokenStatus === "PENDING";
        const { otherScreen } = this.state;
        if (!otherScreen && isQuery) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get("token.codeInvite.pending"),
                    "warning",
                    "tokenDrawerNotification",
                ),
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { fetchingToken, closeDrawer } = this.props;

        if (closeDrawer) {
            // En caso de que se esté ejecutando desde el buttonDrawer se le agrega esta función como prop

            if (prevProps.fetchingToken && !fetchingToken) {
                closeDrawer();
            }
        }
    }

    handleClickDownload = async () => {
        downloadUrl(configUtils.get("setting.token.requestCode.linkPDF"));
    };

    renderOtherScreen() {
        const { isSubmitting } = this.props;
        const { isDesktop } = this.state;
        return (
            <>
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={10} md={8} lg={6}>
                                <Logo className="svg-image logo logo-mobile-size" logoColor />
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <Col style={{ marginTop: "4rem" }}>
                    {!isDesktop ? (
                        <div className="admin-content-center">
                            <I18n id="recoveryPassword.step2.disclaimer.header" component="h1" />
                        </div>
                    ) : (
                        <Head title="recoveryPassword.step2.disclaimer.header" hideMobileMenu emptyRightOption />
                    )}
                </Col>

                <MainContainer className={classNames("main-container", { "container-fluid": isDesktop })}>
                    <form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                        <Container className="container--layout flex-grow align-items-center">
                            <Col sm={12}>
                                <p className="text-lead">
                                    <I18n id="recoveryPassword.step2.disclaimer.message" />
                                </p>
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12}>
                                <Button
                                    label="global.button.signout"
                                    loading={isSubmitting}
                                    type="submit"
                                    bsStyle="primary"
                                />
                            </Col>
                        </Container>
                    </form>
                </MainContainer>
            </>
        );
    }

    renderDefaultScreen() {
        const { isSubmitting, currentEmail, mobilePhone, fetchingToken, tokenStatus } = this.props;
        const isQuery = tokenStatus === "ACCEPTED" || tokenStatus === "PENDING";

        const { isDesktop, otherScreen } = this.state;
        const firstEmailLetter = currentEmail.charAt(0);
        const textAfterLastLetter = currentEmail.substring(currentEmail.indexOf("@") - 1, currentEmail.length);

        const maskPhone = `
            ${mobilePhone.charAt(0)}${mobilePhone.charAt(1)}******${mobilePhone.charAt(
            mobilePhone.length - 2,
        )}${mobilePhone.charAt(mobilePhone.length - 1)}`;

        return (
            <>
                <Notification scopeToShow="tokenDrawerNotification" notificationClassname="w-100 top-0 p-absolute" />
                <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                    <Container className="container--layout flex-grow align-items-center color-black mb-0">
                        <Col sm={12}>
                            {isDesktop && (
                                <Col sm={12}>
                                    <div className="admin-content-center">
                                        <I18n id="setting.token.request" component="h2" />
                                    </div>
                                </Col>
                            )}

                            <Col sm={12}>
                                <div className="admin-content-center mt-3">
                                    <I18n id="setting.token.content.subtitle" component="h4" />
                                </div>
                            </Col>

                            <Col sm={12} className="px-0">
                                <div className="admin-content-center mt-3">
                                    <Button
                                        image="images/download_bold.svg"
                                        label="setting.token.content.button.TyC"
                                        className={classNames("btn btn-outline", {
                                            "w-224px": isDesktop,
                                        })}
                                        onClick={() => this.handleClickDownload()}
                                    />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="admin-content-center mt-4">
                                    <I18n
                                        id={
                                            !isQuery
                                                ? "setting.token.content.text"
                                                : "setting.token.content.text.isQuery"
                                        }
                                        component="h4"
                                        componentProps={
                                            isDesktop ? {} : { style: { fontSize: "0.8375rem", fontWeight: "normal" } }
                                        }
                                    />
                                </div>
                            </Col>
                            {!isQuery && (
                                <Col sm={12} className="settings-token-device-selector mt-3">
                                    <Field
                                        component={Selector}
                                        renderAs="radio"
                                        radioDisplayGrid
                                        name="sendChannel"
                                        isDisabled={fetchingToken}
                                        options={[
                                            {
                                                id: `numberPhone`,
                                                label: `${i18n.get("setting.token.content.device.phone")} ${maskPhone}`,
                                            },
                                            {
                                                id: `email`,
                                                label: `${i18n.get(
                                                    "setting.token.content.device.email",
                                                )} ${firstEmailLetter}*****${textAfterLastLetter}`,
                                            },
                                        ]}
                                    />

                                    <div className="set-token-button">
                                        <Button
                                            label="recoveryPassword.step1.device.none"
                                            type="link"
                                            bsStyle="link"
                                            onClick={() => this.setState({ otherScreen: !otherScreen })}
                                        />
                                    </div>
                                </Col>
                            )}
                        </Col>
                    </Container>

                    {!isQuery && (
                        <Container className="d-flex align-items-center">
                            <Col sm={12} lg={10} className={classNames({ "mt-3": !isDesktop })}>
                                <Button
                                    label="setting.token.content.button.enviar"
                                    type="submit"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                />
                            </Col>
                        </Container>
                    )}
                </Form>
            </>
        );
    }

    render() {
        const { otherScreen } = this.state;

        return otherScreen ? this.renderOtherScreen() : this.renderDefaultScreen();
    }
}

const mapStateToProps = (state) => ({
    currentEmail: settingsSelectors.getEmail(state),
    mobilePhone: settingsSelectors.getMobilePhone(state) || "",
    fetching: tokenSelectors.getFetching(state),
    tokenStatus: tokenSelectors.getUserTokenStatus(state),
    fetchingToken: tokenSelectors.getFetchingToken(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ sendChannel: "numberPhone" }),
        validationSchema: () =>
            Yup.object().shape({
                sendChannel: Yup.string().required(i18n.get(`${FORM_ID}.sendChannel.required`)),
            }),

        handleSubmit: (data, formikBag) => {
            const { sendChannel } = data;
            const { dispatch } = formikBag.props;
            dispatch(tokenActions.tokenCreateRequest(sendChannel));
        },
    }),
)(TokenDrawer);
