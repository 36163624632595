import React, { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, string, shape, bool } from "prop-types";
import { echeqShape, echeqConceptLabel } from "util/checks";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import { compose } from "redux";
import { selectors as sessionSelectors } from "reducers/session";

const FORM_ID = "echeqs";

class CesionSummaryData extends Component {
    static propTypes = {
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        cesionData: shape({
            beneficiary: shape({}),
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { echeqAction, cesionData, isDesktop } = this.props;

        if (echeqAction?.echeqList?.length > 1) {
            return (
                <>
                    <Container
                        className="flex-grow container-white with-margin with-padding-1-rem"
                        rowClassName="justify-content-start"
                        gridClassName="form-content cesion-summary-data">
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.add.beneficiary`}
                                data={cesionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.beneficiario_documento`}
                                data={cesionData?.beneficiary?.document?.toUpperCase()}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.reference.label`}
                                data={cesionData?.reference}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.referencevalue.label`}
                                data={cesionData?.referencevalue}
                            />
                        </Col>

                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                labelClassName="data-label-medium"
                                label={`${FORM_ID}.address.label`}
                                data={cesionData?.address}
                            />
                        </Col>
                    </Container>
                    {echeqAction?.echeqList?.map((echeq) => (
                        <Container
                            className="flex-grow align-items-center container-white with-margin with-padding-1-rem"
                            rowClassName="justify-content-start"
                            gridClassName="form-content cesion-summary-data">
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.cheque_numero`}
                                    data={echeq.cheque_numero}
                                />
                            </Col>

                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.emit.total.amount.label`}
                                    data={
                                        <FormattedAmount
                                            currency={i18n.get("currency.label.".concat(echeq.emisor_moneda))}
                                            quantity={echeq.monto}
                                            notBold
                                        />
                                    }
                                />
                            </Col>
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.cheque_id`}
                                    data={echeq.cheque_id}
                                />
                            </Col>
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label="echeq.emissionDate"
                                    data={<FormattedDate date={echeq.fecha_emision} />}
                                />
                            </Col>
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.fecha_pago`}
                                    data={<FormattedDate showTime={false} date={echeq.fecha_pago} />}
                                />
                            </Col>
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.cheque_tipo`}
                                    data={echeq.cheque_tipo}
                                />
                            </Col>
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.cheque_caracter`}
                                    data={echeq.cheque_caracter}
                                />
                            </Col>
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.cheque_modo`}
                                    data={i18n.get(`echeqs.${echeq.cheque_modo}.modo`)}
                                />
                            </Col>
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.cheque_concepto`}
                                    data={echeqConceptLabel(echeq.cheque_concepto, "")}
                                />
                            </Col>
                            <Col xs={12} md={3} className="px-1">
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    labelClassName="data-label-medium"
                                    label={`${FORM_ID}.cheque_motivo_pago`}
                                    data={echeq.cheque_motivo_pago}
                                />
                            </Col>
                        </Container>
                    ))}
                </>
            );
        }
        return (
            <>
                <Container
                    className="flex-grow align-items-center container-white with-margin with-padding-1-rem"
                    rowClassName="justify-content-start"
                    gridClassName="form-content cesion-summary-data">
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.cheque_numero`}
                            data={echeqAction?.echeqList[0]?.cheque_numero}
                        />
                    </Col>

                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.add.beneficiary`}
                            data={cesionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.beneficiario_documento`}
                            data={cesionData?.beneficiary?.document?.toUpperCase()}
                        />
                    </Col>

                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.emit.total.amount.label`}
                            data={
                                <FormattedAmount
                                    currency={i18n.get(
                                        "currency.label.".concat(echeqAction?.echeqList[0]?.emisor_moneda),
                                    )}
                                    quantity={echeqAction?.echeqList[0]?.monto}
                                    notBold
                                    fontClassName="data-desc"
                                />
                            }
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.cheque_id`}
                            data={echeqAction?.echeqList[0]?.cheque_id}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.address.label`}
                            data={cesionData?.address}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label="echeq.emissionDate"
                            data={<FormattedDate date={echeqAction?.echeqList[0]?.fecha_emision} />}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.fecha_pago`}
                            data={<FormattedDate showTime={false} date={echeqAction?.echeqList[0]?.fecha_pago} />}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.cheque_tipo`}
                            data={echeqAction?.echeqList[0]?.cheque_tipo}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.cheque_caracter`}
                            data={echeqAction?.echeqList[0]?.cheque_caracter}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.cheque_modo`}
                            data={i18n.get(`echeqs.${echeqAction?.echeqList[0]?.cheque_modo}.modo`)}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.cheque_concepto`}
                            data={echeqConceptLabel(echeqAction?.echeqList[0]?.cheque_concepto, "")}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.cheque_motivo_pago`}
                            data={echeqAction?.echeqList[0]?.cheque_motivo_pago}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.reference.label`}
                            data={cesionData?.reference}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            label={`${FORM_ID}.referencevalue.label`}
                            data={cesionData?.referencevalue}
                        />
                    </Col>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(connect(mapStateToProps))(CesionSummaryData);
