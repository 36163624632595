import React, { Component } from "react";
import { selectors as transferSelectors, actions as transferActions } from "reducers/transfer";
import { string, func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import classNames from "classnames";
import { getAccountDescription } from "util/accounts";
import * as i18nUtils from "util/i18n";
import Credential from "pages/_components/fields/credentials/Credential";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import Yup from "yup";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import TransferSummaryData from "pages/transfer/TransferSummaryData";
import { selectors as sessionSelectors } from "reducers/session";
import isTokenActive from "util/token";

const FORM_ID = "transferSummary";
class TransferSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            creditAccount: string.isRequired,
            amount: string.isRequired,
            currency: string.isRequired,
            reference: string,
            email: string,
            emailText: string,
            tadTrx: bool,
        }).isRequired,
        internalTransfer: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    componentDidMount() {
        const {
            summary: { debitAccount },
            dispatch,
        } = this.props;
        if (!debitAccount) {
            dispatch(push("/transfer"));
        }
    }

    getAccountDescription({ number, productType, currency }) {
        const { dispatch } = this.props;
        if (!number) {
            dispatch(push("/transfer"));
            return "";
        }
        return getAccountDescription({ number, productType, currency });
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/transfer/back"));
    };

    render() {
        const { isDesktop, isSubmitting, internalTransfer } = this.props;

        const continueButton = (
            <Col className="col-12 confirmation__content-button" lg={12} md={12} sm={12}>
                <Row>
                    <div className="admin-content-center">
                        <Button
                            image="images/arrowRight.svg"
                            imageStyle={isDesktop ? "mr-2" : "mr-1"}
                            type="submit"
                            bsStyle="primary"
                            label="global.confirm"
                            loading={isSubmitting}
                        />
                    </div>
                </Row>
            </Col>
        );

        const credentials = (
            <>
                <Row className="my-2">
                    {!isTokenActive(!isDesktop) ? (
                        <div className="admin-content-center">
                            <I18n
                                id="transfer.summary.confirm.label"
                                componentProps={{
                                    className: classNames("font-light", {
                                        "f-size-65": !isDesktop,
                                        "f-size-55": isDesktop,
                                    }),
                                }}
                            />
                        </div>
                    ) : (
                        <div className="admin-content-center pb-3">
                            <I18n
                                id="confirmation.withoutotp.text"
                                component="p"
                                componentProps={{ className: "confirmation__text-without-otp" }}
                            />
                        </div>
                    )}
                </Row>
                <Row>
                    <div className="admin-content-center">
                        <Field
                            name="otp"
                            type="otp"
                            idForm={FORM_ID}
                            component={Credential}
                            placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        />
                    </div>
                </Row>
            </>
        );
        return (
            <>
                <Notification scopeToShow="form" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        title={!isDesktop && `transfers.summary.${internalTransfer ? "internal" : "external"}.label`}
                        centerContentClassName={classNames({
                            "mx-5": !isDesktop,
                        })}
                    />
                    {isDesktop && (
                        <h1>
                            {internalTransfer
                                ? i18nUtils.get("transfers.summary.internal.label")
                                : i18nUtils.get("transfers.summary.external.label")}
                        </h1>
                    )}
                </div>
                <MainContainer>
                    <Form>
                        <Container
                            className={classNames("flex-grow align-items-center confirmation__container", {
                                "mb-0 cmf-container-white": !isDesktop,
                            })}
                            gridClassName="form-content">
                            {!isDesktop && (
                                <div
                                    className={classNames("admin-content-center", {
                                        "mb-3": !isDesktop,
                                    })}>
                                    <I18n
                                        id="transfers.summary.checkInfo.label"
                                        component="h2"
                                        componentProps={{ className: classNames({ "f-size-35": !isDesktop }) }}
                                    />
                                </div>
                            )}

                            <Col className="col-12 confirmation__form" lg={6} md={9} sm={12}>
                                {isDesktop && (
                                    <div
                                        className={classNames("admin-content-center", {
                                            "mb-3": !isDesktop,
                                        })}>
                                        <I18n
                                            id="transfers.summary.checkInfo.label"
                                            component={!isDesktop ? "h2" : "h1"}
                                            componentProps={{ className: classNames({ "f-size-35": !isDesktop }) }}
                                        />
                                    </div>
                                )}
                                <TransferSummaryData isDesktop={isDesktop} />
                                {credentials}
                                <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                                    {continueButton}
                                </Container>
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    summary: transferSelectors.getSummary(state),
    internalTransfer: transferSelectors.getInternalTransfer(state),
    userId: sessionSelectors.getUser(state).userId,
    envName: sessionSelectors.getActiveEnvironment(state).name,
    envDocument: sessionSelectors.getActiveEnvironment(state).clients[0]?.idClient,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, summary, internalTransfer, userId } = formikBag.props;
            const addTadActivity = summary?.tadTrx ? ".tad" : "";

            if (internalTransfer) {
                dispatch(
                    transferActions.sendInternalTransfer(
                        { ...summary, idActivity: `transfers.internal${addTadActivity}.send`, userId, formikBag },
                        otp,
                    ),
                );
            } else {
                dispatch(
                    transferActions.sendExternalTransfer(
                        { ...summary, idActivity: `transfers.thirdParties${addTadActivity}.send`, userId, formikBag },
                        otp,
                    ),
                );
            }
        },
    }),
)(TransferSummary);
