import { bool, shape } from "prop-types";
import React from "react";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import { selectors as fundcorpSelectors } from "reducers/fundcorp";
import * as i18n from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import * as configUtils from "util/config";
import Hint from "../../../_components/hints/Hint";

function SubscribeSummaryData({ isDesktop, spaceBetween, summary }) {
    const {
        fundName,
        amount,
        debitAccontLabel,
        shareAccount,
        subscribeDate,
        scheduler,
        selectedScheduler,
        idTransactionStatus,
        idTransaction,
        fondoClase,
        messageCancellation,
    } = summary;

    if (!fundName) {
        return <></>;
    }
    const investmentsFunctionalities = configUtils.getBoolean(
        "frontend.show.failedVoucher.investmentsFunctionalities",
        false,
    );
    return (
        <>
            {summary?.creationDate && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="forms.transaction.ticket.date"
                    data={<FormattedDate date={summary?.creationDate || new Date()} dateFormat="dd/MM/yyyy" showTime />}
                />
            )}

            {idTransaction && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="forms.transaction.ticket.number"
                    data={idTransaction.substring(0, 8).toUpperCase()}
                />
            )}

            {fundName && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="fund.subscribe.summary.fund.label"
                    data={fundName}
                />
            )}

            {amount && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="fund.subscribe.summary.subscribeAmount.label"
                    data={
                        <FormattedAmount
                            currency={amount.currency}
                            quantity={amount.quantity}
                            notBold
                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                        />
                    }
                />
            )}

            {fondoClase && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="fund.subscribe.summary.class.label"
                    data={`${i18n.get("fund.subscribe.summary.class.label")} ${fondoClase}`.toUpperCase()}
                />
            )}

            {debitAccontLabel && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="fund.subscribe.summary.debitAccount.label"
                    data={debitAccontLabel}
                />
            )}

            {shareAccount && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="fund.rescue.summary.shareAccount.label"
                    data={shareAccount}
                />
            )}

            {(!idTransactionStatus || idTransactionStatus === "PENDING" || idTransactionStatus === "SCHEDULED") &&
                (selectedScheduler?.newValueDate || scheduler?.newValueDate) && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${
                            !isDesktop || spaceBetween ? "space-between" : ""
                        }`}
                        label="fund.subscribe.summary.date.label"
                        data={<Message FORM_ID="fund.sheduler.subscribe" value={selectedScheduler || scheduler} />}
                    />
                )}

            {subscribeDate && !selectedScheduler?.newValueDate && !scheduler?.newValueDate && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="fund.subscribe.summary.date.label"
                    data={<FormattedDate date={subscribeDate || new Date()} dateFormat="dd/MM/yyyy" />}
                />
            )}

            {messageCancellation && (
                <Hint
                    classNameGroup="my-0"
                    classNameImage="my-0 ml-2 justify-content-center align-self-center"
                    alwaysShowMessage
                    classNameHintP="hint-font-size-large fw-bold my-0 mt-2 mb-2 justify-content-end align-items-center"
                    defaultValue={messageCancellation}
                />
            )}

            {idTransactionStatus && idTransactionStatus === "FAILED" && !investmentsFunctionalities && (
                <>
                    <Row className="mx-0">
                        <I18n
                            component="h2"
                            id="fund.subscribe.summary.reference.label"
                            componentProps={{
                                className: "transaction-ticket-signature-title",
                            }}
                        />
                    </Row>
                    <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                        <Row style={{ justifyContent: "space-between" }}>
                            <FieldLabel labelKey="transfers.failed.response" notMarginY />
                            <I18n
                                component="p"
                                id="fund.subscribe.summary.generic.error.message"
                                componentProps={{
                                    className: "data-desc",
                                }}
                            />
                        </Row>
                    </Col>
                </>
            )}
        </>
    );
}

SubscribeSummaryData.propTypes = {
    isDesktop: bool,
    spaceBetween: bool,
    summary: shape({}).isRequired,
};

SubscribeSummaryData.defaultProps = {
    isDesktop: true,
    spaceBetween: false,
};

const mapStateToProps = (state) => ({
    summary: fundcorpSelectors.getSummary(state),
});

export default connect(mapStateToProps)(SubscribeSummaryData);
