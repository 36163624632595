import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bool, shape, string, object as obj } from "prop-types";
import { selectors as sessionSelectors } from "reducers/session";
import Checks from "pages/checks/Checks";
import ChecksSummary from "pages/checks/ChecksSummary";
import CheckTransaction from "pages/checks/CheckTransaction";
import ChangeEmail from "pages/settings/ChangeEmail";
import ChangeEmailConfirmation from "pages/settings/ChangeEmailConfirmation";
import ChangeEnvironment from "pages/settings/ChangeEnvironment";
import SetDefaultEnvironment from "pages/settings/SetDefaultEnvironment";
import ChangeLanguage from "pages/settings/ChangeLanguage";
import ChangePassword from "pages/settings/ChangePassword";
import ChangeAddresses from "pages/settings/ChangeAddresses";
import ChangePhone from "pages/settings/ChangePhone";
import ChangePhoneConfirmation from "pages/settings/ChangePhoneConfirmation";
import ChangeSecuritySeal from "pages/settings/ChangeSecuritySeal";
import ChangeSecuritySealConfirmation from "pages/settings/ChangeSecuritySealConfirmation";
import LastLogin from "pages/settings/LastLogin";
import AccountsOfficer from "pages/settings/AccountsOfficer";
import DefaultLayout from "pages/_layouts/DefaultLayout";
import Accounts from "pages/accounts/Accounts";
import AccountOpening from "pages/accounts/AccountOpening";
import AccountOpeningSummary from "pages/accounts/AccountOpeningSummary";

import FingerprintConfiguration from "pages/settings/FingerprintConfiguration";
import FingerprintDeleteConfirmation from "pages/settings/FingerprintDeleteConfirmation";
import MyDevices from "pages/settings/MyDevices";
import DocumentsIndex from "pages/settings/_esign/DocumentsIndex";
import EsignDocumentsSigned from "pages/settings/_esign/EsignDocumentsSigned";
import NotificationsConfiguration from "pages/settings/NotificationsConfiguration";
import NotificationsConfigurationTransports from "pages/settings/NotificationsConfigurationTransports";
import Settings from "pages/settings/Settings";
import Form from "pages/forms/Form";
import Transaction from "pages/forms/Transaction";
import MultipleSignaturesSummary from "pages/transactions/MultipleSignaturesSummary";
import ProcessingMultipleSignatures from "pages/transactions/ProcessingMultipleSignatures";
import SessionAboutToExpire from "pages/status/SessionAboutToExpire";
import BackButtonListener from "pages/_components/BackButtonListener";
import AccountDetail from "pages/accounts/Details";
import MovementDetail from "pages/accounts/_components/MovementDetail";
import AccountStatements from "pages/accounts/_components/Statements";
import SetAlias from "pages/accounts/_components/SetAlias";
import Filters from "pages/accounts/_components/MobileFilters";
import Results from "pages/accounts/_components/MobileFiltersResults";
import Desktop from "pages/desktop/Desktop";
import PendingActions from "pages/desktop/PendingActions";
import Transfer from "pages/transfer/Transfer";
import TransferHistoric from "pages/transfer/TransferHistoric";
import TransferSummary from "pages/transfer/TransferSummary";
import TransferTransaction from "pages/transfer/TransferTransaction";
import TransferScheduled from "pages/transfer/TransferScheduled";
import TransferScheduledCancel from "pages/transfer/TransferScheduledCancel";
import TransferError from "pages/transfer/TransferError";

import CommunicationMain from "pages/communications/Main";
import NewMessage from "pages/communications/NewMessage";
import ReadMessage from "pages/communications/ReadMessage";
import ReplyMessage from "pages/communications/ReplyMessage";
import PendingTransactionList from "pages/transactions/PendingTransactionList";
import TransactionsList from "pages/transactions/TransactionsList";
import TransactionsListFilters from "pages/transactions/_components/MobileFilters";
import TransactionsFiltersResults from "pages/transactions/_components/MobileFiltersResults";
import BankSearch from "pages/forms/_components/_fields/_bankselector/bankSearch/BankSearch";
import MobileTransfers from "pages/accounts/_components/MobileTransfers";
import PrivacyPolicy from "pages/_components/PrivacyPolicy";
import TermsAndConditions from "pages/_components/TermsAndConditions";
import SupportInfo from "pages/_components/SupportInfo";
import UserInvite from "pages/administration/UserInvite";
import UserInviteGroupSignature from "pages/administration/UserInviteGroupSignature";
import UserInviteStep2 from "pages/administration/UserInviteStep2";
import UserInviteStep3 from "pages/administration/UserInviteStep3";
import Administration from "pages/administration/Administration";
import AdministrationMediumSignatureTicket from "pages/administration/medium/tickets/SignatureTicket";
import PointsOfInterest from "pages/pointsOfInterest";
import Templates from "pages/forms/_components/Templates";
import FormPaymentLines from "pages/forms/FormPaymentLines";
import TransactionPaymentLines from "pages/forms/TransactionPaymentLines";
import StepPEP from "pages/onboarding/StepPEP";
import StepIRS from "pages/enrollment/StepIRS";
import ProyectedBalance from "pages/accounts/ProyectedBalance";
import SetProductAlias from "pages/accounts/SetProductAlias";
import HistoricBalance from "pages/accounts/HistoricBalance";
import ChangeUserpass from "pages/settings/ChangeUserpass";
import FrequentDestinationList from "pages/frequentDestinations/List";
import FrequentDestinationDetail from "pages/frequentDestinations/Details";
import FrequentDestinationCreate from "pages/frequentDestinations/CreateFrequentDestination";
/* eslint-disable */
import FrequentDestinationCreateConfirmation from "pages/frequentDestinations/_components/CreateFrequentDestinationConfirmations";
/* eslint-enable */

import FrequentDestinationDelete from "pages/frequentDestinations/DeleteConfirmation";
import ModifyFrequentDestination from "pages/frequentDestinations/ModifyFrequentDestination";
/* eslint-disable */
import ModifyFrequentDestinationConfirmations from "pages/frequentDestinations/_components/ModifyFrequentDestinationConfirmations";
/* eslint-enable */

import TransactionTicketCancelConfirmation from "pages/forms/_components/TransactionTicketCancelConfirmation";
import TransactionTicketSignConfirmation from "pages/forms/_components/TransactionTicketSignConfirmation";
import FrequentDestinationCreateTransfer from "pages/frequentDestinations/CreateFrequentDestinationTransfer";
import DebinRequests from "pages/charges/DebinRequests";
import DebinAddAccounts from "pages/charges/DebinAddAccounts";
import DebinAddAccountsSummary from "pages/charges/DebinAddAccountsSummary";
import DebinCancelSummary from "pages/charges/DebinCancelSummary";
import IncomingDebinList from "pages/debinPayments/IncomingDebinList";
import IncomingDebinDetail from "pages/debinPayments/_components/IncomingDebinDetail";
import SentDebinDetail from "pages/charges/SentDebinDetail";
import Deposits from "pages/deposits/Deposits";
import DepositDetails from "pages/deposits/Details";
import DepositConstitute from "pages/deposits/DepositConstitute";
import DepositSummary from "pages/deposits/DepositSummary";
import DepositConstituteTicket from "pages/deposits/tickets/DepositConstituteTicket";
import RequestDebin from "pages/charges/_components/RequestDebin";
import RequestCredin from "pages/transfer/RequestCredin";
import CredinPrepareConfirmation from "pages/transfer/CredinPrepareConfirmation";
import GenerateCredin from "pages/transfer/GenerateCredin";
import FixedTermAcceptance from "pages/fixedTermAcceptance/FixedTermAcceptance";
import FixedTermAcceptanceDetail from "pages/fixedTermAcceptance/_components/FixedTermAcceptanceDetail";
import AcceptFixedTermConfirm from "pages/fixedTermAcceptance/AcceptFixedTermConfirm";
import DeclineFixedTermConfirm from "pages/fixedTermAcceptance/DeclineFixedTermConfirm";
import CreateDebinSummary from "pages/charges/CreateDebinSummary";
import AcceptDebinConfirm from "pages/debinPayments/AcceptDebinConfirm";
import IncomingDebinTransaction from "pages/debinPayments/IncomingDebinTransaction";
import DeclineDebinConfirm from "pages/debinPayments/DeclineDebinConfirm";
import EcheqsMainPage from "pages/checks/Echeqs/EcheqsMainPage";
import ConfirmEcheqAction from "pages/checks/Echeqs/ConfirmEcheqAction";
import RequestReturnEcheqAction from "pages/checks/Echeqs/RequestReturnEcheqAction";
import EndorseEcheqStep1 from "pages/checks/Echeqs/EcheqActions/EndorseEcheqStep1";
import AnulateEcheqAction from "pages/checks/Echeqs/AnulateEcheqAction";
import NegotiateEcheq from "pages/checks/Echeqs/EcheqActions/NegotiateEcheq";
import ConfirmEndorseEcheqAction from "pages/checks/Echeqs/ConfirmEndorseEcheqAction";
import Token from "pages/settings/Token";
import ReceivedEcheqsDetail from "pages/checks/Echeqs/ReceivedEcheqsDetail";
import EmittedEcheqsDetail from "pages/checks/Echeqs/EmittedEcheqsDetail";
import PayThirdPartiesList from "pages/payThirdParties/List";
import NewPayment from "pages/payThirdParties/newPayment";
import NewPaymentSummary from "pages/payThirdParties/newPaymenSummary";

import ThirdPaymentDetail from "pages/payThirdParties/ThirdPaymentDetail";
import EndorseTransaction from "pages/checks/Echeqs/EcheqTransactions/EndorseTransaction";

import EcheqTransaction from "pages/checks/Echeqs/EcheqTransactions/EcheqTransaction";
import MultipleEcheqsTransaction from "pages/checks/Echeqs/EcheqTransactions/MultipleEcheqsTransaction";
import ServicePaymentsMain from "pages/servicePayments/ServicePaymentsMain";
import ServiceNewPayment from "pages/servicePayments/ServiceNewPayment";

import NewPaymentTicket from "pages/servicePayments/NewPaymentTicket";
import CodeActivationMobile from "pages/settings/_components/token/CodeActivationMobile";
import CodeActivationPasswordMobile from "pages/settings/_components/token/CodeAtivationPasswordMobile";
import EmitEcheq from "pages/checks/Echeqs/EcheqActions/EmitEcheq";
import ConfirmEmitEcheq from "pages/checks/Echeqs/ConfirmEmitEcheq";
import EmitEcheqTransaction from "pages/checks/Echeqs/EcheqTransactions/EmitEcheqTransaction";
import EmitCesion from "pages/checks/Echeqs/EcheqActions/EmitCesion";
import ConfirmCesionEcheqAction from "pages/checks/Echeqs/ConfirmCesionEcheqAction";
import CesionEcheqTransaction from "pages/checks/Echeqs/EcheqTransactions/CesionEcheqTransaction";
import ChangeTokenPass from "pages/settings/_components/token/ChangeTokenPass";
import AdhesionesList from "pages/servicePayments/AdhesionesList";
import UnattachServiceConfirmationTicket from "pages/servicePayments/UnattachServiceConfirmationTicket";
import RecoverToken from "pages/settings/_components/token/RecoverTokenPassword";
import TokenPassword from "pages/settings/TokenPassword";
import TokenRandomCode from "pages/settings/TokenRandomCode";
import DisabledTokenPass from "pages/settings/_components/token/DisabledTokenPass";
import ConfirmAcceptReturnEcheq from "pages/checks/Echeqs/ConfirmAcceptReturnEcheq";
import EcheqAcceptReturnTransaction from "pages/checks/Echeqs/EcheqTransactions/EcheqAcceptReturnTransaction";
import FinancingId from "pages/financing/_components/FinancingId";
import FeedsDetail from "pages/financing/_components/FeedsDetail";
import FinancingListCategories from "pages/financing/FinancingListCategories";
import FinancingDetail from "pages/financing/_components/FinancingDetail";
import TokenValidateRandomCode from "pages/settings/TokenValidateRandomCode";

import PayPaymentAFIP from "pages/servicePayments/PayPaymentAFIP";
import PayPaymentAFIPSummary from "pages/servicePayments/PayPaymentAFIPSummary";
import PayPaymentAFIPConfirmation from "pages/servicePayments/PayPaymentAFIPConfirmation";
import PayPaymentAFIPTicket from "pages/servicePayments/tickets/PayPaymentAFIPTicket";
import DeletePaymentAFIPSummary from "pages/servicePayments/DeletePaymentAFIPSummary";
import DeletePaymentAFIPTicket from "pages/servicePayments/tickets/DeletePaymentAFIPTicket";
import PaymentAFIPDetail from "pages/servicePayments/PaymentAFIPDetail";
import EnabledContributorsList from "pages/enabledContributors/EnabledContributorsList";
import AgendaPayment from "pages/servicePayments/AgendaPayment";
import AgendaPaymentDetail from "pages/servicePayments/AgendaPaymentDetail";
import RecurrentDebins from "pages/debinPayments/_components/recurrentDebin/RecurrentDebins";
import LoadDebinRecurrency from "pages/debinPayments/_components/recurrentDebin/LoadDebinRecurrency";
import ConfirmRecurrencyDebin from "pages/debinPayments/_components/recurrentDebin/ConfirmRecurrencyDebin";
import ConfirmRecurrencyDebinAction from "pages/debinPayments/_components/recurrentDebin/ConfirmRecurrencyDebinAction";
import AgendaServicePaymentsConfirmationTicket from "pages/servicePayments/AgendaServicePaymentsConfirmationTicket";
import ChecksDetail from "pages/checks/ChecksDetail";
import ChecksDetailInfo from "pages/checks/ChecksDetailInfo";
import RecurrencyDetail from "pages/debinPayments/_components/recurrentDebin/RecurrencyDetail";
import PrestacionTransaction from "pages/debinPayments/_components/recurrentDebin/PrestacionTransaction";
import RecurrencyTransaction from "pages/debinPayments/_components/recurrentDebin/RecurrencyTransaction";
import DiscountedChecksDetail from "pages/checks/DiscountedChecksDetail";
import DiscountedChecksDetailInfo from "pages/checks/DiscountedChecksDetailInfo";
import MetrocorpMain from "pages/metrocorp/MetrocorpMain";
import EcomexOperationsMain from "pages/ecomex/EcomexOperationsMain";
import ConsolidatedPosition from "pages/ConsolidatedPosition/ConsolidatedPosition";
import EcomexInformativeRegimeMain from "pages/ecomex/EcomexInformativeRegimeMain";
import EcomexAlertsAndDuesMain from "pages/ecomex/EcomexAlertsAndDuesMain";
import ThirdPaymentTicket from "pages/payThirdParties/ThirdPaymentTicket";
import AccountOpeningTicket from "pages/accounts/AccountOpeningTicket";
import ServicePaymentsConfirmationTicket from "pages/servicePayments/ServicePaymentsConfirmationTicket";
import FundcorpOperationsMain from "pages/fundcorp/FundcorpOperationsMain";
import FundcorpInformationMain from "pages/fundcorp/FundcorpInformationMain";
import FundRescue from "pages/fundcorp/_components/FundRescue";
import FundRescueSummary from "pages/fundcorp/_components/FundRescueSummary";
import RescueTicket from "pages/fundcorp/_components/RescueTicket";
import FundSubscribe from "pages/fundcorp/_components/FundSubscribe";
import FundSubscribeSummary from "pages/fundcorp/_components/_subscribe/FundSubscribeSummary";
import SubscribeTicket from "pages/fundcorp/_components/_subscribe/SubscribeTicket";
import FundRegisterShareAccount from "pages/fundcorp/_components/FundRegisterShareAccount";
import ShareAccountTicket from "pages/fundcorp/_components/_subscribe/ShareAccountTicket";
import InvestorProfile from "pages/fundcorp/_components/InvestorProfile";
import FundScheduledCancel from "pages/fundcorp/_components/FundScheduledCancel";
import MultiplePaymentsConfirmation from "pages/servicePayments/multiple/MultiplePaymentsConfirmation";
import MultiplePaymentsSuccess from "pages/servicePayments/multiple/MultiplePaymentsSuccess";
import MultipleAFIPSummary from "pages/servicePayments/multiple/MultipleAFIPSummary";
import MultipleAFIPSuccess from "pages/servicePayments/multiple/MultipleAFIPSuccess";
import MultiplePaymentAgendaDetail from "pages/servicePayments/multiple/MultiplePaymentAgendaDetail";
import EmitMassive from "pages/checks/Echeqs/Massive/EmitMassive";
import EmitMassiveSummary from "pages/checks/Echeqs/Massive/EmitMassiveSummary";
import MultipleAFIPDetail from "pages/servicePayments/multiple/MultipleAFIPDetail";
import EmitMassiveTicket from "pages/checks/Echeqs/Massive/EmitMassiveTicket";
import BatchDetail from "pages/checks/Echeqs/Massive/BatchDetail";
import DownloadEcheqListCuit from "pages/checks/Echeqs/DownloadEcheqListCuit";
import MultipleTransferDashboard from "pages/transfer/multiple/MultipleTransferDashboard";
import MultipleTransfer from "pages/transfer/multiple/MultipleTransfer";
import MultipleTransferSummary from "pages/transfer/multiple/MultipleTransferSummary";
import MultipleTransferTicket from "pages/transfer/multiple/MultipleTransferTicket";
import MultipleTransferDetail from "pages/transfer/multiple/MultipleTransferDetail";
import CedipList from "pages/cedip/CedipList";
import CedipDetail from "pages/cedip/CedipDetail";
import AcceptOrRejectCedip from "pages/cedip/AcceptOrRejectCedip";
import MultipleSignaturesDelete from "pages/transactions/MultipleSignaturesDelete";
import ProcessingMultipleDelete from "pages/transactions/ProcessingMultipleDelete";
import RejectCedipTicket from "pages/cedip/_components/RejectCedipTicket";

class PrivateRoutes extends Component {
    static propTypes = {
        hasActiveSession: bool,
        activeEnvironment: shape({
            permissions: shape({
                accounts: bool,
                requestTransactionCancellation: bool,
                transferForeign: bool,
                transferInternal: bool,
                transferLocal: bool,
                transferThirdParties: bool,
            }),
            forms: obj,
            administrationScheme: string,
            type: string,
        }).isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    render() {
        const { hasActiveSession, activeEnvironment } = this.props;

        if (!hasActiveSession) {
            return <Redirect to="/" />;
        }

        return (
            <>
                <Switch>
                    <DefaultLayout exact path="/desktop" component={Desktop} />
                    <DefaultLayout exact path="/ConsolidatedPosition" component={ConsolidatedPosition} />
                    <DefaultLayout exact path="/pendingActions" component={PendingActions} withoutLayout />
                    <DefaultLayout exact path="/completePEP" component={StepPEP} withoutLayout />
                    <DefaultLayout exact path="/completeIRS" component={StepIRS} withoutLayout />
                    {/* CHECKS */}
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions.requestCheckbook}
                        exact
                        path="/checks"
                        component={Checks}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions.requestCheckbook}
                        exact
                        path="/checks/summary"
                        component={ChecksSummary}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions.requestCheckbook}
                        exact
                        path="/checks/back"
                        component={Checks}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions.requestCheckbook}
                        exact
                        path="/checks/modify"
                        component={Checks}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions.requestCheckbook}
                        exact
                        path="/checks/transaction"
                        component={CheckTransaction}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["checks.detail.view"]}
                        exact
                        path="/checksDetail"
                        component={ChecksDetail}
                    />
                    <DefaultLayout exact path="/checksDetail/:checkNumber" component={ChecksDetailInfo} />
                    <DefaultLayout exact path="/discountedChecksDetail" component={DiscountedChecksDetail} />
                    <DefaultLayout
                        exact
                        path="/discountedChecksDetail/:checkNumber"
                        component={DiscountedChecksDetailInfo}
                    />
                    {/* ACCOUNTS */}
                    <DefaultLayout exact path="/accounts" component={Accounts} />
                    <DefaultLayout exact path="/accountOpening" component={AccountOpening} />
                    <DefaultLayout exact path="/accountOpening/back" component={AccountOpening} />
                    <DefaultLayout exact path="/accountOpening/modify" component={AccountOpening} />
                    <DefaultLayout exact path="/accountOpening/summary" component={AccountOpeningSummary} />
                    <DefaultLayout exact path="/accountOpening/transaction" component={AccountOpeningTicket} />
                    <DefaultLayout exact path="/accounts/:id" component={AccountDetail} />
                    <DefaultLayout exact path="/accounts/:id/movement" component={MovementDetail} />
                    <DefaultLayout exact path="/accounts/:id/setAlias" component={SetAlias} />
                    <DefaultLayout exact path="/accounts/:id/statements" component={AccountStatements} />
                    <DefaultLayout exact path="/accounts/:id/filters" component={Filters} />
                    <DefaultLayout exact path="/accounts/:id/filters/results" component={Results} />
                    <DefaultLayout exact path="/accounts/:id/transfers" component={MobileTransfers} />
                    <DefaultLayout exact path="/account/proyectedBalance/:id" component={ProyectedBalance} />
                    <DefaultLayout exact path="/account/historicBalance/:id" component={HistoricBalance} />
                    <DefaultLayout exact path="/account/:id/setProductAlias" component={SetProductAlias} />
                    {/* SETTINGS */}
                    <DefaultLayout exact path="/settings" component={Settings} />
                    <DefaultLayout exact path="/settings/changePassword" component={ChangePassword} />
                    <DefaultLayout exact path="/settings/changeUserpass" component={ChangeUserpass} />
                    <DefaultLayout exact path="/settings/changeSecuritySeal" component={ChangeSecuritySeal} />
                    <DefaultLayout
                        exact
                        path="/settings/changeSecuritySeal/confirmation"
                        component={ChangeSecuritySealConfirmation}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/notificationsConfiguration"
                        component={NotificationsConfiguration}
                    />
                    <DefaultLayout
                        path="/settings/notificationsConfiguration/:communicationType"
                        component={NotificationsConfigurationTransports}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/fingerprintConfiguration"
                        component={FingerprintConfiguration}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/fingerprintConfiguration/deleteConfirmation"
                        component={FingerprintDeleteConfirmation}
                    />
                    <DefaultLayout exact path="/settings/myDevices" component={MyDevices} />
                    <DefaultLayout exact path="/settings/token" component={Token} />
                    <DefaultLayout exact path="/settings/token/codeActivation" component={CodeActivationMobile} />
                    <DefaultLayout
                        exact
                        path="/settings/token/codeActivation/changuedPassword"
                        component={CodeActivationPasswordMobile}
                    />
                    <DefaultLayout exact path="/settings/token/ChangeTokenPass" component={ChangeTokenPass} />
                    <DefaultLayout exact path="/settings/token/tokenPassword" component={TokenPassword} />
                    <DefaultLayout exact path="/settings/token/tokenRandomCode" component={TokenRandomCode} />
                    <DefaultLayout exact path="/settings/token/recoverPass" component={RecoverToken} />
                    <DefaultLayout exact path="/settings/token/disabledTokenPass" component={DisabledTokenPass} />
                    <DefaultLayout
                        exact
                        path="/settings/token/synchronization/ValidateRandomCode"
                        component={TokenValidateRandomCode}
                    />
                    <DefaultLayout
                        exact
                        path="/settings/token/synchronization/tokenPassword"
                        component={TokenPassword}
                    />
                    <DefaultLayout exact path="/accountsOfficer" component={AccountsOfficer} />
                    <DefaultLayout exact path="/settings/esign/documents" component={DocumentsIndex} />
                    <DefaultLayout exact path="/settings/esign/documentsSigned" component={EsignDocumentsSigned} />
                    <DefaultLayout exact path="/settings/changeEnvironment" component={ChangeEnvironment} />
                    <DefaultLayout exact path="/settings/setDefaultEnvironment" component={SetDefaultEnvironment} />
                    <DefaultLayout exact path="/settings/changeLanguage" component={ChangeLanguage} />
                    <DefaultLayout exact path="/settings/changeEmail" component={ChangeEmail} />
                    <DefaultLayout
                        exact
                        path="/settings/changeEmail/confirmation"
                        component={ChangeEmailConfirmation}
                    />
                    <DefaultLayout exact path="/settings/ChangeAddresses" component={ChangeAddresses} />
                    <DefaultLayout exact path="/settings/changePhone" component={ChangePhone} />
                    <DefaultLayout
                        exact
                        path="/settings/changePhone/confirmation"
                        component={ChangePhoneConfirmation}
                    />
                    <DefaultLayout exact path="/settings/LastLogin" component={LastLogin} />
                    <DefaultLayout exact path="/settings/pointsOfInterest" component={PointsOfInterest} />
                    {/* TRANSFERS */}
                    <DefaultLayout exact path="/transfer" component={Transfer} />
                    <DefaultLayout exact path="/transfer/back" component={Transfer} />
                    <DefaultLayout exact path="/v2/transfer/historic" component={TransferHistoric} />
                    <DefaultLayout
                        exact
                        path="/transfer/multiple"
                        component={MultipleTransferDashboard}
                        authorizedRoute={!!activeEnvironment?.permissions?.transferMultiple}
                    />
                    <DefaultLayout
                        exact
                        path="/transfer/multiple/detail/:id"
                        component={MultipleTransferDetail}
                        authorizedRoute={!!activeEnvironment?.permissions?.transferMultiple}
                    />
                    <DefaultLayout
                        exact
                        path="/transfer/multiple/create"
                        component={MultipleTransfer}
                        authorizedRoute={!!activeEnvironment?.permissions?.transferMultiple}
                    />
                    <DefaultLayout
                        exact
                        path="/transfer/multiple/confirm"
                        component={MultipleTransferSummary}
                        authorizedRoute={!!activeEnvironment?.permissions?.transferMultiple}
                    />
                    <DefaultLayout
                        exact
                        path="/transfer/multiple/cancelled"
                        component={MultipleTransferTicket}
                        authorizedRoute={!!activeEnvironment?.permissions?.transferMultiple}
                    />
                    <DefaultLayout
                        exact
                        path="/transfer/multiple/pending"
                        component={MultipleTransferTicket}
                        authorizedRoute={!!activeEnvironment?.permissions?.transferMultiple}
                    />
                    <DefaultLayout
                        exact
                        path="/transfer/multiple/failed"
                        component={MultipleTransferTicket}
                        authorizedRoute={!!activeEnvironment?.permissions?.transferMultiple}
                    />
                    <DefaultLayout
                        exact
                        path="/transfer/multiple/confirmation"
                        component={MultipleTransferTicket}
                        authorizedRoute={!!activeEnvironment?.permissions?.transferMultiple}
                    />
                    <DefaultLayout exact path="/transfer/cancelled" component={TransferTransaction} />
                    <DefaultLayout exact path="/transfer/summary" component={TransferSummary} />
                    <DefaultLayout exact path="/transfer/confirmation" component={TransferTransaction} />
                    <DefaultLayout exact path="/transfer/failed" component={TransferTransaction} />
                    <DefaultLayout exact path="/transfer/scheduled" component={TransferScheduled} />
                    <DefaultLayout exact path="/transfer/scheduled/cancel" component={TransferScheduledCancel} />
                    <DefaultLayout exact path="/transfer/pending" component={TransferTransaction} />
                    <DefaultLayout exact path="/transfer/modify" component={Transfer} />
                    <DefaultLayout exact path="/transfer/error" component={TransferError} />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions.frequentDestination}
                        exact
                        path="/FrequentDestination"
                        component={FrequentDestinationList}
                    />
                    <DefaultLayout
                        exact
                        authorizedRoute={!!activeEnvironment?.permissions["frequentDestination.manage"]}
                        path="/FrequentDestination/create/step1"
                        component={FrequentDestinationCreate}
                    />
                    <DefaultLayout
                        exact
                        authorizedRoute={!!activeEnvironment?.permissions["frequentDestination.manage"]}
                        path="/frequentDestination/create/:id/confirm"
                        component={FrequentDestinationCreateConfirmation}
                    />
                    <DefaultLayout
                        exact
                        authorizedRoute={!!activeEnvironment?.permissions["frequentDestination.manage"]}
                        path="/FrequentDestination/detail/:id/"
                        component={FrequentDestinationDetail}
                    />
                    <DefaultLayout
                        exact
                        authorizedRoute={!!activeEnvironment?.permissions["frequentDestination.manage"]}
                        path="/transfer/frequentDestination/:id/create/"
                        component={FrequentDestinationCreateTransfer}
                    />
                    <DefaultLayout
                        exact
                        authorizedRoute={!!activeEnvironment?.permissions["frequentDestination.manage"]}
                        path="/frequentDestination/:id/delete"
                        component={FrequentDestinationDelete}
                    />
                    <DefaultLayout
                        exact
                        authorizedRoute={!!activeEnvironment?.permissions["frequentDestination.manage"]}
                        path="/frequentDestination/:id/modify"
                        component={ModifyFrequentDestination}
                    />
                    <DefaultLayout
                        exact
                        authorizedRoute={!!activeEnvironment?.permissions["frequentDestination.manage"]}
                        path="/frequentDestination/:id/modify/confirmation"
                        component={ModifyFrequentDestinationConfirmations}
                    />
                    <DefaultLayout exact path="/requestCredin" component={RequestCredin} />
                    <DefaultLayout exact path="/requestCredin/generateCredin" component={GenerateCredin} />
                    <DefaultLayout exact path="/confirmCredin/transaction" component={CredinPrepareConfirmation} />
                    {/* ECHEQS */}
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs"
                        component={EcheqsMainPage}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/summary/:envId/:envName/:state"
                        component={EcheqsMainPage}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/single/:envId/:envName/:echeqId/:echeqType"
                        component={EcheqsMainPage}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/action/confirmation"
                        component={ConfirmEcheqAction}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/return/confirmation"
                        component={RequestReturnEcheqAction}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/anulate/confirmation"
                        component={AnulateEcheqAction}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/action/endorse"
                        component={EndorseEcheqStep1}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/action/cesion"
                        component={EmitCesion}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/action/cesion/confirm"
                        component={ConfirmCesionEcheqAction}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/action/negotiate"
                        component={NegotiateEcheq}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/action/return/accept"
                        component={ConfirmAcceptReturnEcheq}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/action/endorse/confirm"
                        component={ConfirmEndorseEcheqAction}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/received/detail/:echeqId"
                        component={ReceivedEcheqsDetail}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/emitted/detail/:echeqId"
                        component={EmittedEcheqsDetail}
                    />
                    <DefaultLayout exact path="/echeqs/confirm/endorse" component={EndorseTransaction} />
                    <DefaultLayout exact path="/echeqs/pending/endorse" component={EndorseTransaction} />
                    <DefaultLayout exact path="/echeqs/cancelled/endorse" component={EndorseTransaction} />
                    <DefaultLayout exact path="/echeqs/failed/endorse" component={EndorseTransaction} />
                    <DefaultLayout exact path="/echeqs/transaction/action" component={EcheqTransaction} />
                    <DefaultLayout
                        exact
                        path="/echeqs/return/accept/transaction"
                        component={EcheqAcceptReturnTransaction}
                    />
                    <DefaultLayout exact path="/echeqs/multiple/:status" component={MultipleEcheqsTransaction} />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/emit"
                        component={EmitEcheq}
                    />
                    <DefaultLayout exact path="/echeqs/action/emit/confirm" component={ConfirmEmitEcheq} />
                    <DefaultLayout exact path="/echeqs/confirm/emit" component={EmitEcheqTransaction} />
                    <DefaultLayout exact path="/echeqs/failed/emit" component={EmitEcheqTransaction} />
                    <DefaultLayout exact path="/echeqs/pending/emit" component={EmitEcheqTransaction} />
                    <DefaultLayout exact path="/echeqs/cancelled/emit" component={EmitEcheqTransaction} />
                    <DefaultLayout exact path="/echeqs/confirm/cesion" component={CesionEcheqTransaction} />
                    <DefaultLayout exact path="/echeqs/failed/cesion" component={CesionEcheqTransaction} />
                    <DefaultLayout exact path="/echeqs/pending/cesion" component={CesionEcheqTransaction} />
                    <DefaultLayout exact path="/echeqs/cancelled/cesion" component={CesionEcheqTransaction} />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.massiveEmitEcheq}
                        exact
                        path="/echeqs/emit/massive"
                        component={EmitMassive}
                    />
                    <DefaultLayout exact path="/echeqs/emit/massive/confirm" component={EmitMassiveSummary} />
                    <DefaultLayout exact path="/echeqs/emitMassive/confirmation" component={EmitMassiveTicket} />
                    <DefaultLayout exact path="/echeqs/emitMassive/pending" component={EmitMassiveTicket} />
                    <DefaultLayout exact path="/echeqs/emitMassive/failed" component={EmitMassiveTicket} />
                    <DefaultLayout exact path="/echeqs/emitMassive/cancelled" component={EmitMassiveTicket} />
                    <DefaultLayout exact path="/echeqs/emit/massive/:idTransaction/detail" component={BatchDetail} />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["operate.echeqs"]}
                        exact
                        path="/echeqs/downloadEcheqListCuit"
                        component={DownloadEcheqListCuit}
                    />
                    {/* SERVICE-PAYMENTS */}
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["pay.afip"]}
                        exact
                        path="/newPayment"
                        component={ServiceNewPayment}
                    />
                    <DefaultLayout
                        authorizedRoute={
                            !!(
                                activeEnvironment?.permissions["pay.afip"] &&
                                activeEnvironment?.permissions["service.payments.view"]
                            )
                        }
                        exact
                        path="/servicePayments"
                        component={ServicePaymentsMain}
                    />
                    <DefaultLayout
                        exact
                        path="/servicePaymentsConfirmationTicket"
                        component={ServicePaymentsConfirmationTicket}
                    />
                    <DefaultLayout exact path="/newPayment/pending" component={NewPaymentTicket} />
                    <DefaultLayout exact path="/newPayment/cancelled" component={NewPaymentTicket} />
                    <DefaultLayout exact path="/newPayment/confirmation" component={NewPaymentTicket} />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["pay.afip"]}
                        exact
                        path="/adhesiones/list"
                        component={AdhesionesList}
                    />
                    <DefaultLayout
                        exact
                        path="/desadherir/confirmation"
                        component={UnattachServiceConfirmationTicket}
                    />
                    <DefaultLayout exact path="/payMultipleAFIPSuccess" component={MultipleAFIPSuccess} />
                    <DefaultLayout exact path="/payMultipleAFIPSummary" component={MultipleAFIPSummary} />
                    <DefaultLayout exact path="/payMultipleAFIPDetail" component={MultipleAFIPDetail} />
                    <DefaultLayout exact path="/payPaymentAFIP" component={PayPaymentAFIP} />
                    <DefaultLayout exact path="/paymentAFIP" component={PaymentAFIPDetail} />
                    <DefaultLayout exact path="/payPaymentAFIPSummary" component={PayPaymentAFIPSummary} />
                    <DefaultLayout exact path="/payPaymentAFIPConfirmation" component={PayPaymentAFIPConfirmation} />
                    <DefaultLayout
                        exact
                        path="/payPaymentAFIP/:idTransaction/ticket"
                        component={PayPaymentAFIPTicket}
                    />
                    <DefaultLayout exact path="/deletePaymentAFIPSummary" component={DeletePaymentAFIPSummary} />
                    <DefaultLayout
                        exact
                        path="/deletePaymentAFIP/:idTransaction/ticket"
                        component={DeletePaymentAFIPTicket}
                    />
                    <DefaultLayout exact path="/payments/enabledContributors" component={EnabledContributorsList} />
                    <DefaultLayout exact path="/agenda/payment" component={AgendaPayment} />
                    <DefaultLayout
                        exact
                        path="/agenda/payment/detail/:userLp/:conceptCode"
                        component={AgendaPaymentDetail}
                    />
                    <DefaultLayout
                        exact
                        path="/agenda/multiplePayment/detail/:key"
                        component={MultiplePaymentAgendaDetail}
                    />
                    <DefaultLayout
                        exact
                        path="/agendaServicePaymentsConfirmationTicket"
                        component={AgendaServicePaymentsConfirmationTicket}
                    />
                    <DefaultLayout
                        exact
                        path="/multiplePaymentsConfirmation"
                        component={MultiplePaymentsConfirmation}
                    />
                    <DefaultLayout exact path="/multiplePaymentsSuccess" component={MultiplePaymentsSuccess} />
                    {/* CHARGES */}
                    <DefaultLayout exact path="/charges/requestDebin" component={RequestDebin} />
                    <DefaultLayout exact path="/charges/debin/detail/:idDebin" component={SentDebinDetail} />
                    <DefaultLayout exact path="/charges/requestDebin/summary" component={CreateDebinSummary} />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions.charges}
                        exact
                        path="/charges/generateDebin"
                        component={DebinRequests}
                    />
                    <DefaultLayout exact path="/charges/debin/addAccounts" component={DebinAddAccounts} />
                    <DefaultLayout
                        exact
                        path="/charges/debin/addAccounts/summary"
                        component={DebinAddAccountsSummary}
                    />
                    <DefaultLayout exact path="/charges/debin/cancel/summary" component={DebinCancelSummary} />
                    {/* FORMS */}
                    <DefaultLayout exact path="/form/:idForm" component={Form} />
                    <DefaultLayout path="/transaction/:idTransaction" component={Transaction} exact />
                    <DefaultLayout
                        path="/form/:idForm/processDetail"
                        component={FormPaymentLines}
                        componentProps={{
                            isConfirmation: true,
                        }}
                    />
                    <DefaultLayout
                        path="/transaction/:idTransaction/processDetail"
                        component={TransactionPaymentLines}
                    />
                    {/* PAYMENT LISTS */}
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["pay.debin"]}
                        exact
                        path="/payments/payDebin"
                        component={IncomingDebinList}
                    />
                    <DefaultLayout exact path="/payments/recurrent" component={RecurrentDebins} />
                    <DefaultLayout exact path="/payments/recurrent/load" component={LoadDebinRecurrency} />
                    <DefaultLayout exact path="/payments/recurrent/load/confirm" component={ConfirmRecurrencyDebin} />
                    <DefaultLayout exact path="/payments/recurrent/detail/:recurrencyId" component={RecurrencyDetail} />
                    <DefaultLayout
                        exact
                        path="/payments/recurrent/load/transaction"
                        component={PrestacionTransaction}
                    />
                    <DefaultLayout
                        exact
                        path="/payments/recurrent/action/transaction"
                        component={RecurrencyTransaction}
                    />
                    <DefaultLayout
                        exact
                        path="/payments/recurrency/action/confirmation"
                        component={ConfirmRecurrencyDebinAction}
                    />
                    <DefaultLayout exact path="/payments/payDebin/detail/:idDebin" component={IncomingDebinDetail} />
                    <DefaultLayout exact path="/payments/payDebin/accept/confirm" component={AcceptDebinConfirm} />
                    <DefaultLayout exact path="/payments/payDebin/decline/confirm" component={DeclineDebinConfirm} />
                    <DefaultLayout
                        exact
                        path="/confirmIncomingDebin/transaction"
                        component={IncomingDebinTransaction}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions["pay.third.parties"]}
                        path="/payThirdParties/list"
                        component={PayThirdPartiesList}
                    />
                    <DefaultLayout path="/payThirdParties/newPayment" component={NewPayment} />
                    <DefaultLayout path="/payThirdParties/summary" component={NewPaymentSummary} />
                    <DefaultLayout path="/payThirdParties/confirmation" component={ThirdPaymentTicket} />
                    <DefaultLayout exact path="/payThirdParties/pending" component={ThirdPaymentTicket} />
                    <DefaultLayout path="/payThirdParties/cancelled" component={ThirdPaymentTicket} />
                    <DefaultLayout
                        exact
                        path="/payThirdParties/:idTransaction/detail"
                        component={ThirdPaymentDetail}
                        componentProps={{
                            isConfirmation: true,
                            shouldLoadForm: false,
                        }}
                    />
                    {/* MANUAL MASS PAYMENT */}
                    <DefaultLayout path="/form/:idForm/manual" component={FormPaymentLines} />
                    {/* COMMUNICATIONS */}
                    <DefaultLayout exact path="/communications" component={CommunicationMain} />
                    <DefaultLayout exact path="/communications/compose" component={NewMessage} />
                    <DefaultLayout exact path="/communications/reply/:id" component={ReplyMessage} />
                    <DefaultLayout exact path="/communications/read/:id" component={ReadMessage} />
                    {/* CANCEL TRANSACTION */}
                    <DefaultLayout exact path="/transactions/cancel" component={TransactionTicketCancelConfirmation} />
                    {/* SIGN TRANSACTION */}
                    <DefaultLayout exact path="/transactions/sign" component={TransactionTicketSignConfirmation} />
                    {/* TRANSACTIONS */}
                    <DefaultLayout exact path="/transactions/list" component={TransactionsList} />
                    <DefaultLayout exact path="/pendingTransaction/list" component={PendingTransactionList} />
                    <DefaultLayout
                        exact
                        path="/pendingTransaction/signMultiple"
                        component={MultipleSignaturesSummary}
                    />
                    <DefaultLayout
                        exact
                        path="/pendingTransaction/deleteMultiple"
                        component={MultipleSignaturesDelete}
                    />
                    <DefaultLayout
                        exact
                        path="/pendingTransaction/multipleSignatures/processing"
                        component={ProcessingMultipleSignatures}
                    />
                    <DefaultLayout
                        exact
                        path="/pendingTransaction/multipleDelete/processing"
                        component={ProcessingMultipleDelete}
                    />
                    <DefaultLayout exact path="/transactions/list/filters" component={TransactionsListFilters} />
                    <DefaultLayout
                        exact
                        path="/transactions/list/filters/results"
                        component={TransactionsFiltersResults}
                    />
                    <DefaultLayout path="/form/:formId/bankSearch/:fieldId" component={BankSearch} />
                    <DefaultLayout path="/form/:formId/templates" component={Templates} />
                    {/* EXTERNAL */}
                    <DefaultLayout exact path="/privacyPolicy" component={PrivacyPolicy} />
                    <DefaultLayout exact path="/termsAndConditions" component={TermsAndConditions} />
                    <DefaultLayout exact path="/support" component={SupportInfo} />
                    {/* administration */}
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/users/invite"
                        component={UserInvite}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/users/inviteStep2"
                        component={UserInviteStep2}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/users/GroupSignature"
                        component={UserInviteGroupSignature}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/users/inviteStep3"
                        component={UserInviteStep3}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        exact
                        path="/administration/medium/signature/:idTransaction/ticket"
                        component={AdministrationMediumSignatureTicket}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        path="/administration/users"
                        component={Administration}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        path="/administration/groups"
                        component={Administration}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        path="/administration/simple/signaturesSchemes"
                        component={Administration}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        path="/administration/medium/signaturesSchemes"
                        component={Administration}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        path="/administration/advanced/signaturesSchemes"
                        component={Administration}
                    />
                    <DefaultLayout
                        excludeEnvType={["retail"]}
                        path="/administration/restrictions"
                        component={Administration}
                    />
                    <DefaultLayout excludeEnvType={["retail"]} path="/administration" component={Administration} />
                    {/* end of administration */}
                    {/* Deposits */}
                    <DefaultLayout
                        exact
                        path="/deposits"
                        component={Deposits}
                        authorizedRoute={!!activeEnvironment.permissions.deposits}
                    />
                    <DefaultLayout exact path="/deposits/:id/" component={DepositDetails} />
                    <DefaultLayout exact path="/depositsConstitute" component={DepositConstitute} />
                    <DefaultLayout exact path="/depositsSummary" component={DepositSummary} />
                    <DefaultLayout
                        exact
                        path="/depositsConfirmation/:idTransaction/ticket"
                        component={DepositConstituteTicket}
                    />
                    ,
                    <DefaultLayout exact path="/deposit/:id/setProductAlias" component={SetProductAlias} />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundcorpOperations}
                        exact
                        path="/fundcorpOperations"
                        component={FundcorpOperationsMain}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundcorpInformation}
                        exact
                        path="/fundInformation"
                        component={FundcorpInformationMain}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/fundcorpOperations/rescue"
                        component={FundRescue}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/fundcorpOperations/rescue/:fondoNumero/:fondoAbreviatura/:fondoMoneda/:cuotapartistaNumero"
                        component={FundRescue}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/fundcorpOperations/rescue/back"
                        component={FundRescue}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/fundcorpOperations/rescue/pending"
                        component={RescueTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/fundcorpOperations/rescue/confirmation"
                        component={RescueTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/fundcorpOperations/rescue/cancelled"
                        component={RescueTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/fundcorpOperations/rescue/failed"
                        component={RescueTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/fundcorpOperations/rescue/scheduled"
                        component={RescueTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/rescue/scheduled/cancel"
                        component={FundScheduledCancel}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/rescue/summary"
                        component={FundRescueSummary}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/fundcorpOperations/subscribe"
                        component={FundSubscribe}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/fundcorpOperations/subscribe/:fondoNumero/:cuotapartistaNumero"
                        component={FundSubscribe}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/subscribe/summary"
                        component={FundSubscribeSummary}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/subscribe/pending"
                        component={SubscribeTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/subscribe/confirmation"
                        component={SubscribeTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/subscribe/cancelled"
                        component={SubscribeTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/subscribe/failed"
                        component={SubscribeTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundRescue}
                        exact
                        path="/subscribe/scheduled"
                        component={SubscribeTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/subscribe/scheduled/cancel"
                        component={FundScheduledCancel}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/fundcorpOperations/investor-profile"
                        component={InvestorProfile}
                    />
                    {/* end of deposits */}
                    {/* Register ShareAccount */}
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/fundcorpOperations/registerShareAccount"
                        component={FundRegisterShareAccount}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/registerShareAccount/success"
                        component={ShareAccountTicket}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.fundSubscribe}
                        exact
                        path="/registerShareAccount/failed"
                        component={ShareAccountTicket}
                    />
                    {/* end of Register ShareAccount */}
                    {/* CEDIP */}
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.cedip}
                        exact
                        path="/cedip"
                        component={CedipList}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.cedip}
                        exact
                        path="/cedip/:idCedip/:fraction"
                        component={CedipDetail}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.cedip}
                        exact
                        path="/cedip/reject"
                        component={AcceptOrRejectCedip}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.cedip}
                        exact
                        path="/cedip/accept"
                        component={AcceptOrRejectCedip}
                    />
                    <DefaultLayout
                        authorizedRoute={!!activeEnvironment?.permissions?.cedip}
                        exact
                        path="/cedip/ticket"
                        component={RejectCedipTicket}
                    />
                    {/* end of CEDIP */}
                    {/* fixedTermAcceptance */}
                    <DefaultLayout exact path="/fixedTermAcceptance" component={FixedTermAcceptance} />
                    <DefaultLayout
                        exact
                        path="/fixedTermAcceptance/detail/:idDebin"
                        component={FixedTermAcceptanceDetail}
                    />
                    <DefaultLayout
                        exact
                        path="/fixedTermAcceptance/accept/confirm"
                        component={AcceptFixedTermConfirm}
                    />
                    <DefaultLayout
                        exact
                        path="/fixedTermAcceptance/decline/confirm"
                        component={DeclineFixedTermConfirm}
                    />
                    {/* Financing */}
                    <DefaultLayout exact path="/financing/categories" component={FinancingListCategories} />
                    <DefaultLayout exact path="/financing/:id" component={FinancingId} />
                    <DefaultLayout exact path="/financing/detail/:id" component={FinancingDetail} />
                    <DefaultLayout exact path="/financing/feeds/detail/:id" component={FeedsDetail} />
                    {/* end of financing */}
                    {/* Metrocorp */}
                    <DefaultLayout exact path="/metrocorp" component={MetrocorpMain} />
                    {/* end of metrocorp */}
                    {/* Ecomex */}
                    <DefaultLayout exact path="/ecomex/consultOperations" component={EcomexOperationsMain} />
                    <DefaultLayout exact path="/ecomex/informativeRegime" component={EcomexInformativeRegimeMain} />
                    <DefaultLayout exact path="/ecomex/alertsAndDues" component={EcomexAlertsAndDuesMain} />
                    {/* end of ecomex */}
                </Switch>
                <BackButtonListener />
                <Route path="/" component={SessionAboutToExpire} />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default withRouter(connect(mapStateToProps)(PrivateRoutes));
