/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";

import { selectors as loginSelectors } from "reducers/login";
import { selectors as assistantSelectors } from "reducers/assistant";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as statusActions } from "reducers/status";

import Notification from "pages/_components/Notification";
import Step1Content from "pages/login/_components/Step1Content";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Step0Content from "pages/login/_components/Step0Content";
import AssistantAlert from "pages/login/_components/AssistantAlert";
import { bool, func, shape, string } from "prop-types";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";

import Title from "pages/_components/title/title";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import { isMobileNative } from "util/device";

class LoginStep1 extends Component {
    static propTypes = {
        location: shape({
            pathname: string,
        }).isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        isFromAmazon: bool,
        isFromGoogle: bool,
        isFromMessenger: bool,
        isFromWhatsapp: bool,
        fingerprintSubmiting: shape({}),
        propsGrid: shape({}).isRequired,
        rememberedUser: bool,
        activeRegion: string.isRequired,
        hasActiveSession: bool.isRequired,
        isMobileNative: bool.isRequired,
    };

    static defaultProps = {
        isFromAmazon: false,
        isFromGoogle: false,
        isFromMessenger: false,
        isFromWhatsapp: false,
        fingerprintSubmiting: null,
        rememberedUser: false,
    };

    componentDidMount() {
        const { dispatch, hasActiveSession, location } = this.props;
        dispatch(statusActions.saveLastHref(location));
        if (!isMobileNative) {
            if (hasActiveSession) {
                dispatch(sessionActions.logout()); // End any previous session open session
            }
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/"));
    };

    mobileView = () => <Step1Content {...this.props} />;

    desktopView = () => {
        const { isFromAmazon, isFromGoogle, isFromMessenger, isFromWhatsapp, isDesktop } = this.props;

        return (
            <div className="above-the-fold">
                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        {(isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && <AssistantAlert />}

                        <Row className="justify-content-center">
                            <Step1Content {...this.props} />
                            {!isDesktop && <Step0Content />}
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    };

    getUserNameFormated = (firstName) => firstName.split(" ")[0];

    render() {
        const { isDesktop, fingerprintSubmiting, propsGrid } = this.props;

        if (!isDesktop) {
            return (
                <>
                    <Notification scopeToShow="login" />
                    <Container as="header" rowClassName="justify-content-start">
                        <Col xs="12" className="login-mobile-title justify-content-start">
                            <div className="w-min-content">
                                <Logo
                                    className={`svg-image logo logo-mobile-size ${isMobileNative ? "mt-48" : "mt-45"}`}
                                    logoColor
                                />
                            </div>
                        </Col>
                    </Container>
                    <MainContainer
                        showLoader={fingerprintSubmiting}
                        shouldHideOnLoad
                        className="login__layout main-container"
                        viewPageClassName="has-blackout-selector">
                        <Container>
                            <Col xs="12" className="login-mobile-title">
                                <h4>
                                    <I18n id="login.step1.header" accessibilityTextId="login.step1.header.a11y" />
                                </h4>
                            </Col>
                        </Container>
                        {this.mobileView()}
                    </MainContainer>
                </>
            );
        }

        return (
            <GridLayoutExternal scopeToShow="login" isDesktop={isDesktop} {...propsGrid}>
                <div className="welcome-title">
                    <Title id="login.step1.header" component="h1" tabIndex={0} className="title-content" />
                </div>
                <MainContainer
                    showLoader={fingerprintSubmiting}
                    shouldHideOnLoad
                    className="login__layout main-container"
                    viewPageClassName="has-blackout-selector">
                    {this.desktopView()}
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

const mapStateToProps = (state) => ({
    showCaptcha: loginSelectors.getShowCaptcha(state),
    resetCaptcha: loginSelectors.getResetCaptcha(state),
    fingerprintSubmiting: loginSelectors.getFingerprintSubmiting(state),
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    rememberedUser: loginSelectors.getRememberedUser(state),
    activeRegion: loginSelectors.getRegion(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(LoginStep1);
