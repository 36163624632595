import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import { compose } from "redux";
import { connect } from "react-redux";

import { actions as fundcorpActions, selectors as fundcorpSelectors } from "reducers/fundcorp";
import Head from "pages/_components/Head";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { maskedAccountNumber } from "util/accounts";
import FormattedAmount from "pages/_components/FormattedAmount";
import Selector from "pages/_components/fields/formik/Selector";
import { CURRENCY_CODE } from "constants.js";

const FORM_ID = "fund.operation.associateShareAccount";

function AssociateAccountModal({
    isVisible,
    isDesktop,
    accounts,
    fetchingBankAccount,
    selectedFundFeature,
    handleResetAssociateModal,
}) {
    const [isYes, setIsYes] = useState(false);

    useEffect(() => {
        setIsYes(false);
    }, [isVisible]);

    const getLabelAccount = (elem) => {
        if (isDesktop) {
            return (
                <>
                    <span className="control-label">
                        {`${elem.productAlias || ""} ${maskedAccountNumber(elem.number)} ${elem.productType} ${i18n.get(
                            `currency.label.${elem.currency}`,
                        )}`}
                    </span>
                    <FormattedAmount quantity={elem.balance} currency={elem.currency} notBold />
                </>
            );
        }
        return (
            <div style={{ display: "grid" }}>
                <div className="control-label transfer-dropdown-account-font">
                    {`${elem.productAlias || ""} ${maskedAccountNumber(elem.number)} ${elem.productType} ${i18n.get(
                        `currency.label.${elem.currency}`,
                    )}`}
                </div>
                <FormattedAmount
                    quantity={elem.balance}
                    currency={elem.currency}
                    className="transfer-dropdown-account-font gray-color"
                    notBold
                />
            </div>
        );
    };

    const getAccountsByCurrency = (fundFeature) => {
        if (!fundFeature?.moneda) {
            return accounts
                ?.filter((account) => account.currency === CURRENCY_CODE.ARS)
                .map((account) => ({
                    value: account.idProduct,
                    label: getLabelAccount(account),
                }));
        }
        return accounts
            ?.filter((account) => account.currency === CURRENCY_CODE[fundFeature.moneda])
            .map((account) => ({
                value: account.idProduct,
                label: getLabelAccount(account),
            }));
    };

    const contentInfo = () => (
        <>
            <div className="w-100 align-items-start flex-column pb-4">
                <Col className="cmf-container-white col-12">
                    <div className="admin-content-center">
                        <Image
                            src="images/info-warning.svg"
                            styles={{
                                width: isDesktop ? "90px" : "65px",
                                height: isDesktop ? "90px" : "65px",
                                transform: "rotate(180deg)",
                            }}
                        />
                    </div>
                </Col>
                <Col className="col-12 px-4">
                    <I18n
                        component="p"
                        id={`${FORM_ID}.message.info`}
                        componentProps={{ className: "text-center px-5" }}
                    />
                </Col>
                <Col
                    className={classNames("text-center", {
                        "f-dir-col-reverse d-flex": !isDesktop,
                    })}
                    md={12}>
                    <Button
                        block={false}
                        type="button"
                        bsStyle="outline"
                        label="global.no"
                        className="px-5 mx-2"
                        onClick={() => handleResetAssociateModal()}
                    />
                    <Button
                        block={false}
                        type="button"
                        bsStyle="primary"
                        label="global.yes"
                        className="px-5 mx-2"
                        onClick={() => setIsYes(true)}
                    />
                </Col>
            </div>
        </>
    );

    const contentAssociate = () => (
        <Form className="h-100">
            <div className="w-100 h-100 align-items-start flex-column space-between py-4">
                <Col md={12} className={classNames("text-center", { "px-md-5": isDesktop })}>
                    {isDesktop ? <I18n id={FORM_ID} component="h3" /> : undefined}
                </Col>
                <Col md={12} className={classNames("align-items-center", { "px-md-5": isDesktop })}>
                    <Field
                        component={Selector}
                        options={getAccountsByCurrency(selectedFundFeature)}
                        idForm={FORM_ID}
                        name="debitAccount"
                    />
                </Col>
                <Col
                    md={12}
                    className={classNames("justify-content-center d-flex my-4", { "align-items-end": !isDesktop })}>
                    <Button
                        type="submit"
                        bsStyle="primary"
                        label="global.confirm"
                        className="px-5"
                        loading={fetchingBankAccount}
                    />
                </Col>
            </div>
        </Form>
    );

    return (
        <Modal
            show={isVisible}
            className={
                isDesktop || !isYes ? "transfer-template-modal risk-label" : "transfer-frequent-destination-modal px-0"
            }
            dialogClassName={isYes && !isDesktop && "h-100"}
            bsClass="no-radius modal"
            onHide={() => handleResetAssociateModal()}>
            {isDesktop || !isYes ? (
                <Modal.Header closeButton className="no-title min-height-3" />
            ) : (
                <Head
                    onClose={() => handleResetAssociateModal()}
                    hideMobileMenu
                    closeImageWhite
                    headerClassName={!isDesktop ? "blue-main-header-mobile pt-2" : ""}
                    title={!isDesktop ? `${FORM_ID}.head.label` : undefined}
                    titleClassName="title-content text-white header-mobile-title-background-blue mb-0"
                    accessibilityTextId={`${FORM_ID}.head.label`}
                    navbarClassName="py-2"
                    centerContentClassName="mx-5"
                />
            )}
            <Modal.Body className="pb-2 modal-md show">{!isYes ? contentInfo() : contentAssociate()}</Modal.Body>
        </Modal>
    );
}

AssociateAccountModal.propTypes = {
    isDesktop: bool.isRequired,
    accounts: arrayOf(shape({})).isRequired,
    selectedFundFeature: shape({
        numero: number,
        rescatePlazo: number,
        suscripcionPlazo: number,
        nombre: string,
        estaAnulado: bool,
        horizonteInversionDescripcion: string,
        sociedadGerenteDescripcion: string,
        moneda: string,
        monedaDescripcion: string,
        regionDescripcion: string,
    }).isRequired,
    isVisible: bool,
    fetchingBankAccount: bool,
    handleResetAssociateModal: func.isRequired,
};

AssociateAccountModal.defaultProps = {
    fetchingBankAccount: false,
    isVisible: false,
};

const mapStateToProps = (state) => ({
    fetchingBankAccount: fundcorpSelectors.getFetchingBankAccount(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            debitAccount: "",
        }),

        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    debitAccount: Yup.string().required(i18n.get(`fund.operation.subscribe.field.error.required`)),
                }),
            ),

        handleSubmit: ({ debitAccount }, formikBag) => {
            const { dispatch, selectedShareAccount } = formikBag.props;
            const shareaccount = selectedShareAccount;
            dispatch(fundcorpActions.sendBankAccountCpt(debitAccount, shareaccount, formikBag));
        },
    }),
)(AssociateAccountModal);
