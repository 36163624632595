/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import { bool, string, shape, arrayOf, func } from "prop-types";
import { Field, Form, withFormik } from "formik";
import classNames from "classnames";
import Yup from "yup";
import moment from "moment";
import queryString from "query-string";
import { isNil } from "lodash";

import { actions as fundcorpActions, selectors as fundcorpSelectors } from "reducers/fundcorp";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";

import I18n from "pages/_components/I18n";
import TabletSelectors from "pages/_components/TabletSelectors";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Col from "react-bootstrap/lib/Col";
import Selector from "pages/_components/fields/formik/Selector";
import { DateField } from "pages/_components/fields/DateField";
import { isEnabledApi5 } from "util/fundcorp";
import FundcorpList from "./_components/FundcorpList";
import OperationRightContent from "./_components/OperationRightContent";

const FORM_ID = "fundcorp.list";
function FundcorpOperationsMain(props) {
    const {
        dispatch,
        fetching,
        isDesktop,
        isSmallDesktop,
        fetchingDownload,
        isFetchingFundcorp,
        optionSelected,
        positions,
        movements,
        requests,
        values,
        setFieldValue,
        funds,
        shareAccounts,
        positionsByShareAccount,
        filtersData,
        location,
        submitForm,
        activeEnvironment,
    } = props;

    const {
        positionsType,
        positionDate,
        movementsDateFrom,
        movementsDateTo,
        movementsShareAccount,
        requestsShareAccount,
        requestsDateFrom,
        requestsDateTo,
    } = values;

    const [showForm, setShowForm] = useState(false);
    const [firstEffect, setFirstEffect] = useState(false);

    useEffect(() => {
        const { query } = queryString.parseUrl(location.search);
        dispatch(fundcorpActions.listFundcorpPre("operations", query?.opt));
        if (query?.opt) {
            const { c, f } = query;

            setFirstEffect(true);
            setFieldValue("movementsFund", parseInt(f, 10));
            setFieldValue("movementsShareAccount", parseInt(c, 10));
        } else {
            setFieldValue("movementsFund", "all");
        }
    }, [dispatch, location, setFieldValue]);

    useEffect(() => {
        if (!requestsShareAccount && shareAccounts && shareAccounts.length > 0) {
            setFieldValue("requestsShareAccount", shareAccounts[0].id);
        }
        if (!movementsShareAccount && shareAccounts && shareAccounts.length > 0) {
            setFieldValue("movementsShareAccount", shareAccounts[0].id);
        }
        if (shareAccounts && shareAccounts.length > 0 && firstEffect) {
            submitForm();
            setFirstEffect(false);
        }
    }, [
        requestsShareAccount,
        movementsShareAccount,
        firstEffect,
        setFirstEffect,
        shareAccounts,
        setFieldValue,
        submitForm,
    ]);

    const resetDefaultValues = () => {
        setFieldValue("positionsType", "byShare");
        setFieldValue("positionDate", moment(new Date()));
        setFieldValue("movementsShareAccount", shareAccounts && shareAccounts.length > 0 ? shareAccounts[0].id : null);
        setFieldValue("requestsShareAccount", shareAccounts && shareAccounts.length > 0 ? shareAccounts[0].id : null);
        setFieldValue("movementsFund", "all");
        setFieldValue("movementsDateFrom", moment().subtract(1, "weeks"));
        setFieldValue("movementsDateTo", moment(new Date()));
        setFieldValue("requestsDateFrom", moment().subtract(1, "weeks"));
        setFieldValue("requestsDateTo", moment(new Date()));
    };

    useEffect(() => {
        resetDefaultValues();
    }, [location]);

    const handleBack = () => {
        dispatch(push("/desktop"));
    };

    const handleExport = (format) => {
        const summary = { positions, movements, requests, optionSelected, filtersData };
        dispatch(fundcorpActions.downloadFundcorpList(summary, format));
    };

    const changeOption = (option) => {
        dispatch(fundcorpActions.saveOptionSelected(option));
        setFieldValue("positionsType", "byShare");
        setFieldValue("positionDate", moment(new Date()));
        const defaultShareAccount = shareAccounts && shareAccounts.length > 0 ? shareAccounts[0].id : null;
        setFieldValue("movementsShareAccount", defaultShareAccount);
        setFieldValue("requestsShareAccount", defaultShareAccount);
        setFieldValue("movementsFund", "all");
        setFieldValue("movementsDateFrom", moment().subtract(1, "weeks"));
        setFieldValue("movementsDateTo", moment(new Date()));
        setFieldValue("requestsDateFrom", moment().subtract(1, "weeks"));
        setFieldValue("requestsDateTo", moment(new Date()));
        resetDefaultValues();
        submitForm();
    };

    const rowButtons = () => {
        const useAPI5 = isEnabledApi5();
        const possibleOptions = useAPI5
            ? ["positions", "movementsAndRequests"]
            : ["positions", "movements", "requests"];
        const options = possibleOptions.map((possibleOption) => ({
            value: possibleOption,
            label: `fundcorp.${possibleOption}.label`,
        }));

        return (
            <TabletSelectors
                isDesktop={isDesktop}
                possibleOptions={options}
                changeOption={changeOption}
                optionSelected={optionSelected}
                imageClassName="ml-3"
                containerClass={classNames(
                    "fundcorp-tablet-selector py-25",
                    { "mt-4 mb-2": isDesktop },
                    { "my-0": !isDesktop },
                )}
            />
        );
    };

    const hasPositionsToday = () => {
        const positionDateFilter = filtersData?.positionDate;
        const today = new Date();

        if (!positionDateFilter || !positionsByShareAccount) {
            return false;
        }
        if (!(positionDateFilter instanceof Date)) {
            return positionsByShareAccount.length > 0 && positionDateFilter.toDate().getDate() === today.getDate();
        }
        return positionsByShareAccount.length > 0 && positionDateFilter.getDate() === today.getDate();
    };

    if (isNil(shareAccounts)) {
        return <></>;
    }

    const positionsTypeOptions = [
        {
            id: "byShare",
            label: i18nUtils.get(`${FORM_ID}.positionsType.byShare`),
        },
        {
            id: "byFund",
            label: i18nUtils.get(`${FORM_ID}.positionsType.byFund`),
        },
    ];

    const shareAccountOptions = [
        ...shareAccounts
            .sort((a, b) => {
                if (a.description > b.description) {
                    return 1;
                }
                if (a.description < b.description) {
                    return -1;
                }
                return 0;
            })
            .map((share) => ({
                value: share.id,
                label: `${share.id} ${share.description}`,
            })),
    ];

    const fundOptions = [
        {
            value: "all",
            label: i18nUtils.get(`${FORM_ID}.fund.options.all`),
        },
        ...funds.map((fund) => ({
            value: fund.id,
            label: fund.description,
        })),
    ];

    const centerElement = () => (
        <div className="d-flex align-items-center">
            <I18n
                id="fundcorp.title.operations.secondPart"
                component="h1"
                componentProps={{ className: "my-0 no-wrap" }}
            />
            <h1 className="p-25 my-0">|</h1>
            <Image src="images/fundcorp-logo.svg" wrapperClassName="fundcorp-logo" />
        </div>
    );

    const centerElementMobile = () => (
        <div className="d-flex flex-column align-items-center">
            <I18n id="fundcorp.title.operations.secondPart" component="h1" componentProps={{ className: "my-0" }} />
        </div>
    );

    if (isNil(shareAccounts)) {
        return <></>;
    }

    return (
        <>
            <Notification scopeToShow={FORM_ID} />
            <div className="admin-detail-head px-0 fundcorp__mobile-main">
                {isDesktop ? <Head onBack={handleBack} /> : undefined}
                <Head
                    centerElement={isDesktop ? centerElement : centerElementMobile}
                    accessibilityTextId="fundcorp.title.operations.secondPart"
                    rightContent={() =>
                        !fetching && (
                            <OperationRightContent
                                activeEnvironment={activeEnvironment}
                                isDesktop={isDesktop}
                                dispatch={dispatch}
                                isSmallDesktop={isSmallDesktop}
                                shareAccounts={shareAccounts}
                                isFetchingFundcorp={isFetchingFundcorp}
                                hasPositionsToday={hasPositionsToday()}
                                handleExport={handleExport}
                                isFetchingDownload={fetchingDownload}
                            />
                        )
                    }
                    imageStyle="mr-2"
                    navbarClassName={!isDesktop ? "fundcorp__navbar-header" : undefined}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : undefined}
                    onBack={!isDesktop && handleBack}
                />
                <PageLoading loading={fetching} className={isDesktop ? "screen-loader min-height-vh-page" : ""}>
                    {rowButtons()}
                    {!isDesktop && (
                        <div className="justify-content-end d-flex">
                            <Button
                                className="btn-link px-2 my-0 mr-2"
                                block={false}
                                label={showForm ? "echeq.button.hideFilter" : "echeq.button.seeFilter"}
                                onClick={() => setShowForm(!showForm)}
                            />
                        </div>
                    )}
                    {(isDesktop || showForm) && (
                        <Form autoComplete="off">
                            <Container
                                className={classNames(
                                    "align-items-left account-header-align-items-left fundcorp-form account-header-detail",
                                )}
                                gridClassName="form-content"
                                rowClassName={classNames("justify-content-left px-2", {
                                    "pb-4": optionSelected !== "positions",
                                    "pb-15": optionSelected === "positions",
                                })}>
                                {optionSelected === "positions" && (
                                    <>
                                        <Col
                                            md={4}
                                            lg={3}
                                            className={`d-flex align-items-center ${
                                                isDesktop ? "p-2 mt-3 ml-2 mr-4" : "mt-3 px-2 space-between"
                                            }`}>
                                            <Field
                                                component={Selector}
                                                formGroupClassName="mb-0 text-uppercase"
                                                options={positionsTypeOptions}
                                                idForm={FORM_ID}
                                                idField="positionsType"
                                                name="positionsType"
                                                inLineControl
                                                isRequired
                                                renderAs="radio"
                                                hideLabel
                                                spaceBetween={!isDesktop}
                                                defaultValue={positionsType}
                                            />
                                        </Col>
                                        <Col
                                            sm={12}
                                            md={4}
                                            lg={2}
                                            className={classNames("p-2", {
                                                "w-100": !isDesktop,
                                                "px-3 mb-35 d-flex align-items-end": isDesktop,
                                            })}>
                                            <Field
                                                idField="positionDate"
                                                component={DateField}
                                                formGroupClassName="w-100 mb-0"
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="positionDate"
                                                minDate={null}
                                                value={positionDate}
                                            />
                                        </Col>
                                    </>
                                )}

                                {(optionSelected === "movements" || optionSelected === "movementsAndRequests") && (
                                    <>
                                        <Col
                                            className={classNames("p-2", {
                                                "w-100": !isDesktop,
                                                "d-flex align-items-end min-width-25 max-width-25": isDesktop,
                                            })}>
                                            <Field
                                                component={Selector}
                                                formGroupClassName="mb-0 w-100"
                                                options={shareAccountOptions}
                                                idForm={FORM_ID}
                                                idField="movementsShareAccount"
                                                name="movementsShareAccount"
                                                isRequired
                                                placeholder={i18nUtils.get(`${FORM_ID}.shareAccount.placeholder`)}
                                                value={movementsShareAccount}
                                            />
                                        </Col>
                                        <Col
                                            className={classNames("p-2", {
                                                "w-100": !isDesktop,
                                                "d-flex align-items-end min-width-25 max-width-25": isDesktop,
                                            })}>
                                            <Field
                                                component={Selector}
                                                formGroupClassName="mb-0 w-100"
                                                options={fundOptions}
                                                idForm={FORM_ID}
                                                idField="movementsFund"
                                                name="movementsFund"
                                                isRequired
                                                searchable={isDesktop}
                                            />
                                        </Col>
                                        <Col
                                            className={classNames("p-2", {
                                                "w-50": !isDesktop,
                                                "d-flex align-items-end": isDesktop,
                                            })}>
                                            <Field
                                                idField="movementsDateFrom"
                                                component={DateField}
                                                formGroupClassName="mb-0"
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="movementsDateFrom"
                                                minDate={null}
                                                maxDate={movementsDateTo}
                                                value={movementsDateFrom}
                                            />
                                        </Col>
                                        <Col
                                            className={classNames("p-2", {
                                                "w-50": !isDesktop,
                                                "d-flex align-items-end": isDesktop,
                                            })}>
                                            <Field
                                                idField="movementsDateTo"
                                                component={DateField}
                                                formGroupClassName="mb-0"
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="movementsDateTo"
                                                minDate={movementsDateFrom}
                                                maxDate={moment()}
                                                style={{ alignSelf: "flex-end" }}
                                                value={movementsDateTo}
                                            />
                                        </Col>
                                    </>
                                )}

                                {optionSelected === "requests" && (
                                    <>
                                        <Col
                                            className={classNames("p-2", {
                                                "w-100": !isDesktop,
                                                "d-flex align-items-end": isDesktop,
                                            })}>
                                            <Field
                                                component={Selector}
                                                formGroupClassName="mb-0"
                                                options={shareAccountOptions}
                                                idForm={FORM_ID}
                                                idField="requestsShareAccount"
                                                name="requestsShareAccount"
                                                isRequired
                                                placeholder={i18nUtils.get(`${FORM_ID}.shareAccount.placeholder`)}
                                                value={requestsShareAccount}
                                            />
                                        </Col>
                                        <Col
                                            className={classNames("p-2", {
                                                "w-50": !isDesktop,
                                                "d-flex align-items-end": isDesktop,
                                            })}>
                                            <Field
                                                idField="requestsDateFrom"
                                                component={DateField}
                                                formGroupClassName="mb-0"
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="requestsDateFrom"
                                                minDate={null}
                                                maxDate={requestsDateTo}
                                                value={requestsDateFrom}
                                            />
                                        </Col>
                                        <Col
                                            className={classNames("p-2", {
                                                "w-50": !isDesktop,
                                                "d-flex align-items-end": isDesktop,
                                            })}>
                                            <Field
                                                idField="requestsDateTo"
                                                component={DateField}
                                                formGroupClassName="mb-0"
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="requestsDateTo"
                                                minDate={requestsDateFrom}
                                                style={{ alignSelf: "flex-end" }}
                                                value={requestsDateTo}
                                            />
                                        </Col>
                                    </>
                                )}
                                <Col
                                    md={2}
                                    lg={2}
                                    className={classNames("d-flex align-items-end p-2 px-0", {
                                        "mb-35": optionSelected === "positions",
                                    })}>
                                    <Button
                                        bsStyle="primary"
                                        label={`${FORM_ID}.btn.filter.label`}
                                        loading={isFetchingFundcorp}
                                        type="submit"
                                        className={classNames({
                                            "filter-button w-80 mb-0": isDesktop,
                                            "mt-4": !isDesktop,
                                        })}
                                    />
                                </Col>
                            </Container>
                        </Form>
                    )}
                    <FundcorpList {...props} hasPositionsToday={hasPositionsToday} />
                </PageLoading>
            </div>
        </>
    );
}

FundcorpOperationsMain.propTypes = {
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    isDesktop: bool.isRequired,
    isSmallDesktop: bool.isRequired,
    fetchingDownload: bool.isRequired,
    isFetchingFundcorp: bool.isRequired,
    optionSelected: string.isRequired,
    positions: arrayOf(shape({})).isRequired,
    movements: arrayOf(shape({})).isRequired,
    requests: arrayOf(shape({})).isRequired,
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
    funds: arrayOf(shape({})).isRequired,
    shareAccounts: arrayOf(shape({})).isRequired,
    positionsByShareAccount: arrayOf(shape({})).isRequired,
    filtersData: shape({}).isRequired,
    setValues: func.isRequired,
    location: shape({ search: string }).isRequired,
    submitForm: func.isRequired,
    activeEnvironment: shape({
        permissions: shape({
            fundRescue: bool,
            fundSubscribe: bool,
        }),
    }).isRequired,
};

const mapStateToProps = (state) => ({
    fetching: fundcorpSelectors.getFetching(state),
    fetchingDownload: fundcorpSelectors.getFetchingDownload(state),
    optionSelected: fundcorpSelectors.getOptionSelected(state),
    isFetchingFundcorp: fundcorpSelectors.isFetchingFundcorp(state),
    funds: fundcorpSelectors.getFunds(state),
    shareAccounts: fundcorpSelectors.getShareAccounts(state),
    positions: fundcorpSelectors.getPositions(state),
    positionsByShareAccount: fundcorpSelectors.getPositionsByShareAccount(state),
    movements: fundcorpSelectors.getMovements(state),
    requests: fundcorpSelectors.getRequests(state),
    filtersData: fundcorpSelectors.getFiltersData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    fundFeatures: fundcorpSelectors.getFundFeatures(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { filtersData, shareAccounts } = props;
            const defaultShareAccount = shareAccounts && shareAccounts.length > 0 ? shareAccounts[0].id : null;
            const movementsShareAccount =
                filtersData && filtersData.movementsShareAccount
                    ? filtersData.movementsShareAccount
                    : defaultShareAccount;
            const requestsShareAccount =
                filtersData && filtersData.requestsShareAccount
                    ? filtersData.requestsShareAccount
                    : defaultShareAccount;
            return {
                positionsType: filtersData && filtersData.positionsType ? filtersData.positionsType : "byShare",
                positionDate: filtersData && filtersData.positionDate ? filtersData.positionDate : moment(new Date()),

                movementsShareAccount,
                movementsFund: filtersData && filtersData.movementsFund ? filtersData.movementsFund : "all",
                movementsDateFrom:
                    filtersData && filtersData.movementsDateFrom
                        ? filtersData.movementsDateFrom
                        : moment().subtract(1, "weeks"),
                movementsDateTo:
                    filtersData && filtersData.movementsDateTo ? filtersData.movementsDateTo : moment(new Date()),

                requestsShareAccount,
                requestsDateFrom:
                    filtersData && filtersData.requestsDateFrom
                        ? filtersData.requestsDateFrom
                        : moment().subtract(1, "weeks"),
                requestsDateTo:
                    filtersData && filtersData.requestsDateTo ? filtersData.requestsDateTo : moment(new Date()),
            };
        },
        validationSchema: (props) =>
            Yup.lazy(() => {
                const { optionSelected } = props;
                let validationObject = {};

                if (optionSelected === "positions") {
                    validationObject = {
                        ...validationObject,
                        positionDate: Yup.string()
                            .nullable()
                            .required(i18nUtils.get(`${FORM_ID}.field.error.required`)),
                    };
                }
                if (optionSelected === "movements") {
                    validationObject = {
                        ...validationObject,
                        movementsDateFrom: Yup.string()
                            .nullable()
                            .required(i18nUtils.get(`${FORM_ID}.field.error.required`)),
                        movementsDateTo: Yup.string()
                            .nullable()
                            .required(i18nUtils.get(`${FORM_ID}.field.error.required`)),
                    };
                }
                if (optionSelected === "requests") {
                    validationObject = {
                        ...validationObject,
                        requestsDateFrom: Yup.string()
                            .nullable()
                            .required(i18nUtils.get(`${FORM_ID}.field.error.required`)),
                        requestsDateTo: Yup.string()
                            .nullable()
                            .required(i18nUtils.get(`${FORM_ID}.field.error.required`)),
                    };
                }
                return Yup.object().shape(validationObject);
            }),
        handleSubmit: (
            {
                positionsType,
                positionDate,
                movementsShareAccount,
                movementsFund,
                movementsDateFrom,
                movementsDateTo,
                requestsShareAccount,
                requestsDateFrom,
                requestsDateTo,
            },
            formikBag,
        ) => {
            const { dispatch, optionSelected, funds, shareAccounts } = formikBag.props;

            const fundOptions = [
                {
                    id: "all",
                    description: i18nUtils.get(`${FORM_ID}.fund.options.all`),
                },
                ...funds,
            ];
            const movementsShareAccountDescription = movementsShareAccount
                ? shareAccounts.find(({ id }) => id === movementsShareAccount).description
                : null;
            const movementsFundDescription = movementsFund
                ? fundOptions.find(({ id }) => id === movementsFund).description
                : null;
            const requestsShareAccountDescription = requestsShareAccount
                ? shareAccounts.find(({ id }) => id === requestsShareAccount).description
                : null;
            const verifyCode =
                movementsShareAccount || requestsShareAccount
                    ? shareAccounts.find(
                          ({ id }) =>
                              id ===
                              (optionSelected === "movements" || optionSelected === "movementsAndRequests"
                                  ? movementsShareAccount
                                  : requestsShareAccount),
                      ).verifyCode
                    : null;

            dispatch(
                fundcorpActions.listFundcorp(
                    optionSelected,
                    positionsType,
                    positionDate,
                    movementsShareAccount,
                    movementsShareAccountDescription,
                    movementsFund,
                    movementsFundDescription,
                    movementsDateFrom,
                    movementsDateTo,
                    requestsShareAccount,
                    requestsShareAccountDescription,
                    requestsDateFrom,
                    requestsDateTo,
                    verifyCode,
                ),
            );
        },
    }),
)(FundcorpOperationsMain);
