import React, { Component } from "react";
import { Modal, Col } from "react-bootstrap";
import { bool, func } from "prop-types";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Row from "react-bootstrap/lib/Row";
import Container from "pages/_components/Container";
import classNames from "classnames";

class TokenModal extends Component {
    static propTypes = {
        isDisplayed: bool.isRequired,
        handleDismiss: func.isRequired,
        buttonNewToken: func.isRequired,
        buttonSyncToken: func.isRequired,
        isOtherDevice: bool,
        setStateOnClick: func,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        isOtherDevice: false,
        setStateOnClick: null,
    };

    contentBlocked = () => {
        const { handleDismiss, isDesktop } = this.props;
        return (
            <div className="p-1">
                <Modal.Title className="full-width mt-1 ml-1 mr-0 content-blocked-title">
                    <div className="modal-title-left" style={isDesktop ? { "margin-left": "3rem" } : {}}>
                        <I18n id="settings.token.modal.tittle" />
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <Container
                        className="container--layout media-object media-object--state-warning content-blocked-container"
                        gridClassName="container-fluid">
                        <Col>
                            <div className="media-object--text">
                                <p className="text-lead">
                                    <I18n component="span" id="settings.token.modal.content" />
                                </p>
                            </div>
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="content-blocked-footer">
                    <Row>
                        <Button
                            onClick={handleDismiss}
                            bsStyle="link"
                            label="settings.token.modal.button"
                            className="cmf-button-width px-0"
                        />
                    </Row>
                </Modal.Footer>
            </div>
        );
    };

    contentSyncToken = () => {
        const { buttonNewToken, buttonSyncToken, setStateOnClick } = this.props;
        return (
            <div className="p-1">
                <Modal.Title className="full-width ml-1 mr-0">
                    <div className="space-between w-100 align-items-center">
                        <I18n
                            component="h2"
                            id="settings.token.sync.modal.tittle"
                            componentProps={{
                                className: "text-nowrap ml-2 d-flex mt-0 mb-0",
                            }}
                        />
                        <Button
                            bsStyle="link"
                            image="images/cancel_black.svg"
                            className="toolbar-btn m-0 p-0 d-contents"
                            onClick={setStateOnClick}
                        />
                    </div>
                </Modal.Title>
                <Modal.Body>
                    <Container
                        className="container--layout media-object media-object--state-warning m-0 p-0"
                        gridClassName="container-fluid">
                        <Col className="px-1 token-sync-text">
                            <div>
                                <p style={{ display: "flex", textAlign: "left" }}>
                                    <I18n component="p" id="settings.token.sync.modal.content" />
                                </p>
                            </div>
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="mb-0">
                    <Col xs={12} className="px-0">
                        <Col xs={6} className="px-0">
                            <Button
                                onClick={buttonSyncToken}
                                bsStyle="link"
                                label="settings.token.sync.modal.button.syncToken"
                                className="p-0"
                            />
                        </Col>
                        <Col xs={6} className="px-0">
                            <Button
                                onClick={buttonNewToken}
                                bsStyle="link"
                                label="settings.token.sync.modal.button.newToken"
                                className="p-0"
                            />
                        </Col>
                    </Col>
                </Modal.Footer>
            </div>
        );
    };

    renderContent = () => {
        const { isOtherDevice } = this.props;
        return isOtherDevice ? this.contentSyncToken() : this.contentBlocked();
    };

    render() {
        const { isDisplayed, handleDismiss, isOtherDevice } = this.props;
        return (
            <Modal
                onHide={handleDismiss}
                show={isDisplayed}
                dialogClassName={classNames("token-modal-backdrop", {
                    "modal-sync-token": isOtherDevice,
                })}>
                <div className="modal-container token h-100 justify-content-center">
                    <div className="modal-box p-2 radius">{this.renderContent()}</div>
                </div>
            </Modal>
        );
    }
}

export default TokenModal;
