import React, { Component } from "react";
import { string, shape, func, bool } from "prop-types";
import { push } from "react-router-redux";

import * as accountUtils from "util/accounts";

import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

class NewPaymentTransactionData extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        dispatch: func.isRequired,
        FORM_ID: string.isRequired,
        isDesktop: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push(`/pendingTransaction/list`));
    };

    render() {
        const { summary, FORM_ID, isDesktop } = this.props;
        const { summaryInfo } = summary;

        return (
            summary && (
                <div className={`${!isDesktop && "m-3 new-payment-transaction-data-mobile"}`}>
                    {summary.idTransactionStatus === "FINISHED" && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="negotiate.transaction.date.label"
                            data={
                                <p className="data-desc m-0">
                                    <FormattedDate date={summary.creationDate} dateFormat="dd/MM/yyyy" showTime />
                                </p>
                            }
                        />
                    )}
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="echeq.transaction.voucher"
                        data={<p className="data-desc m-0">{summary.idTransaction?.substring(0, 8).toUpperCase()}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.rubros.label`}
                        data={<p className="data-desc m-0">{summaryInfo?.selectedRubro?.description}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.entities.label`}
                        data={<p className="data-desc m-0">{summaryInfo?.selectedEntity?.description}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.cpe.label`}
                        data={<p className="data-desc m-0">{summaryInfo?.selectedCPE}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.amountToPay`}
                        data={
                            <FormattedAmount
                                currency="ARS"
                                quantity={summaryInfo?.selectedAmountToPay}
                                small={!isDesktop}
                                notBold
                            />
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.expiration`}
                        data={
                            <p className="data-desc m-0">
                                {summaryInfo.selectedEntity?.handleBaseDebts ? (
                                    <FormattedDate
                                        date={summaryInfo?.selectedConceptExpirationDate}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                ) : (
                                    summaryInfo?.selectedConceptExpirationDate
                                )}
                            </p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.concept`}
                        data={<p className="data-desc m-0">{summaryInfo?.selectedConcept?.description}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.account.label`}
                        data={
                            <p className="data-desc m-0">
                                {summaryInfo?.selectedDebitAccount &&
                                    accountUtils.getIncomingDebinAccountName(summaryInfo.selectedDebitAccount)}
                            </p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.paymentReference`}
                        data={<p className="data-desc m-0">{summaryInfo?.paymentReference}</p>}
                    />
                </div>
            )
        );
    }
}

export default NewPaymentTransactionData;
