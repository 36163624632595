/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { string, func, shape, arrayOf, bool, number } from "prop-types";
import { Field, Form, withFormik } from "formik";
import { push } from "react-router-redux/actions";
import moment from "moment";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";

import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Col from "react-bootstrap/lib/Col";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Yup from "yup";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import CreateFrequentDesitinationModal from "pages/forms/_components/CreateFrequentDesetinationModal";
import GeneralMsg from "pages/_components/GeneralMsg";
import Selector from "pages/_components/fields/formik/Selector";
import DateComponent from "pages/_components/fields/DateField";
import TextField from "pages/_components/fields/TextField";
import SelectedReceivers from "pages/charges/_components/SelectedReceivers";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import NewReceiver from "pages/charges/_components/NewReceiver";
import Image from "pages/_components/Image";
import FieldError from "pages/_components/fields/FieldError";
import Notification from "pages/_components/Notification";
import { actions as statusActions } from "reducers/status";

import * as i18n from "util/i18n";
import classNames from "classnames";
import PageLoading from "pages/_components/PageLoading";

const FORM_ID = "charges.debin.requestDebin";

const getHours = (hour) => hour.substring(0, 2);
const getMinutes = (hour) => hour.substring(3, 5);

const getInitialDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    return date;
};

const getInitialTime = () => {
    const initialDate = getInitialDate();
    const hours = initialDate.getHours();
    const fixedHours = hours >= 10 ? hours : `0${hours}`;
    const minutes = initialDate.getMinutes() - 1;
    const fixedMinutes = minutes >= 10 ? minutes : `0${minutes}`;
    return `${fixedHours}:${fixedMinutes}`;
};

class RequestDebin extends Component {
    static propTypes = {
        setValues: func.isRequired,
        values: shape({ account: string.isRequired, date: string.isRequired, hour: string.isRequired }).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        errors: shape({}).isRequired,
        cbuList: arrayOf(
            shape({
                email: string.isRequired,
                cbu: string.isRequired,
                document: string.isRequired,
                headline: string.isRequired,
                reference: string.isRequired,
                idFrequentDestination: bool.isRequired,
                key: number.isRequired,
                isOwn: string.isRequired,
                comprobante: number.isRequired,
                schedule: bool.isRequired,
            }),
        ).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        concepts: arrayOf(shape({})).isRequired,
        location: shape({
            state: shape({}).isRequired,
        }).isRequired,
        seller: shape({
            cbuList: shape({}).isRequired,
        }).isRequired,
        isBackFromSummary: bool.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                frequentDestination: bool,
                addRemoveDebinAccount: bool,
            }),
        }).isRequired,
    };

    state = {
        frequentVisible: false,
    };

    componentDidMount() {
        const { dispatch, isBackFromSummary, location } = this.props;

        if (!isBackFromSummary) {
            dispatch(debinActions.setCbuSelected([]));
        }

        dispatch(debinActions.debinPreActivity());
        dispatch(statusActions.saveLastHref(location));
    }

    closeModal = () => {
        this.setState({ frequentVisible: false });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/charges/generateDebin"));
    };

    emptyReceivers = (errors) => {
        const { isDesktop } = this.props;

        return (
            <>
                <div>
                    <GeneralMsg
                        idEnviroment
                        imagePath="images/loupeInFile.svg"
                        imageStyle={{ width: "96px", height: "96px" }}
                        description={i18n.get("charges.debin.requestDebin.noRecords")}
                        isDesktop={isDesktop}
                    />
                </div>
                {errors.emptyReceivers && (
                    <div className="form-group has-error">
                        <FieldError error={i18n.get("charges.debin.requestDebin.emptyReceivers.label")} />
                    </div>
                )}
            </>
        );
    };

    getMaxDate = () => {
        const date = moment(new Date()); // the day before DST in the US
        date.add(3, "days"); // 5
        return date;
    };

    existCbu = (account) => {
        const { cbuList } = this.props;
        const recipient = cbuList?.find((e) => e.account === account);

        if (recipient) {
            this.closeModal();
            return true;
        }
        return false;
    };

    selectCBU = (selected) => {
        const { dispatch, cbuList } = this.props;
        const { account, reference, recipientDocumentType, recipientDocumentNumber, name } = selected;

        if (this.existCbu(account)) {
            return;
        }

        const mapInfo = recipientDocumentType
            ? { document: `${recipientDocumentType} ${recipientDocumentNumber}`, reference: name }
            : {};
        const newRecipient = {
            ...selected,
            ...mapInfo,
            headline: reference,
            cbu: account,
        };

        let list = [{ ...newRecipient, schedule: false }];
        if (cbuList !== null) {
            list = [...cbuList, ...list];
        }
        this.closeModal();
        dispatch(debinActions.setCbuSelected(list));
    };

    formatTime = () => {
        setTimeout(() => {
            const { setValues, values } = this.props;
            let hour = values.hour.replace(":", "");

            if (hour.length === 2) {
                hour = hour.substring(0, 2).concat(":");

                setValues({ ...values, hour });
            } else if (hour.length > 2) {
                hour = hour.substring(0, 2).concat(":", hour.substring(2, 4));
                setValues({ ...values, hour });
            }
        }, 1000);
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("charges.debin.requestDebin.title")}</h1>
        </div>
    );

    render() {
        const {
            accounts,
            isDesktop,
            fetching,
            cbuList,
            errors,
            dispatch,
            concepts = [],
            values,
            activeEnvironment,
        } = this.props;
        const { frequentVisible } = this.state;
        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="debin" />
                <CreateFrequentDesitinationModal
                    isVisible={frequentVisible}
                    onClick={this.selectCBU}
                    closeModal={this.closeModal}
                    isDesktop={isDesktop}
                />

                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && <Head title="charges.debin.requestDebin.title" />}
                </div>
                <MainContainer className="overflow-hidden" viewPageClassName="has-blackout-selector">
                    <Form autoComplete="off">
                        <div className="above-the-fold debin-inputs">
                            <Container
                                className="flex-grow align-items-center container-white my-2 py-2"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} xl={7} className="col col-12 align-items-center">
                                    <Col className="col col-12 col-no-pad-mobile py-2">
                                        <Field
                                            name="account"
                                            component={Productselector}
                                            idField="account"
                                            data={{
                                                emptyOptionLabel: "",
                                                options: accounts,
                                            }}
                                            disabled={accounts.length === 0}
                                            isRequired
                                            mode="edit"
                                            labelText="transfers.debitaccount.label"
                                            idForm={FORM_ID}
                                            isDesktop={isDesktop}
                                            customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                        />
                                    </Col>
                                    {activeEnvironment.permissions.addRemoveDebinAccount ? (
                                        <Button
                                            bsStyle="link"
                                            label="charges.debin.addAccounts.button"
                                            onClick={() => dispatch(push("debin/addAccounts"))}
                                            type="button"
                                            className="debin__addaccount"
                                        />
                                    ) : (
                                        undefined
                                    )}
                                </Col>
                            </Container>
                            <Container
                                className="flex-grow align-items-center container-white my-2 with-debin-toolbar-margin py-2"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} xl={7}>
                                    <Col sm={12} className="align-items-center d-flex space-between w-100 px-0 px-md-3">
                                        <div className="d-flex align-items-center">
                                            <I18n
                                                id="charges.debin.requestDebin.receiver"
                                                component="p"
                                                componentProps={{ className: "f-size-55 text-uppercase" }}
                                            />
                                        </div>

                                        <div className="d-flex">
                                            <ButtonDrawer
                                                backButton={!isDesktop}
                                                content={<NewReceiver date={new Date()} isDesktop={isDesktop} />}
                                                width={isDesktop ? "31.25rem" : "100%"}
                                                buttonElement={
                                                    <Button
                                                        disabled={values.account === ""}
                                                        className={classNames("btn-outline plus-button", {
                                                            "radius mr-4 small-svg bold-svg": !isDesktop,
                                                        })}>
                                                        <Image src="images/plus.svg" />
                                                        {isDesktop && (
                                                            <I18n
                                                                id="charges.debin.requestDebin.new.receiver"
                                                                componentProps={{ style: { lineHeight: 1 } }}
                                                            />
                                                        )}
                                                    </Button>
                                                }
                                                headerContent={
                                                    <>
                                                        {!isDesktop && (
                                                            <div className="justify-content-center text-white">
                                                                <h1 className="mt-1">
                                                                    {i18n.get(
                                                                        "charges.debin.requestDebin.add.receiver",
                                                                    )}
                                                                </h1>
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            />
                                            {activeEnvironment?.permissions.frequentDestination ? (
                                                <Button
                                                    bsStyle="link"
                                                    disabled={values.account === ""}
                                                    image="images/frequent-destination.svg"
                                                    className={classNames("no-shadow d-flex w-auto", {
                                                        " m-0 p-0 justify-content-end svg25": !isDesktop,
                                                        "svg30 mr-3": isDesktop,
                                                    })}
                                                    onClick={() => {
                                                        this.setState({ frequentVisible: true });
                                                    }}
                                                />
                                            ) : (
                                                undefined
                                            )}
                                        </div>
                                    </Col>
                                </Col>
                            </Container>
                            <Container
                                className="flex-grow align-items-center container-white py-2 py-2 relative"
                                gridClassName="form-content cbu-list debin">
                                {isDesktop && (
                                    <>
                                        {cbuList?.length !== 0 && <hr />}

                                        <Col sm={12} md={9} lg={6} xl={7} className="col col-12 align-items-center">
                                            <Col className="col-12 col-no-pad-mobile py-2 pr-2">
                                                {cbuList?.length === 0 && this.emptyReceivers(errors)}
                                                {cbuList?.length !== 0 && (
                                                    <SelectedReceivers
                                                        accountSelected={values.account}
                                                        {...this.props}
                                                    />
                                                )}
                                            </Col>
                                        </Col>
                                    </>
                                )}
                                {cbuList?.length === 0 && !isDesktop && (
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12 align-items-center">
                                        {this.emptyReceivers(errors)}
                                    </Col>
                                )}
                                {cbuList?.length !== 0 && !isDesktop && (
                                    <SelectedReceivers accountSelected={values.account} {...this.props} />
                                )}
                            </Container>
                            <Container
                                className="flex-grow align-items-center container-white my-2 py-2"
                                gridClassName="form-content ">
                                <div className="row col-12 col-md-9 col-lg-6 px-0 px-md-3 ">
                                    <div className=" col-12 col-lg-6">
                                        <Field
                                            component={Selector}
                                            searchable
                                            options={concepts.map(({ id, name }) => ({
                                                value: id,
                                                label: `${id} - ${name}`,
                                            }))}
                                            idForm={FORM_ID}
                                            name="concept"
                                            isRequired
                                        />
                                    </div>
                                    <div className=" col-8 col-lg-4">
                                        <Field
                                            idField="date"
                                            component={DateComponent}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="date"
                                            startDate={moment()}
                                            minDate={moment()}
                                            maxDate={this.getMaxDate()}
                                        />
                                    </div>
                                    <div className=" col-4 col-lg-2">
                                        <Field
                                            component={TextField}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            maxLength="5"
                                            name="hour"
                                            type="text"
                                            onCustomChange={() => this.formatTime()}
                                        />
                                    </div>
                                    <Col xs={12}>
                                        <Field
                                            component={TextField}
                                            renderAs="input"
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            maxLength="100"
                                            name="description"
                                            type="text"
                                            optional={i18n.get("transfers.reference.optional.label")}
                                        />
                                    </Col>
                                </div>
                            </Container>
                            <Container className="flex-grow align-items-center" gridClassName="form-content">
                                <Col sm={12} md={4} lg={4} xl={4} className="col col-12 align-items-center">
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={fetching}
                                        className={classNames("mt-4", {
                                            "mb-4": !isDesktop,
                                            "mb-7": isDesktop,
                                        })}
                                    />
                                </Col>
                            </Container>
                        </div>
                    </Form>
                </MainContainer>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: debinSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    cbuList: debinSelectors.getListCbu(state),
    fetching: debinSelectors.getFetching(state),
    concepts: debinSelectors.getConcepts(state),
    recipients: debinSelectors.getRecipients(state),
    seller: debinSelectors.getDebinSeller(state),
    isBackFromSummary: debinSelectors.getIsBackFromSummary(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

const validateTime = (hour, setErrors) => {
    let error = false;
    const reg = new RegExp("^\\d+$");
    const parsedHour = hour.replace(":", "");
    const hours = hour.substring(0, 2);
    const minutes = hour.substring(2, 4);

    if (parsedHour.length < 4) {
        error = true;
    } else if (!reg.test(parsedHour)) {
        error = true;
    } else if (parseInt(hours, 10) > 23 || parseInt(hours, 10) < 0) {
        error = true;
    } else if (parseInt(minutes, 10) > 60 || parseInt(minutes, 10) < 0) {
        error = true;
    }

    if (error) {
        setErrors({ hour: i18n.get("login.step1.wrong.format") });
    }

    return !error;
};

function addId(cbuList, item, index, recipients) {
    const recipient = recipients?.find((e) => e.cbu === item.cbu);

    if (recipient) {
        cbuList.splice(index, 1, { ...item, id: recipient.id });
    }
}

function emptyFieldReceiver(cbuList, dispatch, recipients) {
    let empty = false;
    let messageI18n = "";

    cbuList.forEach((item, index) => {
        if (!item.amount || !item.amount.amount) {
            empty = true;
            messageI18n = "amount";
        } else if (!item.comprobante || !item.comprobante) {
            empty = true;
            messageI18n = "comprobante";
        }

        addId(cbuList, item, index, recipients);
    });

    if (empty) {
        dispatch(
            notificationActions.showNotification(i18n.get(`credin.empty.${messageI18n}.receivers`), "error", ["debin"]),
        );
    }

    return empty;
}

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            account: props.isBackFromSummary && props.seller ? props.seller.account : "",
            date: props.isBackFromSummary && props.seller ? props.seller.date : getInitialDate(),
            hour: props.isBackFromSummary && props.seller ? props.seller.hour : getInitialTime(),
            concept: props.isBackFromSummary && props.seller ? props.seller.concept : "VAR",
            description: props.isBackFromSummary && props.seller ? props.seller.description : "",
            cbuList: props.isBackFromSummary && props.cbuList ? props.cbuList : [],
        }),
        validationSchema: () =>
            Yup.object().shape({
                account: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                concept: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                date: Yup.date()
                    .nullable()
                    .required(i18n.get(`${FORM_ID}.error.required`)),
            }),
        handleSubmit: ({ hour, date, ...fields }, formikBag) => {
            const { setErrors, setSubmitting, props } = formikBag;
            const { dispatch, cbuList, accounts, recipients } = props;
            date.setHours(getHours(hour), getMinutes(hour));
            const newDate = new Date();
            const differenceInMinutes = (date.getTime() - newDate.getTime()) / 1000 / 60;

            if (cbuList.length === 0) {
                setErrors({ emptyReceivers: i18n.get(`${FORM_ID}.emptyReceivers.label`) });
                return;
            }

            if (differenceInMinutes < 0) {
                setErrors({ hour: i18n.get(`${FORM_ID}.date.error.beforeToday`) });
                return;
            }
            if (differenceInMinutes < 10) {
                setErrors({ hour: i18n.get(`${FORM_ID}.date.error.min`) });
                return;
            }
            if (differenceInMinutes > 4319) {
                setErrors({ hour: i18n.get(`${FORM_ID}.date.error.max`) });
                return;
            }

            if (!validateTime(hour, setErrors)) {
                setSubmitting(false);
                return;
            }

            const account = accounts.find((a) => a.idProduct === fields.account);
            if (!emptyFieldReceiver(cbuList, dispatch, recipients)) {
                dispatch(
                    debinActions.sendDebinPreRequest(
                        {
                            ...fields,
                            hour,
                            date,
                            cbu: account?.cbu,
                            idAccount: account?.idProduct,
                            cbuList,
                        },
                        cbuList,
                    ),
                );
            }
        },
    }),
)(RequestDebin);
