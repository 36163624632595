import React, { Component } from "react";
import { shape, bool, func, string, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Container from "pages/_components/Container";
import { goBack } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import moment from "moment";
import { Modal } from "react-bootstrap";
import classNames from "classnames";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as thirdPaymentSelector, actions as thirdPaymentActions } from "reducers/thirdPayment";
import { actions as fileActions } from "reducers/formFields/multilineFile";
import { selectors as selectorsTransactionLines } from "reducers/form/transactionLines";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as holidaysActions, selectors as holidaysSelector } from "reducers/holidays";
import { downloadFilelink } from "middleware/file";

import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import Hint from "pages/_components/hints/Hint";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Multiline from "pages/forms/_components/_fields/Multilinefile";
import Notification from "pages/_components/Notification";
import Row from "react-bootstrap/lib/Row";
import TransactionLinesList from "pages/forms/_components/_fields/_transactionlines/List";
import ContextMenu from "pages/_components/ContextMenu";
import DateComponent from "pages/_components/fields/DateField";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import Dropdown from "pages/_components/Dropdown";
import Image from "pages/_components/Image";
import Head from "pages/_components/Head";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { downloadTxt } from "util/download";
import { filterForAvailableDays, DAY_MONTH_YEAR } from "util/date";
import PageLoading from "pages/_components/PageLoading";

const FORM_ID = "payments.payThirdParties.newPayment";

class NewPayment extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        isFetchingDownload: bool,
        itemsDetail: arrayOf(shape({})).isRequired,
        holidays: arrayOf(string).isRequired,
        fetching: bool.isRequired,
        summary: shape({}).isRequired,
        setFieldValue: func.isRequired,
        values: shape({}).isRequired,
    };

    static defaultProps = {
        isFetchingDownload: false,
    };

    state = {
        showMessage: false,
        showDateProcessHintInfoMb: false,
        showModal: false,
        fileUploaded: false,
        maxProcessHour: "16:00:00",
    };

    componentDidMount() {
        const { dispatch, accounts, loggedUser, setFieldValue, summary, rememberFormData } = this.props;

        this.setState((prev) => ({
            ...prev,
            maxProcessHour: configUtils.get("payment.process.hour", prev.maxProcessHour),
        }));

        if (!rememberFormData) {
            dispatch(thirdPaymentActions.clearSummary());
            dispatch(fileActions.onFileRemoved());
            setFieldValue("typePayment", '');
            setFieldValue("account", '');
            setFieldValue("description", '');
            setFieldValue("dateProcess", null);
        } else if (summary.file != null) {
            setFieldValue("typePayment", summary.typePayment);
            setFieldValue("account", summary.account ? summary.account.idProduct : summary.debitAccount);
            setFieldValue("description", summary.description ? summary.description : summary.reference);
            setFieldValue("dateProcess", summary.dateProcess);
        } else {
            this.setState({ fileUploaded: true });
        }
        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
        dispatch(holidaysActions.listHolidays());
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    setHour = (date) => {
        const { maxProcessHour } = this.state;
        const hours = maxProcessHour.split(":");
        return date && hours.length === 3
            ? moment(date).set({ hour: hours[0], minute: hours[1], second: hours[2] })
            : moment(date);
    };

    getMinDate = () => {
        const { holidays } = this.props;
        const date = moment(new Date());
        const today = new Date(moment());
        const maxTime = new Date(this.setHour(today));
        if (today > maxTime) {
            holidays.push(date.format(DAY_MONTH_YEAR));
        }

        while (!filterForAvailableDays(holidays, date)) {
            date.add(+1, "days");
        }

        return date;
    };

    getProcessDate = () => {
        const { isDesktop, holidays } = this.props;
        const { showDateProcessHintInfoMb, maxProcessHour } = this.state;
        const hour = maxProcessHour.split(":")[0];
        const onClick = () => {
            this.setState((prev) => ({
                ...prev,
                showDateProcessHintInfoMb: !prev.showDateProcessHintInfoMb,
            }));
        };

        const dateProcessField = () => (
            <Field
                idField="date"
                formikValueFirst
                component={DateComponent}
                hidePlaceholder
                idForm={FORM_ID}
                name="dateProcess"
                isRequired
                startDate={this.getMinDate()}
                minDate={this.getMinDate()}
                formGroupClassName={`${isDesktop ? "mb-3" : "mb-0"}`}
                errorClassName={classNames({
                    "p-absolute": isDesktop,
                })}
                filterDate={(date) => filterForAvailableDays(holidays, date)}
            />
        );
        if (isDesktop) {
            return (
                <>
                    <Col sm={12} md={4} className="col col-12 align-items-center pr-0">
                        {dateProcessField()}
                    </Col>
                    <Col sm={12} md={1} className="col col-12 align-items-center">
                        <div className="form-group-text py-15" />
                        <Hint
                            idMessage="payments.payThirdParties.newPayment.date.help"
                            classNameGroup="mb-0"
                            classNameImage="justify-content-center d-flex py-0"
                            classNameMessage="new-payment-date-div pt-0"
                            classNameHintP="new-payment-date container-white"
                            HORA_PROCESO={hour}
                        />
                    </Col>
                </>
            );
        }

        return (
            <Col sm={12} md={12} lg={12} xl={12} className="col col-12 col-no-pad-mobile py-2">
                <Row className="pl-0 pr-0 ml-0 mr-0">
                    <Col sx={12} className="col col-11 pl-0 pr-0 mb-0">
                        {dateProcessField()}
                    </Col>
                    <Col sx={12} className="col col-1 justify-content-center pt-45 pl-1 pr-0 ml-0 mr-0">
                        {this.hintMobile(
                            onClick,
                            showDateProcessHintInfoMb,
                            "images/infoActiveUserInvite.svg",
                            "images/infoUserInvite.svg",
                        )}
                    </Col>
                </Row>
                <Row className="ml-0">
                    <Col sx={12} className="col col-11 pl-0 pr-0">
                        {showDateProcessHintInfoMb && (
                            <p className="hint-text pt-0 mt-0 ml-0">
                                {i18n.get("payments.payThirdParties.newPayment.date.help", null, {
                                    HORA_PROCESO: hour,
                                })}
                            </p>
                        )}
                    </Col>
                </Row>
            </Col>
        );
    };

    handleDownloadClick = async (formatDownload) => {
        if (formatDownload.includes("hab")) {
            const { data } = await downloadFilelink("6");
            return downloadTxt(`archivoEjemploHAB.txt`, data.data.content);
        }
        const { data } = await downloadFilelink("7");
        return downloadTxt(`archivoEjemploPRO.txt`, data.data.content);
    };

    hintMobile = (onClick, showMessage, imageAcive, imageInactive) => (
        <div>
            <button type="button" className="btn btn-hint my-1" onClick={() => onClick()}>
                {showMessage ? (
                    <Image src={imageAcive} className="hint-image-style" styles={{ height: "24px", width: "24px" }} />
                ) : (
                    <Image
                        src={imageInactive}
                        className="hint-image-style"
                        styles={{ height: "24px", width: "24px" }}
                    />
                )}
            </button>
        </div>
    );

    renderDownloadDropdown = (isFetchingDownload, isDesktop) => (
        <div className="toolbar-item--fixed align-items-center justify-content-end">
            <Dropdown
                image="images/download_bold.svg"
                listClassName="min-w-full top-3"
                buttonClass={`btn btn-outline download space-between px-3 ${isDesktop ? "m-0" : "button-white-header"}`}
                fetching={isFetchingDownload}
                label="thirdPayment.download"
                bsStyle="outline"
                pullDown
                fitWidth={false}>
                <Button
                    onClick={() => this.handleDownloadClick("hab")}
                    label="formats.txtHaberes"
                    className="dropdown__item-btn justify-content-start"
                    bsStyle="link"
                />
                <Button
                    onClick={() => this.handleDownloadClick("pro")}
                    label="formats.txtProveedores"
                    className="dropdown__item-btn justify-content-start"
                    bsStyle="link"
                />
            </Dropdown>
        </div>
    );

    rightContent = () => {
        const { isDesktop, isFetchingDownload } = this.props;

        if (isDesktop) {
            return (
                <Col className="p-0 d-flex space-between align-items-center" xs={12}>
                    <div className="toolbar-item justify-content-end">
                        {this.renderDownloadDropdown(isFetchingDownload, isDesktop)}
                    </div>
                </Col>
            );
        }

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={[
                    {
                        label: "payments.payThirdParties.newPayment.fileSupplierPayments.label",
                        onClick: () => this.handleDownloadClick("hab"),
                    },
                    {
                        label: "payments.payThirdParties.newPayment.fileSalaryPayments.label",
                        onClick: () => this.handleDownloadClick("pro"),
                    },
                ]}
            />
        );
    };

    renderHeader = () => {
        const { isDesktop } = this.props;

        return (
            <>
                {isDesktop && <Head onBack={this.handleBack} />}
                <Head
                    onBack={!isDesktop && this.handleBack}
                    title="payments.payThirdParties.newPayment.title"
                    titleClassName="my-0"
                    messageTooltip="payments.payThirdParties.newPayment.title.help"
                    rightContent={() => this.rightContent()}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : "mb-3"}
                />
            </>
        );
    };

    showModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { accounts, isDesktop, itemsDetail, fetching, values } = this.props;
        const { showModal, fileUploaded } = this.state;
        const defaultOption = { value: "PH", label: "Pago de Haberes" };
        const concepts = [{ value: "PP", label: "Pago a proveedores" }];
        const continueButton = (
            <Col lg={3} xl={3} md={9} sm={12}>
                <Row className={`${!isDesktop && "ml-0 mr-0"}`}>
                    <div className="admin-content-center">
                        <Button type="submit" bsStyle="primary" label="global.continue" />
                    </div>
                </Row>
            </Col>
        );

        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="form" />
                <div className="admin-detail-head w-100 px-0 mb-0">{this.renderHeader()}</div>
                <Form autoComplete="off">
                    <Modal show={showModal} className="payment-modal">
                        <Modal.Body>
                            <Container className="container--layout flex-grow align-items-center container-white my-0 px-0">
                                <TransactionLinesList onClose={this.closeModal} lines={itemsDetail} />
                            </Container>
                        </Modal.Body>
                    </Modal>
                    <div className="above-the-fold">
                        <Container
                            className="flex-grow align-items-center container-white my-2 pb-1"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} className="align-items-center">
                                <Col sm={12} className="px-0">
                                    <Field
                                        component={Selector}
                                        options={[defaultOption, ...concepts]}
                                        idForm={FORM_ID}
                                        name="typePayment"
                                        isRequired
                                    />
                                </Col>
                                <Col sm={12} className="px-0">
                                    <Field
                                        name="account"
                                        component={Productselector}
                                        idField="account"
                                        data={{
                                            emptyOptionLabel: "",
                                            options: accounts,
                                        }}
                                        disabled={accounts.length === 0}
                                        isRequired
                                        mode="edit"
                                        labelText="transfers.debitaccount.label"
                                        idForm={FORM_ID}
                                        isDesktop={isDesktop}
                                        customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                    />
                                </Col>
                                <Col
                                    sm={12}
                                    className={classNames("align-items-center px-0 pb-3", {
                                        "d-flex": isDesktop,
                                    })}>
                                    <Col sm={12} md={7} className="align-items-center px-0">
                                        <Field
                                            component={TextField}
                                            renderAs="input"
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            maxLength="100"
                                            name="description"
                                            type="text"
                                            formGroupClassName={!isDesktop ? "mb-0" : ""}
                                            isRequired
                                            errorClassName={classNames({
                                                "p-absolute": isDesktop,
                                            })}
                                        />
                                    </Col>
                                    {this.getProcessDate()}
                                </Col>
                            </Col>
                        </Container>
                        {values.account && (
                            <Container
                                className="flex-grow align-items-center container-white"
                                gridClassName="form-content">
                                <Col xs={12} md={9} lg={6} className="px-3">
                                    <p className="account-display-font">
                                        {i18n.get(`payments.payThirdParties.newPayment.Filetitle`)}
                                    </p>
                                    <div className="new-payment-multiline-file">
                                        <Field
                                            name="file"
                                            component={Multiline}
                                            type="multilinefile"
                                            mode="edit"
                                            formVersion="1"
                                            sub_type="salaryPayment"
                                            formTitle="salaryPayment"
                                            allowMultiple="false"
                                            acceptedFileTypes="text/plain"
                                            useForTotalAmount="true"
                                            maxFileSizeMB="4"
                                            idField="file"
                                            idForm="salaryPayment"
                                            isRequired
                                            openModal={this.showModal}
                                            fileUploaded={fileUploaded}
                                            values={values}
                                        />
                                    </div>
                                </Col>
                            </Container>
                        )}
                    </div>
                    <Container className="flex-grow align-items-center my-2" gridClassName="form-content">
                        {continueButton}
                    </Container>
                </Form>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    itemsDetail: selectorsTransactionLines.getTransactionLines(state),
    holidays: holidaysSelector.getHolidays(state),
    fetching: holidaysSelector.isFetching(state),
    summary: thirdPaymentSelector.getSummary(state),
    rememberFormData: thirdPaymentSelector.getRememberFormData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            account: "",
            file: null,
            description: "",
            typePayment: "",
            dateProcess: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    account: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    file: values.file
                        ? Yup.array()
                              .of(Yup.object())
                              .min(1, i18n.get(`${FORM_ID}.field.error.required`))
                        : Yup.array().notRequired(),
                    description: Yup.string()
                        .max(12, "El máximo es 12")
                        .required(i18n.get(`${FORM_ID}.field.error.required`)),
                    typePayment: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    dateProcess: Yup.date()
                        .nullable()
                        .required(i18n.get(`${FORM_ID}.field.error.required`)),
                }),
            ),
        handleSubmit: ({ account, file, description, typePayment, dateProcess }, formikBag) => {
            const { props } = formikBag;
            const { dispatch, accounts } = props;
            const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === account);
            dispatch(
                thirdPaymentActions.summaryThirdPay({
                    account: completeDebitAccount,
                    file,
                    typePayment,
                    dateProcess,
                    description,
                }),
            );
        },
    }),
)(NewPayment);
