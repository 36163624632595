import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, shape, bool } from "prop-types";
import Notification from "pages/_components/Notification";
import { selectors, actions } from "reducers/checks";
import { echeqShape, accountDescription, findEmisor, echeqConceptLabel } from "util/checks";
import { selectors as accountsSelectors } from "reducers/accounts";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import Hint from "pages/_components/hints/Hint";
import Button from "pages/_components/Button";
import { selectors as sessionSelectors } from "reducers/session";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import I18n from "pages/_components/I18n";

const FORM_ID = "echeqs";

class ConfirmEcheqAction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        accounts: arrayOf(shape({})).isRequired,
        userId: string.isRequired,
        environmentCuit: string.isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        submitting: false,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    closeEcheqAction = () => {
        const { dispatch } = this.props;

        dispatch(actions.cleanEcheqAction());
        this.handleBack();
    };

    componentWillMount = () => {
        const { echeqAction } = this.props;

        if (!echeqAction.action) {
            this.handleBack();
        }
    };

    createAccountDescription = () => {
        const { echeqAction, accounts } = this.props;
        const { specialAttribute } = echeqAction;
        const account = accounts.find((a) => a.idProduct === specialAttribute);

        const { productAlias, number, currency, productType } = account;

        return accountDescription(productAlias, number, currency, productType);
    };

    executeEcheqAction = () => {
        const { dispatch, echeqAction, userId, accounts } = this.props;
        let attribute = echeqAction.specialAttribute;
        let attribute2 = "";
        let attribute3 = "";
        if (echeqAction.action === "CUSTODY" || echeqAction.action === "DEPOSIT") {
            const accountSelected = accounts.find((account) => account.idProduct === echeqAction.specialAttribute);
            attribute = accountSelected.cbu;
            attribute2 = accountSelected.productType === "CA" ? "05" : "03";
            if (accountSelected.number.length < 11) {
                const spacesToFill = 11 - accountSelected.number.length;
                for (let i = 0; i < spacesToFill; i++) {
                    attribute3 += "0";
                }
                attribute3 += accountSelected.number;
            }
        }
        this.setState({ submitting: true });
        dispatch(
            actions.executeActionEcheq(
                {
                    ...echeqAction,
                    specialAttribute: attribute,
                    specialAttribute2: attribute2,
                    specialAttribute3: attribute3,
                },
                userId,
            ),
        );
    };

    totalEcheqs = () => {
        const { echeqAction } = this.props;
        return (
            echeqAction?.echeqList?.length > 1 && <SelectedAccountsAmount list={echeqAction?.echeqList} summaryView />
        );
    };

    renderHeader = () => {
        const { echeqAction, isDesktop } = this.props;
        const title =
            echeqAction?.echeqList?.length > 1
                ? `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title.multi`
                : `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title`;
        const subTitle =
            echeqAction?.action === "ADMIT"
                ? `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.subtitle`
                : "echeqs.action.subtitle";

        if (isDesktop) {
            return (
                <div className="admin-detail-head px-0 echeq-header">
                    <Head onBack={this.handleBack} onClose={this.closeEcheqAction} />
                    <Container className="flex-grow align-items-center mb-2" gridClassName="form-content">
                        <Col xs={12} lg={12} className="px-0 justify-content-start" style={{ alignItems: "center" }}>
                            <div className="px-0">
                                <Head title={title} centerContentClassName="p-0" />
                                <I18n
                                    id={subTitle}
                                    component="h4"
                                    componentProps={{
                                        className: `mb-0`,
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={12} className="echeq__totals justify-content-end d-flex">
                            {this.totalEcheqs()}
                        </Col>
                    </Container>
                </div>
            );
        }

        return (
            <Head
                emptyRightOption
                headerClassName="blue-main-header-mobile blue-main-title-mobile"
                onBack={this.handleBack}
                title={title}
            />
        );
    };

    render() {
        const { echeqAction, environmentCuit, isDesktop } = this.props;
        const { submitting } = this.state;
        const subTitle =
            echeqAction?.action === "ADMIT"
                ? `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.subtitle`
                : "echeqs.action.subtitle";

        return (
            <>
                <Notification scopeToShow="echeqs" />
                {this.renderHeader()}
                <MainContainer viewContentClassName="overflow-visible">
                    {!isDesktop && (
                        <>
                            <Col xs={12} className="echeq__totals justify-content-center d-flex">
                                <I18n
                                    id={subTitle}
                                    component="h3"
                                    componentProps={{ className: "my-0 px-5 text-align-center" }}
                                />
                            </Col>
                            <Col xs={12} className="echeq__totals justify-content-end d-flex">
                                {this.totalEcheqs()}
                            </Col>
                        </>
                    )}
                    <div className="above-the-fold">
                        {echeqAction?.echeqList?.map((echeq) => {
                            const emisor = findEmisor(echeq, environmentCuit);
                            return (
                                <Container
                                    className="flex-grow align-items-center container-white with-margin with-padding p-3 mt-0"
                                    gridClassName="form-content">
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_numero`}
                                            dataClassName="f-size-4 font-light"
                                            data={echeq.cheque_numero}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.emitted.from`}
                                            dataClassName="f-size-4 font-light"
                                            data={emisor.emisorRazonSocial.replaceAll("#", "Ñ")}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.beneficiario_documento`}
                                            dataClassName="f-size-4 font-light"
                                            data={emisor.emisorDocumento}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.monto`}
                                            data={
                                                <FormattedAmount
                                                    currency={i18n.get("currency.label.".concat(echeq.emisor_moneda))}
                                                    quantity={echeq.monto}
                                                    notBold
                                                />
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_id`}
                                            dataClassName="f-size-4 font-light"
                                            data={echeq.cheque_id}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_tipo`}
                                            dataClassName="f-size-4 font-light"
                                            data={echeq.cheque_tipo}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_modo`}
                                            dataClassName="f-size-4 font-light"
                                            data={i18n.get(`echeqs.${echeq.cheque_modo}.modo`)}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_caracter`}
                                            dataClassName="f-size-4 font-light"
                                            data={echeq.cheque_caracter}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.fecha_pago`}
                                            dataClassName="f-size-4 font-light"
                                            data={<FormattedDate showTime={false} date={echeq.fecha_pago} />}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_concepto`}
                                            dataClassName="f-size-4 font-light"
                                            data={echeqConceptLabel(echeq.cheque_concepto, "")}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_motivo_pago`}
                                            dataClassName="f-size-4 font-light"
                                            data={echeq.cheque_motivo_pago}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} className="px-1">
                                        {(echeqAction.action === "REPUDIATE" ||
                                            echeqAction.action === "REPUDIATE-CESION") && (
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                labelClassName="data-label-medium"
                                                label={`${FORM_ID}.repudiate_motive`}
                                                dataClassName="f-size-4 font-light"
                                                data={echeqAction.specialAttribute}
                                            />
                                        )}

                                        {echeqAction.action === "DEPOSIT" && (
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                label={`${FORM_ID}.deposit.account`}
                                                labelClassName="data-label-medium"
                                                dataClassName="f-size-4 font-light"
                                                data={this.createAccountDescription()}
                                            />
                                        )}
                                        {echeqAction.action === "CUSTODY" && (
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                label={`${FORM_ID}.custody.account`}
                                                labelClassName="data-label-medium"
                                                dataClassName="f-size-4 font-light"
                                                data={this.createAccountDescription()}
                                            />
                                        )}
                                    </Col>
                                </Container>
                            );
                        })}
                        <Container
                            className="flex-grow align-items-center container-white with-margin with-padding p-3 mt-0"
                            gridClassName="form-content">
                            <Container className="align-items-center flex-grow">
                                <Col className="col-12 col-xl-9" lg={9} md={9} sm={12}>
                                    {echeqAction.action === "RESCUE" && (
                                        <Hint
                                            idMessage={`${FORM_ID}.rescue.hint`}
                                            styleText={{ maxWidth: "100%" }}
                                            styleImage={{ alignSelf: "center", marginTop: "0.7rem" }}
                                            classNameHintP="hint-font-size-small"
                                            alwaysShowMessage
                                        />
                                    )}
                                    {echeqAction.action === "CUSTODY" && (
                                        <Hint
                                            idMessage={`${FORM_ID}.custody.hint`}
                                            styleText={{ maxWidth: "100%" }}
                                            styleImage={{ alignSelf: "center", marginTop: "0.7rem" }}
                                            classNameHintP="hint-font-size-small"
                                            alwaysShowMessage
                                        />
                                    )}
                                    {echeqAction.action === "DEPOSIT" && (
                                        <Hint
                                            idMessage={`${FORM_ID}.deposit.hint`}
                                            styleText={{ maxWidth: "100%" }}
                                            styleImage={{ alignSelf: "center", marginTop: "0.7rem" }}
                                            classNameHintP="hint-font-size-small"
                                            alwaysShowMessage
                                        />
                                    )}
                                </Col>
                            </Container>
                            <div className="admin-content-center">
                                <I18n
                                    id="confirmation.withoutotp.text"
                                    component="p"
                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                />
                            </div>
                            <Col xs={12} md={3} lg={3} className="px-1">
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.confirm"
                                    onClick={this.executeEcheqAction}
                                    loading={submitting}
                                    image="images/arrowRight.svg"
                                    imageStyle="mr-2"
                                    className="text-capitalize"
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqAction: selectors.getEcheqsToDoActions(state),
    accounts: accountsSelectors.getAccounts(state),
    userId: sessionSelectors.getUser(state).userId,
    environmentCuit: selectors.getEnvironmentCuit(state),
});

export default connect(mapStateToProps)(ConfirmEcheqAction);
