import { call, put, takeLatest, take } from "redux-saga/effects";
import { actions as formActions } from "reducers/form";
import {
    sendInternalTransaction,
    sendInternalTadTransaction,
    sendExternalTransaction,
    sendExternalTadTransaction,
    validateExternalTransaction,
    validateInternalTransaction,
    loadPreActivity,
    cancelScheduledTransaction,
    loadAccountLimitsActivities,
    sendSchedulerEmail,
    getCbuCvuFromAliasActivity,
} from "middleware/transfer";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions } from "reducers/transactions";
import { types, actions as transferActions } from "reducers/transfer";
import { push } from "react-router-redux";
import * as accountsMiddleware from "middleware/accounts";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { INSUFFICIENT_SIGNATURES, INTERNAL_ERROR, SCHEDULED_TRANSACTION } from "util/responses";
import * as transactionsMiddleware from "middleware/transactions";

const sagas = [
    takeLatest(types.SEND_INTERNAL_TRANSFER_REQUEST, sendInternalTransfer),
    takeLatest(types.SEND_EXTERNAL_TRANSFER_REQUEST, sendExternalTransfer),
    takeLatest(types.CREATE_INTERNAL_TRANSFER_REQUEST, validateInternalTransfer),
    takeLatest(types.CREATE_EXTERNAL_TRANSFER_REQUEST, validateExternalTransfer),
    takeLatest(types.GET_ACCOUNT_INFO, getAccountInfo),
    takeLatest(types.TRANSFER_PRE_ACTIVITY_REQUEST, transferPreActivity),
    takeLatest(types.CANCEL_SCHEDULED_TRANSACTION_REQUEST, cancelScheduled),
    takeLatest(types.ACCOUNT_LIMITS_REQUEST, accountLimitsActivities),
    takeLatest(types.SEND_SCHEDULER_EMAIL_REQUEST, sendSchedulerEmailActivity),
    takeLatest(types.GET_CBU_CVU_INFO, getCbuCvuFromAlias),
    takeLatest(types.LOAD_HISTORIC_LIST_REQUEST, loadHistoricListRequest),
    takeLatest(types.LOAD_MORE_TRANSFERS_REQUEST, fetchMoreTransfers),
];

export default sagas;

function* transferPreActivity() {
    const response = yield call(loadPreActivity);

    if (response.type === "W") {
        yield put({ type: types.TRANSFER_PRE_ACTIVITY_FAILURE });
    } else {
        yield put({
            type: types.TRANSFER_PRE_ACTIVITY_SUCCESS,
            concepts: response.data.data.concepts,
            accounts: response.data.data.accounts,
            serverDate: response.data.data.serverDate,
        });
    }
}

function* cancelScheduled({
    idTransaction,
    otp,
    creditAccount,
    cbu,
    debitAccount,
    amount,
    currency,
    formikBag,
    clientUser,
}) {
    const response = yield call(
        cancelScheduledTransaction,
        idTransaction,
        otp,
        creditAccount,
        cbu,
        debitAccount,
        amount,
        currency,
        clientUser,
    );

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(response.data.data);
        } else {
            yield put(
                notificationActions.showNotification(response.data.message, "error", ["transactionScheduledCancel"]),
            );
        }
        yield put({ type: types.CANCEL_SCHEDULED_TRANSACTION_FAILURE });
    } else {
        yield put(
            formActions.readTransaction({
                pathname: `/transaction/${response.data.data?.idTransactionToCancel || response.data.idTransaction}`,
                newTransaction: true,
            }),
        );

        const { data } = response;
        const { code } = data;

        if (code === SCHEDULED_TRANSACTION) {
            yield put(
                notificationActions.showNotification(i18n.get("scheduler.cancel.success"), "success", [
                    "transactionScheduled",
                ]),
            );
        } else if (code !== INSUFFICIENT_SIGNATURES && idTransaction !== null) {
            yield put(
                notificationActions.showNotification(i18n.get("scheduler.cancel.success"), "success", [
                    "pendingTransfer",
                ]),
            );
        }

        yield put({
            type: types.CANCEL_SCHEDULED_TRANSACTION_SUCCESS,
            idTransaction,
        });
    }
}

function* sendInternalTransfer({ summary, otp }) {
    const { formikBag, tadTrx } = summary;

    try {
        let response = null;
        if (tadTrx) {
            response = yield call(sendInternalTadTransaction, summary, otp);
        } else {
            response = yield call(sendInternalTransaction, summary, otp);
        }

        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx),
                );
            }
            yield put({ type: types.SEND_INTERNAL_TRANSFER_FAILURE });
        } else {
            const { data } = response;
            const { code } = data;

            if (code !== INTERNAL_ERROR && code !== INSUFFICIENT_SIGNATURES) {
                if (code === SCHEDULED_TRANSACTION) {
                    const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00");
                    const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00");
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(
                                !tadTrx
                                    ? "transfers.schedule.success.message"
                                    : "transfer.TAD.warning.message.scheduled",
                                null,
                                {
                                    DATE_FROM: TDAhourFrom.substring(0, 5),
                                    DATE_TO: TDAhourTo.substring(0, 5),
                                },
                            ),
                            !tadTrx ? "success" : "warning",
                            ["transactionScheduled"],
                        ),
                    );
                } else if (summary.idTransaction !== null) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.modify.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                }
            }

            yield put(transactionsActions.isFirstTransactionCBURequest(summary.cbu));

            yield put({
                type: types.SEND_INTERNAL_TRANSFER_PENDING,
                idTransaction: response.data.idTransaction,
            });

            const scheduled = summary.scheduler && Object.keys(summary.scheduler).length > 1;
            if ((code === SCHEDULED_TRANSACTION || summary.idTransaction !== null) && scheduled) {
                const {
                    userId,
                    summary: { clientUser, creditAccount },
                    envName,
                } = formikBag.props;
                yield put({
                    type: types.SEND_SCHEDULER_EMAIL_REQUEST,
                    summary: {
                        ...summary,
                        idActivity: "transfers.internal.send",
                        userId,
                        formikBag,
                        internalTransfer: true,
                        _originName: clientUser?.firstName || envName || "",
                        _originDocument: `${clientUser?.cuil.startsWith("3") ? "CUIT" : "CUIL"} ${clientUser?.cuil}`,
                        _creditAccountLabel: `**${creditAccount?.shortLabel}`,
                    },
                });
            }

            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST");
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* getAccountInfo({ account, accountType, isTransfer }) {
    const response = yield call(accountsMiddleware.getAccountInfo, account, isTransfer);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["form"]));
    } else if (accountType === "debit") {
        yield put({
            type: types.SET_DEBIT_ACCOUNT_INFO,
            accountInfo: response.data.data.account,
        });
    } else {
        yield put({
            type: types.SET_CREDIT_ACCOUNT_INFO,
            accountInfo: response.data.data.account,
        });
    }
}

function* sendExternalTransfer({ summary, otp }) {
    const { formikBag, tadTrx } = summary;
    const {
        summary: { creditAccountDetail },
    } = formikBag.props;

    // eslint-disable-next-line no-param-reassign
    summary = {
        ...summary,
        _bankName: creditAccountDetail.bankName,
    };

    try {
        let response = null;
        if (tadTrx) {
            response = yield call(sendExternalTadTransaction, summary, otp);
        } else {
            response = yield call(sendExternalTransaction, summary, otp);
        }

        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx),
                );
            }
            yield put({ type: types.SEND_EXTERNAL_TRANSFER_FAILURE });
        } else {
            const { data } = response;
            const { code } = data;

            if (code !== INTERNAL_ERROR && code !== INSUFFICIENT_SIGNATURES) {
                if (code === SCHEDULED_TRANSACTION) {
                    const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00");
                    const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00");
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(
                                !tadTrx
                                    ? "transfers.schedule.success.message"
                                    : "transfer.TAD.warning.message.scheduled",
                                null,
                                {
                                    DATE_FROM: TDAhourFrom.substring(0, 5),
                                    DATE_TO: TDAhourTo.substring(0, 5),
                                },
                            ),
                            !tadTrx ? "success" : "warning",
                            ["transactionScheduled"],
                        ),
                    );
                } else if (summary.idTransaction !== null) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.modify.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                }
            }

            yield put({
                type: types.SEND_EXTERNAL_TRANSFER_PENDING,
                summary: { idTransaction: response.data.idTransaction },
            });
            const scheduled = summary.scheduler && Object.keys(summary.scheduler).length > 1;
            if ((code === SCHEDULED_TRANSACTION || summary.idTransaction !== null) && scheduled) {
                const {
                    userId,
                    summary: { clientUser },
                    envName,
                    envDocument,
                } = formikBag.props;
                yield put({
                    type: types.SEND_SCHEDULER_EMAIL_REQUEST,
                    summary: {
                        ...summary,
                        idActivity: "transfers.thirdParties.send",
                        userId,
                        formikBag,
                        internalTransfer: false,
                        _originName: envName || "",
                        _originDocument: `${envDocument.startsWith("3") ? "CUIT" : "CUIL"} ${envDocument}`,
                        _beneficitName: clientUser?.firstName || "",
                        _beneficitDocument: `${clientUser?.cuil.startsWith("3") ? "CUIT" : "CUIL"} ${clientUser?.cuil}`,
                    },
                });
            }

            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST");
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* validateExternalTransfer({ summary, formikBag }) {
    const {
        accounts,
        debitAccount,
        cbu,
        alias,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        scheduler,
        transferKind,
        tadTrx,
    } = summary;

    const response = yield call(
        validateExternalTransaction,
        accounts,
        debitAccount,
        cbu,
        alias,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        scheduler,
        transferKind,
        tadTrx,
    );
    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            if (response.data.data.message) {
                yield put(
                    notificationActions.showNotification(response.data.data.message, "error", ["form"], null, !tadTrx),
                );
            } else {
                formikBag.setErrors(response.data.data);
            }
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx));
        }
        yield put({ type: types.CREATE_EXTERNAL_TRANSFER_FAILURE });
    } else {
        const { accountDetail, clientUser, pspEntityName } = response.data.data;

        yield put({
            type: types.CREATE_EXTERNAL_TRANSFER_SUCCESS,
            creditAccountDetail: accountDetail,
            pspEntityName,
            clientUser,
            isCVU: !!accountDetail?.cbu?.startsWith("000"),
            tadTrx,
        });
        yield put(push(`/transfer/summary`));
    }
}

function* validateInternalTransfer({ summary, formikBag }) {
    const {
        debitAccount,
        creditAccount,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        scheduler,
        tadTrx,
    } = summary;

    const response = yield call(
        validateInternalTransaction,
        debitAccount,
        creditAccount,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        scheduler,
        tadTrx,
    );
    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            if (response.data.data.message) {
                yield put(
                    notificationActions.showNotification(response.data.data.message, "error", ["form"], null, !tadTrx),
                );
            } else {
                formikBag.setErrors(response.data.data);
            }
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx));
        }
        yield put({ type: types.CREATE_INTERNAL_TRANSFER_FAILURE });
    } else if (response.type === "I") {
        const { accountDetail, clientUser } = response.data.data;
        yield put({
            type: types.CREATE_INTERNAL_TRANSFER_SUCCESS,
            creditAccountDetail: accountDetail,
            clientUser,
            tadTrx,
        });
        yield put(push(`/transfer/summary`));
    } else {
        const { accountDetail, clientUser } = response.data.data;
        yield put({
            type: types.CREATE_INTERNAL_TRANSFER_SUCCESS,
            creditAccountDetail: accountDetail,
            clientUser,
            tadTrx,
        });
        yield put(push(`/transfer/summary`));
    }
}

function* accountLimitsActivities({ idAccount, idActivity }) {
    const response = yield call(loadAccountLimitsActivities, idAccount, idActivity);

    if (response.type === "W") {
        yield put({ type: types.ACCOUNT_LIMITS_FAILURE });
    } else {
        const { limit, limitUsed } = response.data.data;

        yield put({
            type: types.ACCOUNT_LIMITS_SUCCESS,
            limit,
            limitUsed,
        });
    }
}

function* sendSchedulerEmailActivity({ summary }) {
    const response = yield call(sendSchedulerEmail, summary);

    if (response.type === "W") {
        yield put({ type: types.SEND_SCHEDULER_EMAIL_FAILURE });
    } else {
        yield put({
            type: types.SEND_SCHEDULER_EMAIL_SUCCESS,
        });
    }
}

function* getCbuCvuFromAlias(alias) {
    const response = yield call(getCbuCvuFromAliasActivity, alias);
    const { cbuCvu } = response.data.data;
    const isCVU = cbuCvu?.startsWith("000");
    yield put({
        type: types.SET_IS_CVU,
        isCVU,
    });
}

function* loadHistoricListRequest({ filters, onlyPendings, pendingDispatch }) {
    const response = yield call(transactionsMiddleware.loadListRequest, filters, onlyPendings, pendingDispatch);

    if (response.type === "W") {
        yield put(transferActions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transfers"]));
    } else {
        const { transactions, pageNumber, totalPages, totalRows } = response.data.data;
        yield put(transferActions.loadListSuccess(transactions, pageNumber, totalPages, totalRows));
    }
}

function* fetchMoreTransfers({ filters, onlyPendings, pendingDispatch, filter }) {
    const page = filters.pageNumber;
    const response = yield call(
        transactionsMiddleware.loadListRequest,
        { ...filters, pageNumber: page },
        onlyPendings,
        pendingDispatch,
        filter,
    );

    if (response.type === "W") {
        yield put(transferActions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transfers"]));
    } else {
        const { transactions, pageNumber, totalPages, totalRows } = response.data.data;
        yield put(
            transferActions.loadMoreTransfersSuccess(
                transactions,
                pageNumber,
                totalPages === 0 ? filters.totalPages : totalPages,
                totalRows,
            ),
        );
    }
}
