import { push, replace } from "react-router-redux";
import { delay } from "redux-saga";
import { call, spawn, put, all, select } from "redux-saga/effects";

import { globalTypes } from "reducers/types/global";
import { types as configTypes, selectors as configSelectors } from "reducers/config";
import { types as i18nTypes, selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";

import accounts from "sagas/accounts";
import campaigns from "sagas/campaigns";
import communications from "sagas/communications";
import communication from "sagas/communication";
import communicationTrays from "sagas/communicationTrays";
import config from "sagas/config";
import enrollment from "sagas/enrollment";
import fingerprint from "sagas/fingerprint";
import pushNotifications from "sagas/pushNotifications";
import form from "sagas/form";
import onboarding from "sagas/onboarding";
import i18n from "sagas/i18n";
import products from "sagas/products";
import recoveryPassword from "sagas/recoveryPassword";
import session from "sagas/session";
import settings from "sagas/settings";
import status from "sagas/status";
import template from "sagas/template";
import desktop from "sagas/desktop";
import widgets from "sagas/widgets";
import transactions from "sagas/transactions";
import bankSelector from "sagas/bankSelector";
import login from "sagas/login";
import weather from "sagas/weather";
import administrationGroups from "sagas/administration/groups";
import administrationMedium from "sagas/administration/medium";
import administrationSimple from "sagas/administration/simple";
import administrationTicket from "sagas/administration/common/administrationTicket";
import administrationUsers from "sagas/administration/users";
import administrationUsersInvite from "sagas/administration/usersInvite";
import administrationAdvanced from "sagas/administration/advanced";
import pointsOfInterest from "sagas/pointsOfInterest";
import files from "sagas/files";
import formFields from "sagas/formFields";
import massPayments from "sagas/massPayments";
import transactionLines from "sagas/transactionLines";
import restrictions from "sagas/administration/restrictions";
import multipleTransfers from "sagas/multiple-transfers";
import transfer from "sagas/transfer";
import debin from "sagas/debin";
import frequentDestination from "sagas/frequentDestination";
import checks from "sagas/checks";
import deposits from "sagas/deposits";
import thirdPayment from "sagas/thirdPayment";
import token from "sagas/token";
import servicePayments from "sagas/servicePayments";
import financing from "sagas/financing";
import paymentsAFIP from "sagas/paymentsAFIP";
import metrocorp from "sagas/metrocorp";
import ecomex from "sagas/ecomex";
import posicionConsolidada from "sagas/posicionConsolidada";
import fundcorp from "sagas/fundcorp";
import holidays from "sagas/holidays";
import cedip from "sagas/cedip";

import { MAX_FAILED_TIMES } from "constants.js";
import * as I18n from "util/i18n";

const sagas = [
    ...accounts,
    ...campaigns,
    ...communications,
    ...communication,
    ...communicationTrays,
    ...config,
    ...desktop,
    ...enrollment,
    ...fingerprint,
    ...form,
    ...i18n,
    ...onboarding,
    ...products,
    ...pushNotifications,
    ...recoveryPassword,
    ...status,
    ...session,
    ...settings,
    ...template,
    ...widgets,
    ...transactions,
    ...bankSelector,
    ...login,
    ...weather,
    ...administrationAdvanced,
    ...administrationGroups,
    ...administrationMedium,
    ...administrationSimple,
    ...administrationTicket,
    ...administrationUsers,
    ...administrationUsersInvite,
    ...files,
    ...formFields,
    ...pointsOfInterest,
    ...massPayments,
    ...transactionLines,
    ...multipleTransfers,
    ...transfer,
    ...debin,
    ...restrictions,
    ...frequentDestination,
    ...checks,
    ...deposits,
    ...token,
    ...thirdPayment,
    ...servicePayments,
    ...financing,
    ...paymentsAFIP,
    ...metrocorp,
    ...ecomex,
    ...posicionConsolidada,
    ...fundcorp,
    ...holidays,
    ...cedip,
];

const actionsTransfer = ["SEND_EXTERNAL_TRANSFER_REQUEST", "SEND_INTERNAL_TRANSFER_REQUEST"];

export default function* rootSaga() {
    yield all(
        sagas.map((saga) =>
            spawn(function* listenErrors() {
                let isSyncError = false;
                const resetSyncError = () => {
                    isSyncError = false;
                };
                let httpError = false;
                while (true) {
                    httpError = false;
                    isSyncError = true;
                    try {
                        setTimeout(resetSyncError);

                        yield call(function* execSaga() {
                            yield saga;
                        });
                        // eslint-disable-next-line no-console
                        console.error(
                            "Unexpected root saga termination. " +
                                "The root sagas are supposed to be sagas that live during the whole app lifetime!",
                            saga,
                        );
                    } catch (error) {
                        httpError = typeof error.httpError !== "undefined";
                        if (!httpError && isSyncError) {
                            throw new Error(`${saga.name} was terminated because it threw an exception on startup.`);
                        }
                        yield call(handleError, error, saga);
                    }

                    if (!httpError) {
                        // Para evitar que fallas infinitas bloqueen el browser...
                        // eslint-disable-next-line no-console
                        console.error(saga.name, " will be restarted after 1 second");
                        yield call(delay, 1000);
                    }
                }
            }),
        ),
    );
}

export function* handleError(error, saga) {
    let errorControlled = false;

    if (error.data) {
        switch (error.data.code) {
            // Add known error codes as new cases for avoid general error message

            // User is blocked
            case "COR019E":
                yield put({ type: globalTypes.CLEAN_UP });
                break;
            case "API040E":
                errorControlled = true;
                yield put(
                    notificationActions.showNotification(I18n.get("products.operation.NoDispose"), "error", [
                        "desktop",
                    ]),
                );
                yield put(replace("/desktop"));
                break;
            // Attempt to sign an expired transaction
            case "COR108E":
                errorControlled = true;
                yield put(
                    notificationActions.showNotification(I18n.get("transaction.expired.signAttempt"), "error", [
                        "transactions",
                    ]),
                );
                yield put(replace("/transactions/list"));
                break;
            default:
                break;
        }

        if (!errorControlled) {
            // eslint-disable-next-line no-console
            console.error("[API Error Handler]:1", error.data, error.status);

            yield put(
                push({
                    pathname: "/error",
                    code: error.data.code,
                    message: error.data.message,
                }),
            );
        }
    } else if (error.response && error.response.status === 401) {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:2", error.response);
        // The request was made and the server responded, but with a status code outside of 2xx
        yield put({ type: globalTypes.CLEAN_UP });

        switch (error.response.data.code) {
            case "API004W":
                yield put(notificationActions.showNotification(I18n.get("session.expired"), "error", ["login"]));
                break;

            case "API010W":
                yield put(
                    notificationActions.showNotification(I18n.get("session.loggedinOtherDevice"), "error", ["login"]),
                );
                break;

            case "API007E":
            case undefined: // The 401 sent by the Apigateway / Authorization server doesn't send a data code
                yield put(replace("/"));
                yield put(notificationActions.showNotification(I18n.get("session.expired"), "error", ["login"]));
                break;

            default:
                break;
        }
    } else if (error.request) {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:3", error.request);
        const { FORK } = saga;
        const actionType = FORK?.args ? FORK?.args[0]?.split("/")[1] : "";
        const isTransfer = actionsTransfer.includes(actionType);
        // The request was made but no response was received
        const timesConfigFailed = yield select(configSelectors.getTimesFailed);
        const timesI18nFailed = yield select(i18nSelectors.getTimesFailed);

        if (timesConfigFailed >= MAX_FAILED_TIMES || timesI18nFailed >= MAX_FAILED_TIMES) {
            yield put(push({ pathname: "/serverError" }));
        } else {
            const lang = yield select(i18nSelectors.getLang) || config.get("frontend.i18n.default.lang", "es");
            yield put({ type: configTypes.RESET_SAGAS_UPDATE, lang });
            yield put({ type: i18nTypes.RESET_SAGAS_UPDATE, lang });
            yield put(
                push({
                    pathname: "/error",
                    code: isTransfer ? "API528E" : "CLI999E",
                }),
            );
        }
    } else {
        // eslint-disable-next-line no-console
        console.error("[API Error Handler]:4", error.message);
        const { FORK } = saga;
        const actionType = FORK?.args ? FORK?.args[0]?.split("/")[1] : "";
        const isTransfer = actionsTransfer.includes(actionType);
        // Something happened in setting up the request that triggered an Error
        yield put(
            push({
                pathname: "/error",
                code: isTransfer ? "API528E" : "CLI999E",
            }),
        );
    }
}
