import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, bool, shape } from "prop-types";
import Notification from "pages/_components/Notification";
import { selectors, actions } from "reducers/checks";
import { echeqShape } from "util/checks";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { Field, Form, withFormik } from "formik";
import I18n from "pages/_components/I18n";
import { compose } from "redux";
import Yup from "yup";
import Credential from "pages/_components/fields/credentials/Credential";
import { selectors as sessionSelectors } from "reducers/session";
import CesionSummaryData from "pages/checks/Echeqs/EcheqTransactions/CesionSummaryData";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import isTokenActive from "util/token";

const FORM_ID = "echeqs";

class ConfirmCesionEcheqAction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        cesionData: shape({
            beneficiary: shape({}),
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    componentWillMount = () => {
        const { echeqAction } = this.props;

        if (!echeqAction.action) {
            this.handleBack();
        }
    };

    closeEcheqAction = () => {
        const { dispatch } = this.props;

        dispatch(actions.cleanEcheqAction());
        this.handleBack();
    };

    executeEcheqAction = () => {
        const { dispatch, echeqAction } = this.props;

        dispatch(actions.executeActionEcheq(echeqAction));
    };

    totalEcheqs = () => {
        const { echeqAction, isDesktop } = this.props;

        return (
            echeqAction?.echeqList?.length > 1 && (
                <SelectedAccountsAmount list={echeqAction?.echeqList} summaryView isDesktop={isDesktop} />
            )
        );
    };

    renderHeader = () => {
        const { echeqAction, isDesktop } = this.props;
        const title =
            echeqAction?.echeqList.length > 1
                ? `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title.multi`
                : `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title`;

        if (isDesktop) {
            return (
                <div className="admin-detail-head px-0">
                    <Head onBack={this.handleBack} onClose={this.closeEcheqAction} />
                    <Container className="flex-grow align-items-center mb-2 " gridClassName="form-content">
                        <Col
                            xs={12}
                            lg={8}
                            className="px-0 justify-content-start"
                            style={{ alignItems: "center", height: !isDesktop && "4.5rem" }}>
                            <div className="px-0">
                                <Head title={title} centerContentClassName="p-0" />
                                <I18n id="echeqs.action.data.subtitle" component="h4" />
                            </div>
                        </Col>
                        <Col lg={4} className="echeq__totals justify-content-end d-flex">
                            {this.totalEcheqs()}
                        </Col>
                    </Container>
                </div>
            );
        }

        return (
            <Head
                headerClassName="blue-main-header-mobile"
                titleClassName="w-100"
                onBack={this.handleBack}
                title={title}
                emptyRightOption
            />
        );
    };

    render() {
        const { echeqAction, cesionData, isSubmitting, isDesktop } = this.props;
        return (
            <>
                <Notification scopeToShow="form" />
                {this.renderHeader()}
                <MainContainer>
                    {!isDesktop && (
                        <>
                            <Col xs={12} className="echeq__totals justify-content-center d-flex">
                                <I18n
                                    id="echeqs.cesion.subtitle"
                                    component="h4"
                                    componentProps={{ className: `my-0 ${!isDesktop && "text-center"}` }}
                                />
                            </Col>
                            <Col xs={12} className="echeq__totals justify-content-end d-flex">
                                {this.totalEcheqs()}
                            </Col>
                        </>
                    )}
                    <div className="above-the-fold">
                        <CesionSummaryData cesionData={cesionData} echeqAction={echeqAction} isDesktop={isDesktop} />
                        <Container>
                            <Form className="above-the-fold">
                                <Container className="flex-grow align-items-center container-white pb-0">
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                        {!isTokenActive(!isDesktop) ? (
                                            <div className="admin-content-center font-light">
                                                <I18n id="transfer.summary.confirm.label" />
                                            </div>
                                        ) : (
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                        )}
                                        <div className="admin-content-center">
                                            <Field
                                                name="otp"
                                                type="otp"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                loading={isSubmitting}
                                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                            />
                                        </div>
                                    </Col>
                                </Container>
                                <Container
                                    className="flex-grow align-items-center container-white mt-0"
                                    gridClassName="form-content">
                                    <Col className="col-12" lg={4} md={9} sm={12}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            loading={isSubmitting}
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            className="text-capitalize"
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqAction: selectors.getEcheqsToDoActions(state),
    cesionData: selectors.getEmitCesionData(state),
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: (form, formikBag) => {
            const { setSubmitting, setErrors } = formikBag;
            const { dispatch, cesionData, echeqAction, userId } = formikBag.props;

            setSubmitting(true);
            if (echeqAction.echeqList?.length > 1) {
                dispatch(
                    actions.cesionMultipleEcheq(form.otp, setSubmitting, setErrors, cesionData, echeqAction, userId),
                );
            } else {
                dispatch(actions.cesionEcheq(form.otp, setSubmitting, setErrors, cesionData, echeqAction, userId));
            }
        },
    }),
)(ConfirmCesionEcheqAction);
