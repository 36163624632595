import * as API from "middleware/api";
// import formField from "pages/forms/_components/_fields/_commons/formField";
export const validateThirdPayment = (
    file,
    description,
    typePayment,
    dateProcess,
    debitAccount,
    otp,
    processedFileData,
    pendingLines,
) =>
    API.executeWithAccessToken("/pay.multiline.send", {
        file,
        reference: description,
        idForm: "salaryPayment",
        typePayment,
        dateProcess,
        debitAccount: debitAccount.idProduct,
        debitAccountData: debitAccount,
        _otp: otp,
        amount: processedFileData.totalAmount,
        processedFileData,
        pendingLines,
    });

export const loadListRequest = (filters) => API.executeWithAccessToken("/list.multiline.salary", { ...filters });

export const downloadTransactionLinestMiddleware = (format, transactionLines) =>
    API.executeWithAccessToken("/transactionLines.download", { format, transactionLines });

export const downloadListRequestMiddleware = (format, transactionLinesStatus) =>
    API.executeWithAccessToken("/listThirdPayment.download", { format, transactionLinesStatus });

export const downloadTransactionFiletMiddleware = (
    format,
    transactionLines,
    typePayment,
    fileNam,
    id,
    reference,
    dateProcess,
    transactionOrder,
    currency,
    idTransactionStatus,
    accountName,
    transactionStatusName,
    isDetailDownload,
) =>
    API.executeWithAccessToken("/transactionLines.file.download", {
        format,
        transactionLines,
        typePayment,
        fileNam,
        id,
        reference,
        dateProcess,
        transactionOrder,
        currency,
        idTransactionStatus,
        accountName,
        transactionStatusName,
        isDetailDownload,
    });

export const downloadConfirmation = (summary) =>
    API.executeWithAccessToken("/thirdPayment.downloadConfirm", { summary });
