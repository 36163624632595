import React, { Component } from "react";
import { shape } from "prop-types";
import { connect } from "react-redux";
import { selectors as debinSelectors } from "reducers/debin";
import moment from "moment";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";
import * as i18nUtils from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";
import * as configUtils from "util/config";

const FORM_ID = "debin.recurrency";

class PrestaciondebinPrestacion extends Component {
    static propTypes = {
        debinPrestacion: shape({}).isRequired,
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    render() {
        const { debinPrestacion } = this.props;

        return (
            debinPrestacion?.idTransaction && (
                <>
                    {debinPrestacion.idTransactionStatus === "FINISHED" && (
                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="negotiate.transaction.date.label"
                                data={
                                    <p className="data-desc m-0">
                                        <FormattedDate
                                            date={debinPrestacion.creationDate}
                                            dateFormat="dd/MM/yyyy"
                                            showTime
                                        />
                                    </p>
                                }
                            />
                        </Col>
                    )}
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.detail.table.header.voucher"
                            data={
                                <p className="data-desc m-0">
                                    {debinPrestacion?.idTransaction.substring(0, 8).toUpperCase()}
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.account.title`}
                            data={<p className="data-desc m-0">{debinPrestacion?.accountFull}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.seller.name.title`}
                            data={<p className="data-desc m-0">{debinPrestacion?.sellerInfo?.titular}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0 mb-3">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.seller.cuit.title`}
                            data={<p className="data-desc m-0">{debinPrestacion?.seller}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.prestacion.title`}
                            data={<p className="data-desc m-0">{debinPrestacion?.prestacionSeleccionada?.nombre}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.concept.title`}
                            data={
                                <p className="data-desc m-0">
                                    {debinPrestacion?.concept} -{" "}
                                    {i18nUtils.get(`transfers.concept.${debinPrestacion?.concept}`)}
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.reference.title`}
                            data={
                                <p className="data-desc m-0">
                                    {debinPrestacion?.prestacionSeleccionada?.ayuda_referencia}
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.descripcion.title`}
                            data={<p className="data-desc m-0">{debinPrestacion?.description}</p>}
                        />
                    </Col>
                    {debinPrestacion.idTransactionStatus === "FAILED" &&
                        !configUtils.getBoolean("frontend.show.failedVoucher.payFunctionalities") && (
                            <>
                                <Col xs={12} className="px-0">
                                    <Heading.DataGroup
                                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                        labelClassName="data-label-medium"
                                        dataClassName="data-desc"
                                        label={`${FORM_ID}.coelsa.response`}
                                        data={<p className="data-desc m-0">{debinPrestacion?.coelsaResponse}</p>}
                                    />
                                </Col>
                                <Col xs={12} className="px-0">
                                    <Heading.DataGroup
                                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                        labelClassName="data-label-medium"
                                        dataClassName="data-desc"
                                        label={`${FORM_ID}.coelsa.code`}
                                        data={<p className="data-desc m-0">{debinPrestacion?.coelsaCode}</p>}
                                    />
                                </Col>
                            </>
                        )}
                </>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    debinPrestacion: debinSelectors.getdebinPrestacionData(state),
});

export default connect(mapStateToProps)(PrestaciondebinPrestacion);
